<div class="modal-main">
  <div class="modal-content">
    <table mat-table [dataSource]="reportList" class="mat-elevation-z8">
    
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef style="width: 40%;padding-right:24px"> Description </th>
        <td mat-cell *matCellDef="let element" (click)="sendBullets(element.bulletsList)" class="custom-td"> {{element.Description}} </td>
      </ng-container>

      <ng-container matColumnDef="Hours_Used">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;padding-right:24px" style="text-align: center;"> Use Hours </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.Hours_Used !== null ? element.Hours_Used + '' + 'Hr': 'N/A'}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Reported_By">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;padding-right:24px"> Reported By </th>
        <td mat-cell *matCellDef="let element"> {{element.Reported_By}} </td>
      </ng-container>
    
      <ng-container matColumnDef="Assigned_Mechanic">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;padding-right:24px"> Assigned By </th>
        <td mat-cell *matCellDef="let element"> {{element.Assigned_Mechanic}} </td>
      </ng-container>
      
      <ng-container matColumnDef="Reparation_Range">
        <th mat-header-cell *matHeaderCellDef style="width: 15%;padding-right:24px" style="text-align: right;"> Reparation Hours </th>
        <td mat-cell *matCellDef="let element" style="text-align: right;"> {{element.Reparation_Range}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
