<div class="container">
    <mat-card>
        <div class="title">
            <button mat-icon-button (click)="back()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <h1>Weekly Reports</h1>
        </div>
        <mat-tab-group>
            <mat-tab label="V1"> Content 1 </mat-tab>
            <mat-tab label="V2">
                <div class="tab-content">
                    <h1>Filters</h1>
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>Select Grower</mat-label>
                            <mat-select matNativeControl required>

                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Select Ranch</mat-label>
                            <mat-select matNativeControl required>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Select User</mat-label>
                            <mat-select matNativeControl required>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <h1>Select date to start search</h1>
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>From Date</mat-label>
                            <input matInput [matDatepicker]="dp1" disabled>
                            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                            <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Language</mat-label>
                            <mat-select matNativeControl required>
                                <mat-option value="English">English</mat-option>
                                <mat-option value="Español">Español</mat-option>
                            </mat-select>
                        </mat-form-field>  
                    </div>
                    <div class="main-table">
                        <div class="filter" fxLayout="row">
                            <mat-form-field>
                                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field>
                        </div>

                        <div class="mat-elevation-z8">
                            <mat-table [dataSource]="dataSource" matSort>

                                <ng-container *ngFor="let headCol of displayedColumns; let i = index" matColumnDef="{{ headCol }}">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ headCol }} </mat-header-cell>
                                    <mat-cell *matCellDef="let row" [attr.data-label]="headCol">
                                        <div *ngIf="headCol == 'Actions'">
                                            <button *ngIf="row.ContactName != null" mat-button (click)="ranch(row.Id);">
                                            <mat-icon>map</mat-icon>
                                            </button>
                                            <button mat-button (click)="update(row, i);">
                                            <mat-icon>edit</mat-icon>
                                            </button>
                                            <button mat-button (click)="delete(row, i)">
                                            <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                        {{ checkData(row[headCol], headCol) }}
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;">
                                </mat-row>
                            </mat-table>

                            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                        </div>
                    </div>
                </div>        
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>