<div class="container">
    <mat-card>
        <div class="title">
            <button mat-icon-button (click)="back()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <h1>Weekly Report</h1>
        </div>
        <mat-tab-group>
            <mat-tab label="V2">
                <div class="tab-content">
                    <h1>Filters</h1>
                    <div>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Select Grower</mat-label>
                            <mat-select [(ngModel)]="growerId" (ngModelChange)="growerChange(growerId)">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let grower of growers" [value]="grower.Id">
                                    {{grower.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Select Ranch</mat-label>
                            <mat-select [(ngModel)]="ranchId">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let ranch of ranchesFiltered" [value]="ranch.Id">
                                    {{ranch.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Select User</mat-label>
                            <mat-select [(ngModel)]="userId">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let user of users" [value]="user.Id">
                                    {{user.Name}} {{user.LastName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="fill" class="input-custom" (click)="dp1.open()">
                            <mat-label>From Date</mat-label>
                            <input matInput [matDatepicker]="dp1" [(ngModel)]="fromDate" autocomplete="off"
                                [disabled]="true">
                            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                            <mat-datepicker #dp1 disabled="true"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom" (click)="dp2.open()">
                            <mat-label>To Date</mat-label>
                            <input matInput [matDatepicker]="dp2" (click)="dp2.open()" [(ngModel)]="toDate"
                                [max]="maxDate" autocomplete="off" (ngModelChange)="toDateChange()" />
                            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                            <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Download in Language (not a filter)</mat-label>
                            <mat-select [(ngModel)]="lang">
                                <mat-option [value]="'en'">English</mat-option>
                                <!-- <mat-option [value]="'es'">Español</mat-option> -->
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <button mat-raised-button color="primary"
                            [disabled]="!toDate || !growerId || !ranchId || !userId" (click)="getData()">
                            Submit filters & generate report
                        </button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>