<div class="container">
  <mat-toolbar>
      <span class="fill-space"></span>
      <span>Update Report</span>
      <span class="fill-space"></span>
      <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>

  <form [formGroup]="form">
      <div>
        <mat-form-field>
          <mat-label>Reviewed By*</mat-label>
          <input matInput type="text" formControlName="ReviewedBy">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Reviewed Date*</mat-label>
          <input matInput [matDatepicker]="picker2" formControlName="ReviewedDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
  </form>


  <footer class="dialog-footer">
    <div class="button-row">
        <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="update()">Update</button>
    </div>
</footer>
</div>
