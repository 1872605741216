import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { RanchesService } from '../ranches.service';
import { Ranch } from '../ranches.model';

export class UserAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-ranches-modal-add',
  templateUrl: './ranches-modal-add.component.html',
  styleUrls: ['./ranches-modal-add.component.scss']
})
export class RanchesModalAddComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RanchesModalAddComponent>, private service: RanchesService, @Inject(MAT_DIALOG_DATA) public data: {growerId: number}) { }

  ngOnInit(): void {
  }

  public form: FormGroup = new FormGroup({
    name: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
    location: new FormControl('', Validators.maxLength(500)),
  });
  
  public cancelModal = (data: Ranch = null) => {
    this.dialogRef.close(data);
  }
  public onAdd = async() => {

    let ranch = new Ranch();
    ranch.IdGrower = this.data.growerId;    
    ranch.Name = this.form.controls.name.value;
    ranch.Location = this.form.controls.location.value;

    let result: any = await this.service.Create(ranch);

    if(result.error){
      return;
    }
    this.cancelModal(result);
  }
}
