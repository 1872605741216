import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportsV2Service } from '../../reports-v2.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-water-usate-detail',
  templateUrl: './water-usate-detail.component.html',
  styleUrls: ['./water-usate-detail.component.scss']
})
export class WaterUsateDetailComponent implements OnInit {

  public form: any;
  public isValidating: boolean = false;
  public Code: string = "";
  public Comments: string = "";
  public validatedBy: string = "";
  public userLetters: string = "";
  public invalidCode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<WaterUsateDetailComponent>,
    private service: ReportsV2Service,
    @Inject(MAT_DIALOG_DATA) private data: {form: any}
    ){
      
    }

  ngOnInit(): void {

    this.form = this.data.form;
    console.log(this.form)
    
  }

  public cancelModal = (result: any = null) => {
    this.dialogRef.close(result);
  }


  public formatDate = (date) => {
    let dates = moment.utc(date).local().format().split('T');
    return `${dates[0]}`;
  }

  public imageUrl = (id: any) => {
    return `${environment.STORAGE}waterUsage/${id.toLowerCase()}.jpeg`;
  }

  public handleMissingImage(event: Event, img: string) {
    (event.target as HTMLImageElement).src = `${environment.STORAGE}${environment.CONTAINER}/waterUsage/${img.toLowerCase()}.jpeg`;
  }

  public getType = (type: number) => {
    switch(type) {
      case 1: return 'Liter';
      case 2: return 'Galon';
      case 3: return 'm3 (Cubic meter)';
      case 4: return 'f3 (Cubic feet)';
      default: return 'Galon';
    }
  }

}
