<div class="main">
    <mat-card>
        <h1>Machines & Equipment</h1>
        <p>Control and manage machines</p>
        <button mat-raised-button (click)="modalAdd()">Register new machine or equipment</button>
        <div *ngIf="machinesAndEquipment.length > 0">
            <app-table [data]="machinesAndEquipment" [dataTable]="dataTable" (updateItem)="update($event)" (deleteItem)="delete($event)"></app-table>
        </div>
        <div *ngIf="machinesAndEquipment.length == 0" style="text-align: center;">
            <h1>Machines & Equipment table is empty</h1>
            <button mat-raised-button (click)="modalAdd()">Register Machine</button>
        </div>
    </mat-card>
</div>