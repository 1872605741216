<mat-toolbar color="primary">
    <button mat-icon-button aria-label="menu" class="menu" (click)="toggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button>

    <button mat-button [matMenuTriggerFor]="submenu"><mat-icon matListAvatar style="margin-right:15px">face</mat-icon>{{ userName }}</button>
    <mat-menu #submenu="matMenu">
      <button mat-menu-item (click)="logout()"><mat-icon matListAvatar>exit_to_app</mat-icon> Logout</button>
    </mat-menu>
  
</mat-toolbar>