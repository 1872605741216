import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilesService } from '../files.service';
import Folder from '../models/folder.model';
import SubFolder from '../models/sub-folder.model';

export class UserAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-sub-folder-add-modal',
  templateUrl: './sub-folder-add-modal.component.html',
  styleUrls: ['./sub-folder-add-modal.component.scss']
})
export class SubFolderAddModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SubFolderAddModalComponent>, private service: FilesService, @Inject(MAT_DIALOG_DATA) public data: Folder) { }

  ngOnInit(): void {
  }

  public form: FormGroup = new FormGroup({
    name: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
  });

  public cancelModal = (data: SubFolder = null) => {
    this.dialogRef.close(data);
  }

  public onAdd = async() => {

    let folder = new SubFolder();
    folder.IdParentFolder = this.data.Id;
    folder.Name = this.form.controls.name.value;

    let result: any = await this.service.CreateSubFolder(folder);

    if(result.error){
      return;
    }
    this.cancelModal(result);
  }
}
