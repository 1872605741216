import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import DataTable from 'src/app/components/table/table.model';
import { PreSeasonService } from '../pre-season/pre-season.service';
import PreSeason from '../pre-season/pre-season.model';
import { GrowersService } from '../../growers/growers.service';
import { PreHarvestModalAddComponent } from './pre-harvest-modal-add/pre-harvest-modal-add.component';
import { PreHarvestModalDeleteComponent } from './pre-harvest-modal-delete/pre-harvest-modal-delete.component';
import { PreHarvestModalEditComponent } from './pre-harvest-modal-edit/pre-harvest-modal-edit.component';


@Component({
  selector: 'app-pre-harvest',
  templateUrl: './pre-harvest.component.html',
  styleUrls: ['./pre-harvest.component.scss']
})
export class PreHarvestComponent implements OnInit {

  public preHarvest:Array<PreSeason> = [];
  public dataTable = new DataTable();
  public isPreseason:boolean = false;

    constructor(
    private router:Router,
    public dialog: MatDialog,
    private service:PreSeasonService,
    private growerService:GrowersService
  ) { }

  ngOnInit(): void {
    this.configTable();
    this.fillTable();
  }

  public back = () => {
    this.router.navigate(['forms']);
  }

  private configTable = () => {
    this.dataTable.Headers.push('GrowerName');
    this.dataTable.Headers.push('RanchName');
    this.dataTable.Headers.push('Date');
    this.dataTable.Headers.push('Lots');
    this.dataTable.Headers.push('ReviewedDate');
    this.dataTable.Headers.push('Comodities');
    this.dataTable.Headers.push('InspectedBy');
    this.dataTable.Headers.push('ReviewedBy');
    // this.dataTable.Headers.push('Preseason');
    this.dataTable.Headers.push('Actions');
  }

  private fillTable = async() =>{
    let content:Array<PreSeason> = await this.service.Get(this.isPreseason);
    let onlyPreSeasonArray = content.filter(pre =>{
      return pre.Preseason == false;
    });
    this.preHarvest = onlyPreSeasonArray;
  }

  public update = (event: {data: PreSeason, index: number}) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {data: event.data};
    dialogConfig.width = "45vw";
    let dialogRef = this.dialog.open(PreHarvestModalEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArr = this.preHarvest;
        newArr.map(pre => {
          if(pre.Id === result.Id){
            pre.ReviewedBy = result.ReviewedBy;
            pre.ReviewedDate = result.ReviewedDate;
          }
        });
        this.preHarvest = newArr;
      }
    });
  }

  public delete = (event: {dataId: number, index: number}) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {preseasonId: event.dataId};
    let dialogRef = this.dialog.open(PreHarvestModalDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.preHarvest.filter((x, i)=> i != event.index);
        this.preHarvest = newArray;
      }
    });
  }

  //MODAL
  public modalAdd = async() => {
    const growers = await this.growerService.Get();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    dialogConfig.height = "75%";
    dialogConfig.data = {growers: growers};
    let dialogRef = this.dialog.open(PreHarvestModalAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result =>{
      console.log(result);
      if(result != null){
        let newArray = this.preHarvest.concat(result);
        this.preHarvest = newArray;
      }
    });
  }

}
