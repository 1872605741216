<div class="main-login">
    <div class="container">
        <mat-card class="z-depth center" flex="50">
            <figure>
                <img src="assets/LoginIcon.png"/>
            </figure>
            <form (ngSubmit)="singIn()">
                <mat-form-field>
                    <mat-label>Username</mat-label>
                    <span matPrefix><mat-icon >lock</mat-icon> &nbsp;</span>
                    <input matInput name="authUsername" [(ngModel)]="authUser.authUsername" [formControl]="authUsernameControl" [errorStateMatcher]="authUsernameMatcher"/>
                    <mat-error *ngIf="authUsernameControl.hasError('required')">
                        <strong>Required</strong>
                    </mat-error>
                    <mat-error *ngIf="authUsernameControl.hasError('maxLength')">
                        <strong>Username Cannot contain more than 20 characters</strong>
                    </mat-error>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <span matPrefix><mat-icon >vpn_key</mat-icon> &nbsp;</span>
                    <input matInput type="password" name="authPassword" [(ngModel)]="authUser.authPassword"  [formControl]="authPaswordControl" [errorStateMatcher]="authPasswordMatcher"/>
                    <mat-error *ngIf="authPaswordControl.hasError('required')">
                        <strong>Required</strong>
                    </mat-error>
                    <mat-error *ngIf="authUsernameControl.hasError('maxLength')">
                        <strong>Username Cannot contain more than 50 characters</strong>
                    </mat-error>
                    <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
                <button mat-button type="submit" [disabled]="authPaswordControl.hasError('required') || authUsernameControl.hasError('required') || loading">Sign in</button>
            </form>
        </mat-card>
    </div>
</div>
