import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MachinesAndEquipment } from '../../../models/machinesAndEquipment.model';
import { MachinesAndEquipmentService } from '../../machines-component/machines-component.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-machine-v2',
  templateUrl: './create-machine-v2.component.html',
  styleUrls: ['./create-machine-v2.component.scss']
})
export class CreateMachineV2Component implements OnInit {

  constructor(public dialogRef: MatDialogRef<CreateMachineV2Component>, private service: MachinesAndEquipmentService, @Inject(MAT_DIALOG_DATA) public data: {machine: MachinesAndEquipment}) { }

  ngOnInit(): void {
    if(this.data.machine !== null) {
      this.form.controls.description.setValue(this.data.machine.Description);
      this.form.controls.manufacturer.setValue(this.data.machine.Manufacturer);
      this.form.controls.hf.setValue(this.data.machine.HF);
      this.form.controls.plate.setValue(this.data.machine.LicensePlate);
      this.form.controls.date.setValue(this.data.machine.Date);
    }
  }

  public form: FormGroup = new FormGroup({
    id: new FormControl(0),
    description: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    manufacturer: new FormControl('', Validators.required),
    hf: new FormControl('', Validators.required),
    plate: new FormControl('', [Validators.maxLength(50), Validators.minLength(3)]),
    date: new FormControl('', [Validators.required]),
  });

  //Function Para cerrar y crear modal
  public cancelModal = (result: any) => {
    this.dialogRef.close(result);
  }

  public createMachinesAndEquipment = async(update: boolean) => {
    let machine = new MachinesAndEquipment();
    
    machine.Id = this.form.controls.id.value;   
    machine.Description = this.form.controls.description.value;   
    machine.Manufacturer = this.form.controls.manufacturer.value;
    machine.HF = this.form.controls.hf.value;
    machine.LicensePlate = this.form.controls.plate.value;
    machine.Date = this.form.controls.date.value;
    
    let result: any = update ? (await this.service.Edit(machine)) : (await this.service.Create(machine));
    if(result.error){
      return;
    }
    this.cancelModal(result);

  }

}
