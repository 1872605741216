import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MachinesAndEquipmentService } from '../machines-component.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-machine-modal-delete',
  templateUrl: './machine-modal-delete.component.html',
  styleUrls: ['./machine-modal-delete.component.scss']
})
export class MachineModalDeleteComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: {machinesAndEquipmentId: number} ,public dialogRef: MatDialogRef<MachineModalDeleteComponent>, private service: MachinesAndEquipmentService) { }

  ngOnInit(): void {
  }

  public cancelModal = (data: any = null) => {
    this.dialogRef.close(data);
  }

  public delete = async() => {
    let result: any = await this.service.Delete(false, this.data.machinesAndEquipmentId);
    if(result.recordset == void 0 || result.recordset == null || result.error){
      this._snackBar.open(`This item can't be deleted, because is used in another place`, 'Undo');
      this.cancelModal();
    } else
      this.cancelModal(this.data.machinesAndEquipmentId);
    
  }
}
