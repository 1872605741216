import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-modal-delete',
  templateUrl: './user-modal-delete.component.html',
  styleUrls: ['./user-modal-delete.component.scss']
})
export class UserModalDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UserModalDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: {userId: number, loginId: number}, private userService: UserService) { }

  ngOnInit(): void {
  }

  public cancelModal = (userId: number = null) => {
    this.dialogRef.close(userId);
  }

  public delete = async() => {
    let result: any = await this.userService.Delete(this.data);
    if(!result.error)
      this.cancelModal(this.data.userId);
  }
}
