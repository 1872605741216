import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {PreSeasonModalAddComponent} from './pre-season-modal-add/pre-season-modal-add.component';
import DataTable from 'src/app/components/table/table.model';
import { PreSeasonService } from './pre-season.service';
import PreSeason from './pre-season.model';
import { GrowersService } from '../../growers/growers.service';
import { PreSeasonModalDeleteComponent } from './pre-season-modal-delete/pre-season-modal-delete.component';
import { PreSeasonModalEditComponent } from './pre-season-modal-edit/pre-season-modal-edit.component';

@Component({
  selector: 'app-pre-season',
  templateUrl: './pre-season.component.html',
  styleUrls: ['./pre-season.component.scss']
})

export class PreSeasonComponent implements OnInit {
  public preSeasons:Array<PreSeason> = [];
  public dataTable = new DataTable();
  public isPreseason:boolean = true;

  constructor(
    private router:Router,
    public dialog: MatDialog,
    private service:PreSeasonService,
    private growerService:GrowersService
  ) { }

  ngOnInit(): void {
    // this.displayedColumns = ['Actions'];
    // this.dataSource = new MatTableDataSource([]);
    this.configTable();
    this.fillTable();
  }

  public back = () => {
    this.router.navigate(['forms']);
  }

  private configTable = () => {
    this.dataTable.Headers.push('GrowerName');
    this.dataTable.Headers.push('RanchName');
    this.dataTable.Headers.push('Date');
    this.dataTable.Headers.push('Lots');
    this.dataTable.Headers.push('ReviewedDate');
    this.dataTable.Headers.push('Comodities');
    this.dataTable.Headers.push('InspectedBy');
    this.dataTable.Headers.push('ReviewedBy');
    // this.dataTable.Headers.push('Preseason');
    this.dataTable.Headers.push('Actions');
  }

  private fillTable = async() =>{
    let content:Array<PreSeason> = await this.service.Get(this.isPreseason);
    let onlyPreSeasonArray = content.filter(pre =>{
      return pre.Preseason == true;
    });
    this.preSeasons = onlyPreSeasonArray;
  }

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  // public displayedColumns: Array<string>;
  // public dataSource: MatTableDataSource<any>;

  // ngAfterViewInit(): void {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  // public applyFilter(filterValue: string) {
  //   filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  //   this.dataSource.filter = filterValue;
  // }

  // public checkData = (data, header) => {
  //   if(header == 'Actions')
  //     return '';

  //   if(data == null)
  //     return 'Not captured';

  //   if(typeof data == 'string'){
  //     if(data.trim() == '' || data == 'null' || data == 'Null')
  //       return 'Not captured'
  //   }

  //   if(header == 'Status') {
  //     if(data)
  //       return 'Active';
  //     else
  //       return 'Inactive';
  //   }

  //   return data;
  // }

  public update = (event: {data: PreSeason, index: number}) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {data: event.data};
    dialogConfig.width = "45vw";
    let dialogRef = this.dialog.open(PreSeasonModalEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArr = this.preSeasons;
        newArr.map(pre => {
          if(pre.Id === result.Id){
            console.log('entre');
            pre.ReviewedBy = result.ReviewedBy;
            pre.ReviewedDate = result.ReviewedDate;
          }
        });
        this.preSeasons = newArr;
      }
    });
  }

  public delete = (event: {dataId: number, index: number}) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {preseasonId: event.dataId};
    let dialogRef = this.dialog.open(PreSeasonModalDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.preSeasons.filter((x, i)=> i != event.index);
        this.preSeasons = newArray;
      }
    });
  }

  public detail = (event:any) => {
    console.log("works")
  }

  //MODAL
  public modalAdd = async() => {
    const growers = await this.growerService.Get();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    dialogConfig.height = "75%";
    dialogConfig.data = {growers: growers};
    let dialogRef = this.dialog.open(PreSeasonModalAddComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result =>{
      console.log(result);
      if(result != null){
        let newArray = this.preSeasons.concat(result);
        this.preSeasons = newArray;
      }
    });
  }
}
