import { Component, OnInit } from '@angular/core';
import DataTable from '../../components/table/table.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ContractorsService } from './contractors.service';
import { AddContratorComponent } from './add-contrator/add-contrator.component';
import { EditContratorComponent } from './edit-contrator/edit-contrator.component';
import { DeleteContratorComponent } from './delete-contrator/delete-contrator.component';

@Component({
  selector: 'app-contractors',
  templateUrl: './contractors.component.html',
  styleUrls: ['./contractors.component.scss']
})
export class ContractorsComponent  implements OnInit {

  public contractors: any[] = [];
  public dataTable: DataTable = new DataTable();
  public isCon: boolean = true;

  constructor(public dialog: MatDialog, private contractorService: ContractorsService) { }

  async ngOnInit() {
    await this.get();
    this.configTable();
  }

  private get = async() => {
    try {
      this.contractors = await this.contractorService.Get();
    }catch(error){
      console.log(error);
    }
  }

  private configTable = () => {
    this.dataTable.Headers.push('Contact');
    this.dataTable.Headers.push('ContactName');
    this.dataTable.Headers.push('Status');
    this.dataTable.Headers.push('Actions');
  }

  // UPDATE --------------->
  public update = (event: {data: any, index: number}) => {
    this.modalEdit(event);
  }

  // DELETE --------------->
  public delete = (event: {dataId: number, index: number}) => {
    this.modalDelete(event);
  }

  //Open Modal Create
  public modalAdd = () => {
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
    let dialogRef = this.dialog.open(AddContratorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.contractors.concat(result);
        this.contractors = newArray;
      }
    });
  }

  //Open Modal Edit
  private modalEdit = (event: {data: any, index: number}) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {contractor: event.data};
    let dialogRef = this.dialog.open(EditContratorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.contractors;
        newArray.map(contractor => {
          if(contractor.Id === result.Id){
            contractor.Contact = result.Contact;
            contractor.ContactName = result.ContactName;
            contractor.Status = result.Status;
          }
        })
        this.contractors = newArray;
      }
    });
  }

  // Open Modal Delete
  private modalDelete = (event: {dataId: number, index: number}) => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {contractorId: event.dataId};
    let dialogRef = this.dialog.open(DeleteContratorComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.contractors.filter((x, i)=> i != event.index);
        this.contractors = newArray;
      }
    });
  }
}
