<div class="container" id="modal">
  <mat-toolbar>
    <span class="fill-space"></span>
    <span>Edit Grower</span>
    <span class="fill-space"></span>
    <button mat-icon-button (click)="cancelModal(null)"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  
  <form [formGroup]="form">
    <div class="identity-information">
      <h2>Identity Information</h2>
    </div>
    <mat-divider></mat-divider>
    
    <div class="block1">
      <mat-form-field>
       <mat-label>Contractor Name</mat-label>
       <input matInput type="text" formControlName="contactName" placeholder="Contact Name" [formControl]="form.controls['contactName']" [errorStateMatcher]="matcher">
       <mat-error *ngIf="form.controls['contactName'].errors?.required">Contact name is required</mat-error>
       <mat-error *ngIf="form.controls['contactName'].errors?.maxLength">Contact name cannot contain more than 100 letters</mat-error>
       <mat-error *ngIf="form.controls['contactName'].errors?.minLength">Contact name cannot contain less than 3 letters</mat-error>
      </mat-form-field>
    </div>

    <div class="block2">
      <mat-form-field>
        <mat-label>Contact email or phone number</mat-label>
        <input matInput type="text" formControlName="contact" placeholder="Contact information" [formControl]="form.controls['contact']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['contact'].errors?.required">Contact information is required</mat-error>
        <mat-error *ngIf="form.controls['contact'].errors?.maxLength">Contact information cannot contain more than 150 letters</mat-error>
        <mat-error *ngIf="form.controls['contact'].errors?.minLength">Contact information cannot contain less than 3 letters</mat-error>
      </mat-form-field>
    </div>

  </form>
  
  <footer class="dialog-footer">
    <div class="button-row">
      <button mat-raised-button color="primary" (click)="onEdit()" [disabled]="!form.valid">Edit</button>
      <button mat-raised-button color="secondary" (click)="cancelModal(null)">Cancel</button>
    </div>
  </footer>
</div>  