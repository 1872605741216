import { Inject, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { History_Report } from '../machineReports.model';
import * as moment from 'moment';

@Component({
  selector: 'app-machine-modal-detail-v2',
  templateUrl: './machine-modal-detail-v2.component.html',
  styleUrls: ['./machine-modal-detail-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MachineModalDetailV2Component implements OnInit {

  public history: History_Report = new History_Report();

  constructor(public dialogRef: MatDialogRef<MachineModalDetailV2Component>, @Inject(MAT_DIALOG_DATA) private data: {form: History_Report}) { }

  ngOnInit(): void {
    this.history = this.data.form;
    this.history.Status_Name = this.history.Status ? 'Active' : 'Finish';
    this.history.Date_Create_Formatted = this.formatDate(this.history.Date_Create);
    if(!this.history.Status){
      this.history.Date_Finish_Formatted = this.formatDate(this.history.Date_Finish);
    }
  }

  public cancelModal = (result: any = null) => {
    this.dialogRef.close(result);
  }

  public formatDate = (date) => {
    let dates = moment.utc(date).local().format().split('T');
    return `${dates[0]} ${dates[1].substring(0,5)}`;
  }
}
