<div class="container">
    <mat-card>
        <div class="title">
            <button mat-icon-button (click)="back()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <h1>Daily Reports</h1>
        </div>
        <mat-tab-group>
            <mat-tab label="V1">
                <div class="tab-content">
                    <h1>Filters</h1>
                    <div>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Select Grower</mat-label>
                            <mat-select [(ngModel)]="growerId">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let grower of growers" [value]="grower.value">
                                    {{grower.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Select Ranch</mat-label>
                            <mat-select [(ngModel)]="ranchId">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let ranch of ranches" [value]="ranch.value">
                                    {{ranch.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Select User</mat-label>
                            <mat-select [(ngModel)]="userId">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let user of users" [value]="user.value">
                                    {{user.Name}} {{user.LastName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <h1>Select date to start search</h1>
                    <div>
                        <mat-form-field appearance="fill" class="input-custom" (click)="dp1.open()">
                            <mat-label>From Date</mat-label>
                            <input matInput [matDatepicker]="dp1" [(ngModel)]="fromDate" [max]="toDate ? toDate : maxDate" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                            <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom" (click)="dp2.open()">
                            <mat-label>To Date</mat-label>
                            <input matInput [matDatepicker]="dp2" (click)="dp2.open()" [(ngModel)]="toDate" [min]="fromDate" [max]="maxDate" autocomplete="off">
                            <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                            <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Language</mat-label>
                            <mat-select [(ngModel)]="lang">
                                <mat-option [value]="'en'">English</mat-option>
                                <mat-option [value]="'es'">Español</mat-option>
                            </mat-select>
                        </mat-form-field>  
                    </div>
                    <div>
                        <button mat-raised-button color="primary" (click)="getData()">
                            Submit filters
                        </button>
                    </div>
                    <div class="main-table">
                        <app-table [data]="dailyV1Reports" [dataTable]="dailyV1ReportsdataTable" [isReport]="true" (detailItem)="detail($event)" (downloadItem)="download($event)" (downloadAllItems)="downloadAll($event)"></app-table>
                    </div>
                </div>
            </mat-tab>
            <!-- <mat-tab label="V2">
                <div>
                    <form>
                        <div class="flex-box">
                            <h1>Filters</h1>
                            <div>
                                <mat-form-field appearance="fill">
                                    <mat-label>From Date</mat-label>
                                    <input matInput [matDatepicker]="dp1" disabled>
                                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                    <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>To Date</mat-label>
                                    <input matInput [matDatepicker]="dp3" disabled>
                                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Language</mat-label>
                                    <mat-select matNativeControl required>
                                        <mat-option value="English">English</mat-option>
                                        <mat-option value="Español">Español</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field appearance="fill">
                                    <mat-label>Select Grower</mat-label>
                                    <mat-select matNativeControl required>

                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Select Ranch</mat-label>
                                    <mat-select matNativeControl required>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Select User</mat-label>
                                    <mat-select matNativeControl required>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div>
                                <button mat-button>Apply Filter</button>
                            </div>
                        </div>
                    </form>

                    <div class="main-table">
                        <div class="filter" fxLayout="row">
                            <mat-form-field>
                                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                            </mat-form-field>
                        </div>

                        <div class="mat-elevation-z8">
                            <mat-table [dataSource]="dataSource" matSort>

                                <ng-container *ngFor="let headCol of displayedColumns; let i = index" matColumnDef="{{ headCol }}">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header> {{ headCol }} </mat-header-cell>
                                    <mat-cell *matCellDef="let row" [attr.data-label]="headCol">
                                        <div *ngIf="headCol == 'Actions'">
                                            <button *ngIf="row.ContactName != null" mat-button (click)="ranch(row.Id);">
                                             <mat-icon>map</mat-icon>
                                            </button>
                                            <button mat-button (click)="update(row, i);">
                                             <mat-icon>edit</mat-icon>
                                            </button>
                                            <button mat-button (click)="delete(row, i)">
                                             <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                        {{ checkData(row[headCol], headCol) }}
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;">
                                </mat-row>
                            </mat-table>

                            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                        </div>
                    </div>
                </div>   
            </mat-tab> -->
        </mat-tab-group>
    </mat-card>
</div>