<div class="container">
    <mat-toolbar>
        <span class="fill-space"></span>
        <span>Create Report</span>
        <span class="fill-space"></span>
        <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
    <form [formGroup]="form">
        <!-- <div>
            <section>
                <mat-checkbox>on - Pre season / off - Pre harvest</mat-checkbox>
            </section>
        </div> -->
        <div>
          <mat-form-field>
            <mat-label>Grower*</mat-label>
            <mat-select [(ngModel)]="growerId" (ngModelChange)="growerChange(growerId)" [ngModelOptions]="{standalone: true}">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let grower of growers" [value]="grower.Id">{{grower.Name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Ranch*</mat-label>
            <mat-select formControlName="ranchId">
              <mat-option [value]="null">None</mat-option>
              <mat-option *ngFor="let ranch of ranchesFiltered" [value]="ranch.Id">
                  {{ranch.Name}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="fill">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="Date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Hour*</mat-label>
              <mat-select  formControlName="hours" placeholder="none">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="null" *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field>
              <mat-label>Minute*</mat-label>
              <mat-select  formControlName="minutes">
                <mat-option [value]="null">None</mat-option>
                <mat-option [value]="null" *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
              </mat-select>
          </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Comodity(ies)*</mat-label>
                <input matInput type="text" formControlName="Comodities">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Lot(s)*</mat-label>
                <input matInput type="text" formControlName="Lots">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Inspected by*</mat-label>
                <input matInput type="text" formControlName="InspectedBy">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Reviewed By*</mat-label>
                <input matInput type="text" formControlName="ReviewedBy">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Reviewed Date*</mat-label>
                <input matInput [matDatepicker]="picker2" formControlName="ReviewedDate">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
        </div>
        <div>
            <h4>Report Info</h4>
        </div>
        <div class="sub-title">
            <h2><strong>Water</strong></h2>
        </div>
        <mat-divider></mat-divider>
        <div class="seccion">
            <p>What is/are the sources of water for this ranch?</p>
            <br>
            <section>
                <div class="flex-checkbox">
                    <mat-checkbox formControlName="WellWater">Well</mat-checkbox>
                    <mat-checkbox formControlName="TerrainWater">Surface/Canal/Reservoir</mat-checkbox>
                    <mat-checkbox formControlName="SupplyWater">Municipal Water Supply</mat-checkbox>
                    <mat-checkbox formControlName="Other">Other(explain)</mat-checkbox>
                </div>
                <mat-form-field class="fila">
                    <mat-label>Other description</mat-label>
                    <input matInput type="text" formControlName="OtherExplain">
                </mat-form-field>
                <div class="flex-checkbox">
                    <div class="large-checkbox"> <mat-checkbox formControlName="Water1">Are all sources of water and distribution <br> systems clearly identified on the ranch map?</mat-checkbox></div>
                    <div class="large-checkbox"> <mat-checkbox formControlName="Water2">Was a water system assessment completed <br> for each water source?</mat-checkbox></div>
                    <div class="large-checkbox"> <mat-checkbox formControlName="Water3">Will all the water sources be tested for <br> generic E. coli prior to planting?</mat-checkbox></div>
                </div>
            </section>
        </div>
        <div class="seccion">
            <div class="sub-title">
                <h2><strong>Soil Amendments</strong></h2>
            </div>
            <mat-divider></mat-divider>
            <section>
                <div>
                    <mat-checkbox formControlName="Soil1">Is raw or partially composted animal manure used to supplement soil?</mat-checkbox>
                </div>
                <div class="flex-checkbox">
                   <div class="large-checkbox"> <mat-checkbox formControlName="Soil2">Is composted animal manure or aged animal manure used to supplement the soil?</mat-checkbox></div>
                   <div class="large-checkbox"> <mat-checkbox formControlName="Soil2Explain">If yes, was it composted according to LGMA requirements?</mat-checkbox></div>
                </div>
                <div class="flex-checkbox">
                    <div class="large-checkbox"> <mat-checkbox formControlName="Soil3">Is animal manure that was physically heat treated to supplement the soil?</mat-checkbox></div>
                    <div class="large-checkbox"> <mat-checkbox formControlName="Soil3Explain">If yes, was it composted according to LGMA requirements?</mat-checkbox></div>
                 </div>
                 <div class="flex-checkbox">
                    <div class="large-checkbox"> <mat-checkbox formControlName="Soil4">Are non-synthetic crop treatments <br>(compost teas, fish emulsions, <br> fish meal, blood meal, bio-fertilizer, etc.) used to supplement the soil?</mat-checkbox></div>
                    <div class="large-checkbox"> <mat-checkbox formControlName="Soil4Explain">If yes, was it tested in accordance to LGMA requirements?</mat-checkbox></div>
                 </div>
                 <div class="flex-checkbox">
                    <div class="large-checkbox"> <mat-checkbox formControlName="Soil5">Are any soil amendments stored near fields?</mat-checkbox></div>
                    <div class="large-checkbox"> <mat-checkbox formControlName="Soil5Explain">If yes, was it tested in accordance to LGMA requirements?</mat-checkbox></div>
                 </div>
            </section>
        </div>
        <div class="seccion">
            <div class="sub-title">
                <h2><strong>Environmental Factors</strong></h2>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="Environment1">Is there evidence of wildlife activity in or around the growing area <br>(animals present, animal tracks, feces/urine, plant feeding)?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="Environment1Explain">If yes, are there corrective actions in place?</mat-checkbox></div>
            </div>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="Environment2">Is there a history of flooding?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="Environment2Explain">If yes, did flooding occur within the last 60 days?</mat-checkbox></div>
            </div>
            <div class="form-questions">
                <h6>Is there any evidence of the following environmental/weather related conditions</h6>
            </div>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentTemperature">Extreme temperatures?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentPrecipitation">Excessive precipitation?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentWind">High winds?</mat-checkbox></div>
            </div>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="Environment3">Is there any evidence of weather-related crop damage?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="Environment4">Have equipment/tools been exposed to adverse weather events or conditions?</mat-checkbox></div>
            </div>
            <div class="form-questions">
                <h6>Are any of the following LGMA adjacent land uses present?</h6>
            </div>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentCafo">CAFO (1200 feet)?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentAmendments">Storage of non-synthetic <br> soil amendments (1200 feet)?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentGrazing">Grazing lands or <br> domestic animals <br> (30 feet)?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentSeptic">Septic leach fields <br>(home or other building, 30 feet)?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentProtection">Well head and <br>surface water<br> protection?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentAreas">Riparian <br> areas?</mat-checkbox></div>
            </div>
            <div class="form-questions">
                <h6>Riparian areas? If you answered “yes” next to CAFO’s, did you observe evidence of the following</h6>
            </div>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentCafoExplain1">Manure runoff or other discharges into surface water sources?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentCafoExplain2">Circumstances or situations that could impact the quality of water <br> used for irrigation or other direct crop-contact applications?</mat-checkbox></div>
            </div>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentCafoExplain3">Large populations of flies or birds flying between feedlot and production area?</mat-checkbox></div>
                <div class="large-checkbox"> <mat-checkbox formControlName="EnvironmentCafoExplain4">Manure or compost storage near sources of irrigation water or produce fields?</mat-checkbox></div>
            </div>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="Environment5">Have they all been addressed in accordance with LGMA?</mat-checkbox></div>
            </div>
        </div>
        <div>
            <div class="sub-title">
                <h2><strong>Field Employee Practices & Hygiene</strong></h2>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="EmployeeHygiene">Is there any evidence that worker hygiene rules have been violated?</mat-checkbox></div>
            </div>
            <div>
                <mat-form-field>
                    <mat-label>If yes, what remedial action was taken</mat-label>
                    <input matInput type="text" formControlName="EmployeeHygieneExplain">
                </mat-form-field>
            </div>
        </div>
        <div class="seccion">
            <div class="sub-title">
                <h2><strong>Field Sanitation</strong></h2>
            </div>
            <mat-divider></mat-divider>
            <div class="flex-checkbox">
                <div class="large-checkbox"> <mat-checkbox formControlName="Sanitation">Is there a specific individual responsible for identifying and documenting potential contamination risks during the growing and harvesting of crops?</mat-checkbox></div>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="seccion">
            <div class="sub-title">
                <h2><strong>Comments</strong></h2>
            </div>
            <div class="form-questions">
                <h6>ALLERGENS PRESENT</h6>
            </div>
            <mat-form-field>
                <mat-label>Water</mat-label>
                <input matInput type="text" formControlName="WaterComments">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Soil amendments</mat-label>
                <input matInput type="text" formControlName="SoilComments">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Environmental Factors</mat-label>
                <input matInput type="text" formControlName="EnvironmentComments">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Field employee practices & Hygiene</mat-label>
                <input matInput type="text" formControlName="EmployeeComments">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Field sanitation</mat-label>
                <input matInput type="text" formControlName="SanitationComments">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Allergens present</mat-label>
                <input matInput type="text" formControlName="AllergentsComments">
            </mat-form-field>
        </div>
    </form>
    <footer class="dialog-footer">
        <div class="button-row">
            <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
            <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="Create()">Save</button>
        </div>
    </footer>
</div>
