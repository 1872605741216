import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreSeasonService } from '../pre-season.service';

@Component({
  selector: 'app-pre-season-modal-delete',
  templateUrl: './pre-season-modal-delete.component.html',
  styleUrls: ['./pre-season-modal-delete.component.scss']
})
export class PreSeasonModalDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PreSeasonModalDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: {preseasonId: number}, private service: PreSeasonService) { }

  ngOnInit(): void {
  }

  public cancelModal = (data) => {
    this.dialogRef.close(data);
  }

  public delete = async() => {
    let result: any = await this.service.Delete(this.data.preseasonId);
    if(!result.error)
      this.cancelModal(this.data.preseasonId);
  }

}
