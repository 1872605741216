<div class="main">
    <mat-card>
        <div class="title">
            <button mat-icon-button (click)="back()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <h1>Grower Ranches</h1>
        </div>
        <p>Control and manage of application grower ranches</p>
        <button mat-raised-button *ngIf="ranches.length > 0" (click)="modalAdd()">Create new Ranch</button>
        <div *ngIf="ranches.length > 0">
            <app-table [data]="ranches" [dataTable]="dataTable" (updateItem)="update($event)" (deleteItem)="delete($event)"></app-table>
        </div>
        <div *ngIf="ranches.length == 0" style="text-align: center;">
            <h1>Ranches table is empty</h1>
            <button mat-raised-button (click)="modalAdd()">Create new Ranch</button>
        </div>
    </mat-card>
</div>
