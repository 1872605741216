import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Grower } from '../../../models/grower.model';
import { ContractorsService } from '../contractors.service';

export class ContractorAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-add-contrator',
  templateUrl: './add-contrator.component.html',
  styleUrls: ['./add-contrator.component.scss']
})
export class AddContratorComponent implements OnInit {

  public matcher:ContractorAddErrorStateMatcher = new ContractorAddErrorStateMatcher();

  constructor(public dialogRef: MatDialogRef<AddContratorComponent>, private service: ContractorsService) {}

  ngOnInit(): void {
  }

  public form: FormGroup = new FormGroup({
    contactName: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
    contact: new FormControl('',[Validators.required, Validators.maxLength(150), Validators.minLength(3)]),
  });
  
  public cancelModal = (result: Grower = null) => {
    this.dialogRef.close(result);
  }
  
  public createContractor = async() => {
    let contractor: any = {};

    contractor.Contact = this.form.controls.contact.value;
    contractor.ContactName = this.form.controls.contactName.value;

    let result: any = await this.service.Create(contractor);
    
    if(result.error){
      return;
    }
    this.cancelModal(result);

  }

}
