import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Grower } from '../../../models/grower.model';
import { Ranch } from '../../../views/ranches/ranches.model';
import { FilesService } from '../files.service';
import Folder from '../models/folder.model';

export class UserAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-folder-add-modal',
  templateUrl: './folder-add-modal.component.html',
  styleUrls: ['./folder-add-modal.component.scss']
})
export class FolderAddModalComponent implements OnInit {

  public growers: Array<Grower> = [];
  public ranches: Array<Ranch> = [];

  public form: FormGroup = new FormGroup({
    Name: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]),
    IdGrower: new FormControl(null),
    IdRanch: new FormControl(null),
  });

  constructor(
    public dialogRef: MatDialogRef<FolderAddModalComponent>,
    private service: FilesService,
    @Inject(MAT_DIALOG_DATA) public data: { growers: Array<Grower>}) {}

  ngOnInit(): void {
    this.Init();    
  }
  
  private Init = async () => {
    this.growers = this.data.growers;
  }

  public growerChange = async () => {
    if(this.form.controls.IdGrower.value) {
      this.ranches = await this.service.GetRanches(this.form.controls.IdGrower.value);
    } else {
      this.form.controls.IdRanch.setValue(null);
    }
  }

  public cancelModal = (data: Folder = null) => {
    this.dialogRef.close(data);
  }

  public onAdd = async() => {
    let folder = new Folder();
    folder.Name = this.form.controls.Name.value;
    folder.IdGrower = this.form.controls.IdGrower.value;
    folder.IdRanch = this.form.controls.IdRanch.value;

    let result: any = await this.service.CreateFolder(folder);

    if(result.error){
      return;
    }
    this.cancelModal(result);
  }
}
