<div class="main">
  <mat-card>
      <h1>Contractors</h1>
      <p>Control and manage of application Contractors</p>
      <button mat-raised-button *ngIf="contractors.length > 0" (click)="modalAdd()">Create new Contractor</button>
      <div *ngIf="contractors.length > 0">
          <app-table [data]="contractors" [dataTable]="dataTable" [isContractor]="isCon" (updateItem)="update($event)" (deleteItem)="delete($event)"></app-table>
      </div>
      <div *ngIf="contractors.length == 0" style="text-align: center;">
          <h1>Contractors table is empty</h1>
          <button mat-raised-button (click)="modalAdd()">Create new contractor</button>
      </div>
  </mat-card>
</div>
