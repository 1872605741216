<div class="container">
    <mat-card>
        <div class="title">
            <button mat-icon-button (click)="back()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <h1>Machine Reports</h1>
        </div>
        <mat-tab-group>
            <mat-tab label="V2">
                <div class="tab-content">
                    <h1>Filters</h1>
                    <div class="flex">
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Select HF</mat-label>
                            <mat-select [(ngModel)]="Machine_HF">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let machine of machinesAndEquipments" [value]="machine.Id">
                                    {{machine.HF}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="input-custom">
                            <mat-label>Select Type</mat-label>
                            <mat-select [(ngModel)]="Type_Id">
                                <mat-option [value]="null">None</mat-option>
                                <mat-option *ngFor="let type of types" [value]="type.Id">
                                    {{type.Name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" (click)="getData()">
                            Submit filters
                        </button>
                    </div>
                    <div class="main-table">
                        <app-table [data]="machineReports" [dataTable]="dataTable" [isReport]="true" [isMachine]="true" (detailItem)="detail($event)"></app-table>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-card>
</div>