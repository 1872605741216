import { Component, OnInit } from '@angular/core';
import File from './models/file.model';
import Folder from './models/folder.model';
import SubFolder from './models/sub-folder.model';
import { FilesGuestsService } from './files-guests.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-files-guests',
  templateUrl: './files-guests.component.html',
  styleUrls: ['./files-guests.component.scss']
})
export class FilesGuestsComponent implements OnInit {

  public folders: Array<Folder> = [];
  public folderSelected: Folder = new Folder();
  public subFolderSelected: SubFolder = new SubFolder();
  public files: Array<any> = [];
  public path = '';
  public backArray: Array<any>;
  public tempID: any;
  public tempIDFile: any;

  constructor(private service: FilesGuestsService) { }

  ngOnInit(): void {
    this.Init();
  }

  private Init = async () => {
    this.folders = await this.service.GetFolders();
  }

  public selectFolder = async(folder: Folder) => {
    this.folderSelected = folder;
    this.path = `${folder.Name}/`;
    this.tempIDFile = folder.Id;
    this.files = await this.service.GetSubFolders(folder.Id);
    let folderFiles =  await this.service.GetFiles(folder.Id);
    this.backArray = this.files;
    if(folderFiles.length > 0){
      folderFiles.map(file => this.files.push(file));
    }
  }

  private selectSubFolder = async(subFolder: SubFolder) => {
    this.path += `${subFolder.Name}/`;
    this.subFolderSelected = subFolder;
    this.tempID = subFolder;
    this.files = await this.service.GetSubFolders(subFolder.Id);
    let folderFiles =  await this.service.GetFiles(subFolder.Id);
    if(folderFiles.length > 0){
      folderFiles.map(file => this.files.push(file));
    }
  }

  private selectFile = async(file: File) => {
    window.open(`${environment.STORAGE}/${file.Name}`, '_blank');
  }

  public managerAction = (fileOrFolder: any) => {
    if(fileOrFolder.IdParentFolder)
      this.selectSubFolder(fileOrFolder);
    else
      this.selectFile(fileOrFolder);
  }

  public shortName = (folderName: string) => {
    if(folderName.length > 27) {
      return `${folderName.substring(0, 27)}...`
    }
  }

  public redirectBack = async() => {
    let cutPath = this.path.indexOf("/") + 1;
    let finalPath = this.path.substr(0, cutPath);
    this.path = finalPath;
    if(!this.backArray[this.backArray.length - 1].IdParentFolder){
      console.log(this.tempID.Id);
      this.files = await this.service.GetSubFolders(this.tempID.IdParentFolder);
      let folderFiles =  await this.service.GetFiles(this.tempIDFile);
      if(folderFiles.length > 0){
        folderFiles.map(file => this.files.push(file));
      }
    }else{
      this.files = await this.service.GetSubFolders(this.backArray[this.backArray.length - 1].IdParentFolder);
      let folderFiles =  await this.service.GetFiles(this.tempIDFile);
      if(folderFiles.length > 0){
        folderFiles.map(file => this.files.push(file));
      }
      console.log(this.files, this.backArray[this.backArray.length - 1].IdParentFolder);
    }

  }
}
