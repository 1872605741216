<div class="container-report">
    <mat-card>
        <h1>Reports</h1>
        <mat-selection-list [multiple]="false">
            <mat-list-option *ngFor="let rout of reportRoutes" [value]="rout" (click)="goTo(rout)">
                <div class="item">
                    <mat-icon>{{rout.icon}}</mat-icon>{{rout.title}}
                </div>
            </mat-list-option>
        </mat-selection-list>
    </mat-card>
</div>