<div class="container" id="modal">
  <mat-toolbar>
    <span class="fill-space"></span>
    <span>{{data.report === null ? 'New' : 'Update'}} custom report</span>
    <span class="fill-space"></span>
    <button mat-icon-button (click)="cancelModal(null)"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="form-container">
    <form >
      <div class="flex-field">
        <mat-form-field>
          <mat-label>Title </mat-label>
          <input [(ngModel)]="title" name="title" matInput type="text">
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Periodicity</mat-label>
          <mat-select [(ngModel)]="typeName" name="typeName" required>
            <mat-option [value]="false">Daily</mat-option>
            <mat-option [value]="true">Periodic</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="question-main-actions">
          <button mat-button (click)="addNewQuestion()">
            <mat-icon>add</mat-icon>
            Add new question
          </button>
        </div>
      </div>
      <div class="questions-table">
        <div class="question-rows" *ngFor="let question of questionList; let i = index">
          <div class="question-column-inputs">
            <mat-form-field>
              <mat-label>Question (spanish)</mat-label>
              <input matInput [(ngModel)]="question['question' + i]" name="{{'question' + i}}"/>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Question (english)</mat-label>
              <input matInput [(ngModel)]="question['translation' + i]" name="{{'translation' + i}}"/>
            </mat-form-field>
          </div>
          <div class="question-column-actions">
            <mat-form-field>
              <mat-label>Select input type</mat-label>
              <mat-select [(ngModel)]="question['inputType'+i]" name="{{'inputType'+i}}">
                <mat-option [value]="1">Switch</mat-option>
                <mat-option [value]="2">Text</mat-option>
                <mat-option [value]="3">Number</mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-icon-button (click)="removeQuestion(question)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <footer class="dialog-footer">
    <div class="button-row">
      <button mat-raised-button *ngIf="data.report === null" color="primary" [disabled]="this.title === '' || this.questionList.length === 0" (click)="createMachinesAndEquipment(false)">Create</button>
      <button mat-raised-button *ngIf="data.report !== null" color="primary" [disabled]="this.title === '' || this.questionList.length === 0" (click)="createMachinesAndEquipment(true)">Update</button>
      <button mat-raised-button color="secondary" (click)="cancelModal(null)">Cancel</button>
    </div>
  </footer>
</div>
