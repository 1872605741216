import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective, NgForm, FormControl, Validators } from '@angular/forms';
import { Login } from './login.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ts } from '../../app.shared';
import { LoginService } from './login.service';

export class LoginErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private _snackBar:MatSnackBar, private loginService:LoginService) { }

  public loading = false;

  ngOnInit() {}

  public authUser: Login = new Login();

  public authUsernameControl = new FormControl('', [Validators.required, Validators.maxLength(20)]);

  public authPaswordControl = new FormControl('', [Validators.required, Validators.maxLength(50)]);

  public authUsernameMatcher = new LoginErrorStateMatcher();
  public authPasswordMatcher = new LoginErrorStateMatcher();

  public toggleLoadingDisabled = () => {
    this.loading =! this.loading;
  }

  private validateFormLogin = () => {
    let values = this.authUser;
    return !(values.authUsername.trim() == '' || values.authPassword.trim() == ''); // && (values.authUsername.trim().length < 20 && values.authPassword.trim().length < 50); ??
  }

  public singIn = async() => {
    this.toggleLoadingDisabled();
    try{
      if(this.validateFormLogin()){
        // this._snackBar.open('Valid sign in', 'Ok');
        let result = await this.loginService.login(this.authUser);
        if(result.error){
          this._snackBar.open('The username or password is wrong, verify your credentials please. If the problem persist contact manager.', 'Understood');
        }else {
          window.location.reload();
        }
      }else {
          this._snackBar.open('The username or password is wrong, verify your credentials please. If the problem persist contact manager.', 'Understood');
      }
    }catch(error) {
      console.log(error)
      this._snackBar.open('The username or password is wrong, verify your credentials please. If the problem persist contact manager.', 'Understood');
    }
    this.toggleLoadingDisabled();
  }
}