<div class="container" id="modal">
  <mat-toolbar>
      <span class="fill-space"></span>
      <span>Water usage detail</span>
      <span class="fill-space"></span>
      <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="detail">
      <h1>Main information</h1>
      <mat-divider></mat-divider>
      <p>Date: {{ formatDate(form.Date) }}</p>
      <p>User name: {{ form.Name }}</p>
      <p>User lastname{{ form.LastName }}</p>
      <p>Lecture before: {{ form.Lecture_Before }} {{ getType(form.Type) }}</p>
      <img src="{{ imageUrl(form.Image_Before_Id)}}" (error)="handleMissingImage($event, form.Image_Before_Id)" />
      <p>Lecture after: {{ form.Lecture_After }} {{ getType(form.Type) }}</p>
      <img src="{{ imageUrl(form.Image_After_Id)}}" (error)="handleMissingImage($event, form.Image_After_Id)" />
      <p>Total used: {{ form.Total }} m3 (Cubic meter)</p>
  </div>
  
  <footer class="dialog-footer">
      <div class="button-row">
          <button mat-raised-button color="secondary" (click)="cancelModal()">Close</button>
      </div>
  </footer>
</div>