export class Report {
    public Id: number = null;
    public User_Id?: number = null;
    public Grower_Id: number= null;
    public Ranch_Id: number= null;
    public Contractor_Name: string= null;
    public Contractor_Contact: string= null;
    public Harvesting: string= null;
    public Yard: string= null;
    public Sub_Yard?: string= null;
    public Squad: string= null;
    public Date_Create: Date= null;
    public Date_Uploaded: Date= null;
    public Validated_By: number = null;
    public Comment_By_Validator: string = null;
    public Validate_Date: Date = null;
    public Status: boolean = null;
    public Foreman?: string = null;
    
    // External Info
    public Grower_Name: string = null;
    public Grower_Address: string = null;
    public Grower_Contact_Name: string = null;
    public Ranch_Name: string = null;
    public Ranch_Location: string = null;
    public User_Name: string = null;
    public User_Last_Name: string = null;
    public Validated_Name: string = null;
    public Validated_Last_Name: string = null;

    public harvestMachine: Harvest_Machine = new Harvest_Machine();
    public machineEquipment: Machine_Equipment = new Machine_Equipment;
    public machineInspection: Machine_Inspection = new Machine_Inspection();
    public fieldAssessment: Field_Assessment = new Field_Assessment();

    public chlorineInspections: Array<Chlorine_Inspection> = [];
    public weightInspections: Array<Weight_Inspection> = [];
    public total: number;
}

export class Harvest_Machine {
    public Id?: number = null;
    public Form_Info_Id: number = null;
    public answer1?: string= null;
    public answer2?: string= null;
    public answer3?: string= null;
    public answer4?: string= null;
    public answer5?: string= null;
    public answer6?: string= null;
    public answer7?: string= null;
    public answer8?: string= null;
    public answer9?: string= null;
    public answer10?: string= null;
    public answer11?: string= null;
    public answer12?: string= null;
    public answer13?: string= null;
    public answer14?: string= null;
    public answer15?: string= null;
    public Date_Create: Date= null;
    public Comment: string= null;
    public Status: boolean= null;
    public images: Array<Images> = [];
}

export class Machine_Equipment {
    public Id?: number = null;
    public Form_Info_Id: number= null;
    public answer1?: string= null;
    public answer2?: string= null;
    public answer3?: string= null;
    public answer4?: string= null;
    public answer5?: string= null;
    public answer6?: string= null;
    public answer7?: string= null;
    public answer8?: string= null;
    public answer9?: string= null;
    public answer10?: string= null;
    public answer11?: string= null;
    public Date_Create: Date= null;
    public Comment: string= null;
    public Status: boolean= null;
    public images: Array<Images> = [];
}

export class Machine_Inspection {
    public Id?: number = null;
    public Form_Info_Id: number= null;
    public answer1?: string= null;
    public answer2?: string= null;
    public answer3?: string= null;
    public answer4?: string= null;
    public answer5?: string= null;
    public answer6?: string= null;
    public answer7?: string= null;
    public answer8?: string= null;
    public answer9?: string= null;
    public answer10?: string= null;
    public answer11?: string= null;
    public answer12?: string= null;
    public Date_Create: Date= null;
    public Comment: string= null;
    public Status: boolean= null;
    public images: Array<Images> = [];
}

export class Field_Assessment {
    public Id?: number = null;
    public Form_Info_Id: number= null;
    public answer1?: string= null;
    public answer2?: string= null;
    public answer3?: string= null;
    public answer4?: string= null;
    public answer5?: string= null;
    public answer6?: string= null;
    public answer7?: string= null;
    public answer8?: string= null;
    public answer9?: string= null;
    public Date_Create: Date = null;
    public Comment: string= null;
    public Status: boolean= null;
    public images: Array<Images> = [];
}

export class Chlorine_Inspection {
    public Id?: number = null;
    public Form_Info_Id: number= null;
    public answer1?: string= null;
    public answer2?: string= null;
    public answer3?: string= null;
    public Date_Create: Date= null;
    public Comment: string= null;
    public Status: boolean= null;
    public images: Array<Images> = [];
}

export class Weight_Inspection {
    public Id?: number = null;
    public Form_Info_Id: number= null;
    public answer1?: string= null;
    public answer2?: string= null;
    public answer3?: string= null;
    public answer4?: string= null;
    public answer5?: string= null;
    public answer6?: string= null;
    public answer7?: string= null;
    public answer8?: string= null;
    public answer9?: string= null;
    public answer10?: string= null;
    public Date_Create: Date= null;
    public Comment: string= null;
    public Status: boolean= null;
    public images: Array<Images> = [];
}

export class Images {
    public Id?: number = null;
    public Form_Id: string= null;
    public Image_Url: string= null;
}

export class MachineAndEquipment {
    public Id: number;
    public Description: string;
    public Manufacturer: string;
    public HF: string;
    public LicencePlate: string;
    public Date: Date;
    public Status: boolean;
}


export class MachineAndEquipmentTypes {
    public Id: number;
    public Name: string;
}

export default class MachinesEquipmentReports {
    public Id: number;
    public Machine_Id: number;
    public Type_Id: number;
    public Description: string;
    public Reported_By: string;
    public Assigned_Mechanic: string;
    public Work_Order?: string = "";
    public Repairs_Order?: string = "";
    public Hours_Used: number;
    public Washed: boolean;
    public Time_Since_Repair: number;
    public Date_Create: Date;
    public Date_Finish: Date;
    public Status: boolean;
}