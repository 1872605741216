<div class="container" id="modal">
  <mat-toolbar>
    <span class="fill-space"></span>
    <span>Register Machine</span>
    <span class="fill-space"></span>
    <button mat-icon-button (click)="cancelModal(null)"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="form-container">
    <form [formGroup]="form">
      <div class="center">
        <div class="flex-field">
          <mat-form-field appearance="fill" (click)="picker2.open()" >
            <mat-label>Acquired at </mat-label>
            <input matInput [matDatepicker]="picker2" autocomplete="off" formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <mat-label>HF# </mat-label>
            <input formControlName="hf" matInput type="number">
            <mat-error *ngIf="form.controls['hf'].errors?.required">HF# is required</mat-error>
          </mat-form-field>
        </div>
        <div class="flex-field">
          <mat-form-field class="fila">
            <mat-label>Description </mat-label>
            <input formControlName="description" matInput type="text">
            <mat-error *ngIf="form.controls['description'].errors?.required">Description is required</mat-error>
          </mat-form-field>
        </div>
        <div class="flex-field">
          <mat-form-field>
            <mat-label>Manufacturer </mat-label>
            <input formControlName="manufacturer" matInput type="text">
            <mat-error *ngIf="form.controls['manufacturer'].errors?.required">Manufacturer is required</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Liscense Plate</mat-label>
            <input formControlName="plate" matInput type="text">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <footer class="dialog-footer">
    <div class="button-row">
      <button mat-raised-button *ngIf="data.machine === null" color="primary" [disabled]="!form.valid" (click)="createMachinesAndEquipment(false)">Create</button>
      <button mat-raised-button *ngIf="data.machine !== null" color="primary" [disabled]="!form.valid" (click)="createMachinesAndEquipment(true)">Update</button>
      <button mat-raised-button color="secondary" (click)="cancelModal(null)">Cancel</button>
    </div>
  </footer>
</div>
