import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-machines-reports-v2',
  templateUrl: './machines-reports-v2.component.html',
  styleUrls: ['./machines-reports-v2.component.scss']
})
export class MachinesReportsV2Component implements OnInit {

  public reportList: any[] = [];
  public displayedColumns: string[] = ['Description', 'Hours_Used', 'Reported_By', 'Assigned_Mechanic', 'Reparation_Range'];
  @Input() hf: any = '';
  @Input() reports: any[] = [];
  @Output() bullets: EventEmitter<any[]> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.reportList = this.reports;
    if(this.reportList.length > 0) {
      this.reportList.map((report: any) => {
        
        if(report.Date_Finish !== null && report.Date_Create !== null)
          report.Reparation_Range = this.diff_hours(report.Date_Finish, report.Date_Create);
        else
          report.Reparation_Range = 'Not finish';
        
        report.bulletsList = [];

        if(report.bullets.length > 0) {
          report.bullets.map((bullet) => {
            if(bullet.Description.trim().length > 0)
              report.bulletsList.push(bullet.Description);
          })
        }
      });
    }
  }

  private diff_hours = (dt2: string, dt1: string) => {

    let date1 = new Date(dt1);
    let date2 = new Date(dt2);
    let diffMs = (date2.getTime() - date1.getTime()); // milliseconds between now & Christmas
    let diffDays = Math.floor(diffMs / 86400000); // days
    let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let result = `${diffDays > 0 ? diffDays + 'd ' : ''} ${diffHrs > 0 ? diffHrs + 'hr ' : ''} ${diffMins > 0 ? diffMins + 'min' : ''}`;
    return result;
  }

  public sendBullets = (data: any[]) => {
    this.bullets.emit(data);
  }
}
