<div class="container">
  <h1>Machines & Equipment</h1>
  <div class="filters">
    <button mat-icon-button (click)="showCreate()" style="margin-right: 13px;"><mat-icon>add</mat-icon></button>
    <form (submit)="getData()">
      <mat-form-field style="width: 24em;">
        <mat-label>Filter</mat-label>
        <input matInput [(ngModel)]="filter" id="focus-me"/>
      </mat-form-field>
      <button mat-button type="submit" (click)="getData()">Search</button>
    </form>
  </div>
  <div class="list-machine" *ngIf="!loading">
    <div class="list-machine-item" *ngFor="let machine of machineList">

      <!-- <button mat-icon-button class="fixed-alert"><mat-icon>notifications</mat-icon></button> -->

      <p><strong>HF</strong>: </p>
      <p>{{ machine.HF }}</p>
      

      <p><strong>Total use hours</strong>: {{ machine.useHours | number }} Hr</p>
      <p><strong>Total reparations & maintanances</strong>: {{ machine.reports.length | number }}</p>
      
      <div class="actions">
        
        <button mat-button class="main-btn green-border" [disabled]="machine.activeReports === 0" (click)="showReports(machine.HF, machine.reports, 0)">
          <div class="btn-info">
            <p>Active reports:</p>
            <p><strong>{{ machine.activeReports }}</strong></p>
          </div>
        </button>
        <button mat-button class="main-btn red-border" [disabled]="machine.correctiveReports === 0" (click)="showReports(machine.HF, machine.reports, 1)">
          <div class="btn-info">

            <p>Corrective reports:</p>
            <p><strong>{{ machine.correctiveReports }}</strong></p>
          </div>
        </button>
        <button mat-button class="main-btn blue-border" [disabled]="machine.preventiveReports === 0" (click)="showReports(machine.HF, machine.reports, 2)">
          <div class="btn-info">

            <p>Preventive reports:</p>
            <p><strong>{{ machine.preventiveReports }}</strong></p>
          </div>
        </button>
        <button mat-button class="main-btn orange-border" [disabled]="machine.predictiveReports === 0" (click)="showReports(machine.HF, machine.reports, 3)">
          <div class="btn-info">

            <p>Predictible reports:</p>
            <p><strong>{{ machine.predictiveReports }}</strong></p>
          </div>
        </button>
      </div>
      <p><strong>Description</strong>: </p>
      <p>{{ machine.Description.trim().length  !== 0 ? machine.Description : 'Not registered' }}</p>

      <p><strong>Manufacturer</strong>: </p>
      <p>{{ machine.Manufacturer.trim().length !== 0 ? machine.Manufacturer : 'Not registered'  }}</p>

      <p><strong>License Plate</strong>: </p>
      <p>{{ machine.LicensePlate.trim().length  !== 0 ? machine.LicensePlate : 'Not registered' }}</p>
      <div style="display: flex; width: 100%;">
        <button mat-button style="width: 80%; margin-top: 7px; border-left: 4px solid rgba(0, 0, 0, 0.58);" (click)="showDetail(machine)">Edit information</button>
        <button mat-button color="accent" style="width: 10%; color: rgb(223, 120, 60);" (click)="deleteMachine(machine.Id, true)"><mat-icon>hide_source</mat-icon></button>
        <button mat-button color="warning" style="width: 10%; color: rgb(223, 60, 60);" (click)="deleteMachine(machine.Id, false)"><mat-icon>delete</mat-icon></button>
      </div>
    </div>
    <div class="list-detail" *ngIf="showDetails">
      <app-machines-reports-v2 [hf]="hf" [reports]="filteredReports" (bullets)="showBullets($event)"></app-machines-reports-v2>
    </div>
    <div class="list-detail-bullets" *ngIf="showDetailBullets">
      <mat-card>
        <mat-card-title>Actions</mat-card-title>
        <mat-card-content>
          <mat-list>
            <mat-list-item *ngFor="let bullet of bulletsList">
              {{bullet}}
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>