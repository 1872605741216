export class Grower {
    public Id: number = 0;
    public Name: string = '';
    public ContactName: string = '';
    public CellNumber: string = '';
    public PhoneNumber: string = '';
    public FaxNumber: string = '';
    public Email: string = '';
    public Adress: string = '';
    public Location: string = '';
    public City: string = '';
    public County: string = '';
    public State: string = '';
    public Country: string = '';
    public ZipCode: string = '';
    public Size: number = 0;
    public Status: number = 1;
}