import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {

  public formRoutes = [
    {title: 'Nouca Log', route: 'nouca', icon: 'grading'},
    {title: 'Pre-Season', route: 'pre', icon: 'eco'},
    {title: 'Pre-Harvest', route: 'preh', icon: 'grass'}
  ];

  constructor(private router:Router, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  public goTo = (r) => {
    this.router.navigate([r.route], { relativeTo: this.activeRoute });
  }

}
