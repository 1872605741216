import { Injectable } from '@angular/core';
import { ts, ActiveUser } from '../../app.shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import User from '../../models/user.model';
import { Ranch } from './ranches.model';

@Injectable({
  providedIn: 'root'
})
export class RanchesService {

  private uri:string = environment.URI;
  private _s: string;
  private _us: User;
  
  constructor(private http:HttpClient, private s:ts, private activeUser:ActiveUser) {
    s.getValue().subscribe((value)=> {
      this._s = value;
    });
    activeUser.getValue().subscribe((value)=> {
        this._us = value;
    })
  }

  public Get = (growerId: number):Promise<Array<Ranch>> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.get<Array<Ranch>>(`${this.uri}/api/ranch?growerId=${growerId}`, {headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public Create = (ranch:Ranch):Promise<Ranch> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.post<Ranch>(`${this.uri}/api/ranch`, ranch,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public Edit = (ranch:Ranch):Promise<Ranch> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.put<Ranch>(`${this.uri}/api/ranch`, ranch,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public Delete = (ranchId: number, growerId: number):Promise<Ranch> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.delete<Ranch>(`${this.uri}/api/ranch?ranchId=${ranchId}&growerId=${growerId}`, {headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
}
