<div class="file-manager-main">
    <h1 class="title">File manager</h1>
    <div class="container">
        <div class="toolbar">
            <button mat-button (click)="addFolder()" [disabled]="disableAddFolder">Add folder</button>
            <button mat-button (click)="addSubFolder()" [disabled]="disableAddSubFolder">Add sub folder</button>
            <button mat-button (click)="addFile()" [disabled]="disableAddFile">Add file</button>
            <div class="back-icon" *ngIf=" path != '' " (click)="redirectBack()">
              <span class="material-icons">reply</span>
            </div>

            <p>{{ path }}</p>
        </div>
        <div class="manager-main">
            <div class="manager">
                <mat-list>
                    <mat-list-item *ngFor="let folder of folders">
                        <button mat-icon-button (click)="editFolder(folder)" matTooltip="Edit folder">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="selectFolder(folder)" matTooltip="{{folder.Name}}" *ngIf="folder.Name.length > 27">
                            <span class="material-icons">{{ folderSelected.Id == folder.Id ? 'folder_open' : 'folder' }}</span>
                            {{shortName(folder.Name)}}
                        </button>
                        <button mat-button (click)="selectFolder(folder)" *ngIf="folder.Name.length < 27">
                            <span class="material-icons">{{ folderSelected.Id == folder.Id ? 'folder_open' : 'folder' }}</span>
                            {{folder.Name}}
                        </button>
                    </mat-list-item>
                </mat-list>
            </div>
            <div class="content">
                <div class="element" *ngFor="let file of files">
                    <span class="select">
                        <mat-checkbox (change)="updateStatus(file)" [(ngModel)]="file.Status"></mat-checkbox>
                    </span>
                    <span class="close">
                        <button mat-icon-button>
                            <span class="material-icons" (click)="deleteFile(file)">close</span>
                        </button>
                    </span>
                    <button mat-button class="file-btn" (click)="managerAction(file)">
                        <mat-card>
                            <span class="material-icons">{{ file.IdParentFolder ? 'folder' : 'insert_drive_file' }}</span>
                            <p>{{ file.Name }}</p>
                        </mat-card>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
