import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { NoucaModalAddComponent } from './nouca-modal-add/nouca-modal-add.component';
import { NoucaService } from './nouca.service';
import Nouca from './nouca.model';
import DataTable from 'src/app/components/table/table.model';
import { GrowersService } from '../../growers/growers.service';
import { RanchesService } from '../../ranches/ranches.service';
import { NoucaModalEditComponent } from './nouca-modal-edit/nouca-modal-edit.component';
import { NoucaModalDeleteComponent } from './nouca-modal-delete/nouca-modal-delete.component';

@Component({
  selector: 'app-nouca',
  templateUrl: './nouca.component.html',
  styleUrls: ['./nouca.component.scss']
})
export class NoucaComponent implements OnInit {
  public noucas:Array<Nouca> = [];
  public dataTable: DataTable = new DataTable();

  constructor(
    private router:Router,
    public dialog: MatDialog,
    private noucaService: NoucaService,
    private growerService: GrowersService,
    private ranchService: RanchesService
  ) { }

  ngOnInit(): void {
    this.fillTable();
    this.displayedColumns = ['Actions'];
    this.dataSource = new MatTableDataSource([]);
    this.configTable();
  }
  private configTable = () => {
    this.dataTable.Headers.push('Description');
    this.dataTable.Headers.push('Date');
    this.dataTable.Headers.push('Time');
    this.dataTable.Headers.push('Supervisor');
    this.dataTable.Headers.push('ReportedBy');
    this.dataTable.Headers.push('Actions');
  }

  public back = () => {
    this.router.navigate(['forms']);
  }
  public fillTable = async() =>{
    let result = await this.noucaService.Get();
    this.noucas = result;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: Array<string>;
  public dataSource: MatTableDataSource<any>;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public checkData = (data, header) => {
    if(header == 'Actions')
      return '';

    if(data == null)
      return 'Not captured';

    if(typeof data == 'string'){
      if(data.trim() == '' || data == 'null' || data == 'Null')
        return 'Not captured'
    }

    if(header == 'Status') {
      if(data)
        return 'Active';
      else
        return 'Inactive';
    }

    return data;
  }

  public update = async(event:{data:any, index:number}) => {

    const growers = await this.growerService.Get();
    const ranch = await this.ranchService.Get(event.data.GrowerId);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {event:event.data, growers:growers, ranch:ranch};

    let dialogRef = this.dialog.open(NoucaModalEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async result =>{
      console.log(result);
      if(result != null){
        let newArray:Array<Nouca> = this.noucas;
        newArray.map(nouca => {
          if(nouca.Id === result.Id){
            nouca.GrowerId = result.GrowerId;
            nouca.RanchId = result.RanchId;
            nouca.Description = result.Description;
            nouca.Actions = result.Actions;
            nouca.Date = result.Date;
            nouca.ReportedBy = result.ReportedBy;
            nouca.Supervisor = result.Supervisor;
            nouca.Time = result.Time;
          }
        });
        this.noucas = newArray;
      }
    });
  }

  public delete = (event: {dataId: number, index: number}) => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {noucaId: event.dataId};

    let dialogRef = this.dialog.open(NoucaModalDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.noucas.filter((x, i)=> i != event.index);
        this.noucas = newArray;
      }
    });
  }

  public modalAdd = async() => {

    const growers = await this.growerService.Get();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {growers: growers};
    // dialogConfig.height = "90%";

    let dialogRef = this.dialog.open(NoucaModalAddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      console.log(result);
      if(result != null && !result.error){
        let newArray = this.noucas.concat(result);
        this.noucas = newArray;
      }
    });
  }
}
