import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { MachinesAndEquipment } from '../../../models/machinesAndEquipment.model';
import { MachinesAndEquipmentService } from '../machines-component.service';
import { ErrorStateMatcher } from '@angular/material/core';



export class GrowerAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-machine-modal-add',
  templateUrl: './machine-modal-add.component.html',
  styleUrls: ['./machine-modal-add.component.scss']
})
export class MachineModalAddComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<MachineModalAddComponent>, private service: MachinesAndEquipmentService) { }

  ngOnInit(): void {
  }

  public form: FormGroup = new FormGroup({
    id: new FormControl(0),
    description: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    manufacturer: new FormControl('', Validators.required),
    hf: new FormControl('', Validators.required),
    plate: new FormControl('', [Validators.maxLength(50), Validators.minLength(3)]),
    date: new FormControl('', [Validators.required]),
  });

  //Function Para cerrar y crear modal
  public cancelModal = (result: any) => {
    this.dialogRef.close(result);
  }

  public createMachinesAndEquipment = async() => {
    let machines = new MachinesAndEquipment();
    
    machines.Id = this.form.controls.id.value;   
    machines.Description = this.form.controls.description.value;   
    machines.Manufacturer = this.form.controls.manufacturer.value;
    machines.HF = this.form.controls.hf.value;
    machines.LicensePlate = this.form.controls.plate.value;
    machines.Date = this.form.controls.date.value;

    let result: any = await this.service.Create(machines);
    if(result.error){
      return;
    }
    this.cancelModal(result);

  }

}
