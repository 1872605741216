import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveUser, ts } from 'src/app/app.shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomReportService {

  private uri: string = environment.URI;
  private _s;
  private _us;

  constructor(private http: HttpClient, private s:ts, private activeUser:ActiveUser) {
    s.getValue().subscribe((value)=> {
      this._s = value;
    });
    activeUser.getValue().subscribe((value)=> {
        this._us = value;
    })
  }

  public getData = (): Promise<any> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.get<any>(`${this.uri}/api/custom-reports`, {headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public createData = (report: any): Promise<any> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.post<any>(`${this.uri}/api/custom-reports`, report,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
  
  public editData = (report: any): Promise<any> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.put<any>(`${this.uri}/api/custom-reports`, report,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
  
  public deleteData = (report_id: any, soft: boolean): Promise<any> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.delete<any>(`${this.uri}/api/custom-reports?report_id=${report_id}&soft=${soft}`,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
}
