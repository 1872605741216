import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Grower } from '../../../growers/growers.model';
import { GrowerEditErrorStateMatcher } from 'src/app/views/growers/grower-modal-edit/grower-modal-edit.component';
import { Ranch } from '../../../ranches/ranches.model';
import { NoucaComponent } from '../nouca.component';
import { NoucaService } from '../nouca.service';
import { RanchesService } from 'src/app/views/ranches/ranches.service';
import Nouca from '../nouca.model';

@Component({
  selector: 'app-nouca-modal-add',
  templateUrl: './nouca-modal-add.component.html',
  styleUrls: ['./nouca-modal-add.component.scss']
})
export class NoucaModalAddComponent implements OnInit {

  public growers: Array<Grower> = [];
  public ranches: Array<Ranch> = [];
  public ranchesFiltered: Array<Ranch> = [];
  public growerId:number;
  public hours:Array<number> = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
  public minutes:Array<number> = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,];

  constructor(public dialogRef: MatDialogRef<NoucaComponent>, @Inject(MAT_DIALOG_DATA) public data:{growers:any}, private service:NoucaService, private ranchService:RanchesService) { }

  async ngOnInit(){
    this.growers = this.data.growers;

    console.log(this.growers);
    console.log(this.data.growers);
  }

  public form:FormGroup = new FormGroup({
    growerId: new FormControl(0, Validators.required),
    ranchId: new FormControl(0, Validators.required),
    date: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    corrective: new FormControl('',Validators.required),
    reportedBy: new FormControl('', Validators.required),
    supervisor:  new FormControl('', Validators.required),
    hours: new FormControl(0, Validators.required),
    minutes: new FormControl(0, Validators.required),

  });

  public growerChange = async(growerId: number) => {
    this.ranches = await this.ranchService.Get(growerId);
    this.ranchesFiltered = await this.ranches.filter(x => x.IdGrower === growerId).sort((a,b)=> a<b ?-1: 1);
  }

  public cancelModal = (data: any = null) => {
    this.dialogRef.close(data);
  }

  public create = async() =>{
    let newNouca = new Nouca();
    newNouca.Description = this.form.controls.description.value;
    newNouca.Actions = this.form.controls.corrective.value;
    newNouca.GrowerId = this.form.controls.growerId.value;
    newNouca.RanchId = this.form.controls.ranchId.value;
    newNouca.Date = this.form.controls.date.value;
    let time = `${this.form.controls.hours.value}:${this.form.controls.minutes.value}`
    newNouca.Time = time;
    newNouca.Supervisor = this.form.controls.supervisor.value;
    newNouca.ReportedBy = this.form.controls.reportedBy.value;

    console.log(newNouca);
    try{
      let result = await this.service.Create(newNouca);
      this.cancelModal(newNouca);
    }catch(err){
      console.log(err);
    }
  }

}
