import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import PreSeason from '../../pre-season/pre-season.model';
import { PreSeasonService } from '../../pre-season/pre-season.service';

@Component({
  selector: 'app-pre-harvest-modal-edit',
  templateUrl: './pre-harvest-modal-edit.component.html',
  styleUrls: ['./pre-harvest-modal-edit.component.scss']
})
export class PreHarvestModalEditComponent implements OnInit {

  constructor(private service:PreSeasonService,public dialogRef: MatDialogRef<PreHarvestModalEditComponent> , @Inject(MAT_DIALOG_DATA) public data:{data:PreSeason}) { }

  ngOnInit(): void {
    console.log(this.data);
    this.form.controls.ReviewedBy.setValue(this.data.data.ReviewedBy);
    this.form.controls.ReviewedDate.setValue(this.data.data.ReviewedDate);
  }
  public form = new FormGroup({
    ReviewedBy: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    ReviewedDate: new FormControl('', Validators.required)
  });

  public cancelModal = (result: any = null) => {
    this.dialogRef.close(result);
  }
  public update = async() => {
    let preSeason = new PreSeason();
    preSeason.Id = this.data.data.Id;
    preSeason.ReviewedBy = this.form.controls.ReviewedBy.value;
    preSeason.ReviewedDate = this.form.controls.ReviewedDate.value;

    try{
      let result:any = await this.service.Edit(preSeason);
      this.cancelModal(preSeason);
    }catch(err){
      console.log(err);
    }

  }


}
