import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import DataTable from '../../../components/table/table.model';
import { MachineAndEquipment, MachineAndEquipmentTypes } from '../report.model';
import { ReportsV2Service } from '../reports-v2.service';
import { MachineModalDetailV2Component } from './machine-modal-detail-v2/machine-modal-detail-v2.component';
import { History_Report } from './machineReports.model';
import * as moment from 'moment';

@Component({
  selector: 'app-machine-v2',
  templateUrl: './machine-v2.component.html',
  styleUrls: ['./machine-v2.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MachineV2Component implements OnInit {

  public dataTable = new DataTable();
  public machineReports: Array<History_Report> = [];

  public Machine_HF: string = null;
  public Type_Id: number = null;

  public machinesAndEquipments: Array<MachineAndEquipment> = [];
  public types: Array<MachineAndEquipmentTypes> = [];

  constructor(
    private router:Router,
    private reportService: ReportsV2Service,
    private dialog: MatDialog
  ) { }

  async ngOnInit() {
    await this.getCatalogues();
    this.configTable();
  }
  

  public back = () => {
    this.router.navigate(['reports-v2']);
  }

  public detail = (event: {data: History_Report}) => {
    this.modalDetail(event.data);
  }
  
  public getData = async() => {
    let filters = {
        Machine_HF: this.Machine_HF,
        Type_Id: this.Type_Id
    }
    this.machineReports = await this.reportService.GetMachineAndEquipmentReports(filters);
  }

  public delete = (event: any) => {
  }

  public modalDetail = (data: History_Report) => {
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    dialogConfig.height = "84vh";
    dialogConfig.data = {form: data};

    let dialogRef = this.dialog.open(MachineModalDetailV2Component, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
    });
  }

  private configTable = () => {
    this.dataTable.Headers.push('HF');
    this.dataTable.Headers.push('Description');
    this.dataTable.Headers.push('Reported_By');
    this.dataTable.Headers.push('Assigned_Mechanic');
    this.dataTable.Headers.push('Type');
    this.dataTable.Headers.push('Date_Create');
    this.dataTable.Headers.push('Date_Finish');
    this.dataTable.Headers.push('Actions');
  }

  private getCatalogues = async() => {
    this.machinesAndEquipments = await this.reportService.GetMachinesAndEquipment();
    console.log(this.machinesAndEquipments);
    this.types = await this.reportService.GetMachinesAndEquipmentTypes();
  }
}
