import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Grower } from '../../../models/grower.model';
import { Ranch } from '../../../views/ranches/ranches.model';
import { FilesService } from '../files.service';
import User from '../../../models/user.model';
import Folder from '../models/folder.model';
import { UserService } from '../../../views/users/user.service';

export class UserAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-folder-edit-modal',
  templateUrl: './folder-edit-modal.component.html',
  styleUrls: ['./folder-edit-modal.component.scss']
})
export class FolderEditModalComponent implements OnInit {

  public growers: Array<Grower> = [];
  public ranches: Array<Ranch> = [];
  public users: Array<User> = [];

  public form: FormGroup = new FormGroup({
    Name: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]),
    IdGrower: new FormControl(null),
    IdRanch: new FormControl(null),
    Guests: new FormControl(null)
  });

  constructor(
    public dialogRef: MatDialogRef<FolderEditModalComponent>,
    private service: FilesService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: { folder: Folder, growers: Array<Grower>, users: Array<User>},
    public dialog: MatDialog) {}

  ngOnInit() {
    this.Init();
  }

  private Init = async () => {
    this.growers = this.data.growers;
    this.users = this.data.users;
    if(this.data.folder.IdGrower != null && this.data.folder.IdGrower != 0){
      await this.growerChange();
    }
    this.form.controls.Name.setValue(this.data.folder.Name);
    this.form.controls.IdGrower.setValue(this.data.folder.IdGrower);
    this.form.controls.IdRanch.setValue(this.data.folder.IdRanch);
    this.form.controls.Guests.setValue(JSON.parse(this.data.folder.PermissionTo));
  }
  
  public growerChange = async () => {
    if(this.form.controls.IdGrower.value) {
      this.ranches = await this.service.GetRanches(this.form.controls.IdGrower.value);
    } else {
      this.form.controls.IdRanch.setValue(null);
    }
  }
  
  public cancelModal = (data: any = null) => {
    this.dialogRef.close(data);
  }

  public onDelete = async() =>{
    this.cancelModal({folder: this.data.folder, type:'delete'});
  }

  public onEdit = async() =>{
    let folder = new Folder();
    folder.Id = this.data.folder.Id;
    folder.Name = this.form.controls.Name.value;
    folder.IdGrower = this.form.controls.IdGrower.value;
    folder.IdRanch = this.form.controls.IdRanch.value;
    folder.PermissionTo = JSON.stringify(this.form.controls.Guests.value);
    folder.Status = this.data.folder.Status;
    let result: any = await this.service.UpdateFolders(folder);
    if(result.error){
      return;
    }
    this.cancelModal(result);
  }
}
