<div class="container">
  <mat-card>
      <div class="title">
          <button mat-icon-button (click)="back()">
          <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <h1>Pre-Harvest</h1>
      </div>
      <div>
          <button mat-raised-button (click)="modalAdd()">Create Report</button>
      </div>
      <app-table [data]="preHarvest" [dataTable]="dataTable" (updateItem)="update($event)" (deleteItem)="delete($event)"></app-table>
  </mat-card>
</div>
