import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MaterialModule} from './components/material/material.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { TableComponent } from './components/table/table.component';
import { HomeComponent } from './views/home/home.component';
import { UsersComponent } from './views/users/users.component';
import { GrowersComponent } from './views/growers/growers.component';
import { RanchesComponent } from './views/ranches/ranches.component';
import { ReportsComponent } from './views/reports/reports.component';

import { ToolbarComponent } from './components/layout/toolbar/toolbar.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { FootbarComponent } from './components/layout/footbar/footbar.component';
import { DailyComponent } from './views/reports/daily/daily.component';
import { WeeklyComponent } from './views/reports/weekly/weekly.component';
import { FormsComponent } from './views/forms/forms.component';
import { FileManagerComponent } from './views/file-manager/file-manager.component';
import { ManagerComponent } from './components/manager/manager.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './components/loader/loader.service';
import { NoucaComponent } from './views/forms/nouca/nouca.component';
import { PreSeasonComponent } from './views/forms/pre-season/pre-season.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoaderInterceptor } from './components/loader/loader.interceptor';
import { UserService } from './views/users/user.service';
import { TableDialogComponent } from './components/table-dialog/table-dialog.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { UserModalAddComponent } from './views/users/user-modal-add/user-modal-add.component';
import { UserModalEditComponent } from './views/users/user-modal-edit/user-modal-edit.component';
import { UserModalDeleteComponent } from './views/users/user-modal-delete/user-modal-delete.component';
import { GrowerModalAddComponent } from './views/growers/grower-modal-add/grower-modal-add.component';
import { GrowerModalEditComponent } from './views/growers/grower-modal-edit/grower-modal-edit.component';
import { GrowerModalDeleteComponent } from './views/growers/grower-modal-delete/grower-modal-delete.component';
import { RanchesModalAddComponent } from './views/ranches/ranches-modal-add/ranches-modal-add.component';
import { RanchesModalEditComponent } from './views/ranches/ranches-modal-edit/ranches-modal-edit.component';
import { RanchesModalDeleteComponent } from './views/ranches/ranches-modal-delete/ranches-modal-delete.component';
import { MachineComponent } from './views/reports/machine/machine.component';
import { PreSeasonModalAddComponent } from './views/forms/pre-season/pre-season-modal-add/pre-season-modal-add.component';
import { NoucaModalAddComponent } from './views/forms/nouca/nouca-modal-add/nouca-modal-add.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DailyModalDetailComponent } from './views/reports/daily/daily-modal-detail/daily-modal-detail.component';
import { MachinesComponentComponent } from './views/machines-component/machines-component.component';
import { MachineModalAddComponent } from './views/machines-component/machine-modal-add/machine-modal-add.component';
import { MachinesAndEquipmentService } from './views/machines-component/machines-component.service';
import { MachinesModalEditComponent } from './views/machines-component/machine-modal-edit/machines-modal-edit.component';
import { MachineModalDeleteComponent } from './views/machines-component/machine-modal-delete/machine-modal-delete.component';
import { ReportsV2Component } from './views/reports-v2/reports-v2.component';
import { MachineV2Component } from './views/reports-v2/machine-v2/machine-v2.component';
import { DailyV2Component } from './views/reports-v2/daily-v2/daily-v2.component';
import { WeeklyV2Component } from './views/reports-v2/weekly-v2/weekly-v2.component';
import { ReportsV2Service } from './views/reports-v2/reports-v2.service';
import { DailyModalDetailV2Component } from './views/reports-v2/daily-v2/daily-modal-detail-v2/daily-modal-detail-v2.component';
import { MachineModalDetailV2Component } from './views/reports-v2/machine-v2/machine-modal-detail-v2/machine-modal-detail-v2.component';
import { FilesComponent } from './components/files/files.component';
import { FolderAddModalComponent } from './components/files/folder-add-modal/folder-add-modal.component';
import { SubFolderAddModalComponent } from './components/files/sub-folder-add-modal/sub-folder-add-modal.component';
import { FileAddModalComponent } from './components/files/file-add-modal/file-add-modal.component';
import { FilesService } from './components/files/files.service';
import { FolderEditModalComponent } from './components/files/folder-edit-modal/folder-edit-modal.component';
import { FolderDeleteModalComponent } from './components/files/folder-delete-modal/folder-delete-modal.component';
import { FileDeleteModalComponent } from './components/files/file-delete-modal/file-delete-modal.component';
import { TableV2Component } from './components/table-v2/table-v2.component';
import { OnlyAdmins, OnlyAppUsers, OnlyAuditor, OnlyGuests } from './guard.app';
import { FilesGuestsService } from './components/files-guests/files-guests.service';
import { FilesGuestsComponent } from './components/files-guests/files-guests.component';
import { NoucaModalDeleteComponent } from './views/forms/nouca/nouca-modal-delete/nouca-modal-delete.component';
import { NoucaModalEditComponent } from './views/forms/nouca/nouca-modal-edit/nouca-modal-edit.component';
import { PreSeasonModalDeleteComponent } from './views/forms/pre-season/pre-season-modal-delete/pre-season-modal-delete.component';
import { PreHarvestComponent } from './views/forms/pre-harvest/pre-harvest.component';
import { PreSeasonModalEditComponent } from './views/forms/pre-season/pre-season-modal-edit/pre-season-modal-edit.component';
import { PreHarvestModalAddComponent } from './views/forms/pre-harvest/pre-harvest-modal-add/pre-harvest-modal-add.component';
import { PreHarvestModalEditComponent } from './views/forms/pre-harvest/pre-harvest-modal-edit/pre-harvest-modal-edit.component';
import { PreHarvestModalDeleteComponent } from './views/forms/pre-harvest/pre-harvest-modal-delete/pre-harvest-modal-delete.component';
import { WaterUsageComponent } from './views/reports-v2/water-usage/water-usage.component';
import { WaterUsateDetailComponent } from './views/reports-v2/water-usage/water-usate-detail/water-usate-detail.component';
import { MachinesV2Component } from './views/machines-v2/machines-v2.component';
import { MachinesReportsV2Component } from './views/machines-v2/machines-reports-v2/machines-reports-v2.component';
import { CreateMachineV2Component } from './views/machines-v2/create-machine-v2/create-machine-v2.component';
import { DeleteMachineV2Component } from './views/machines-v2/delete-machine-v2/delete-machine-v2.component';
import { TooltipListPipe } from './pipes/tooltip-list';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomReportsComponent } from './views/custom-reports/custom-reports.component';
import { CreateCustomReportComponent } from './views/custom-reports/create-custom-report/create-custom-report.component';
import { DeleteCustomReportComponent } from './views/custom-reports/delete-custom-report/delete-custom-report.component';
import { ContractorsComponent } from './views/contractors/contractors.component';
import { AddContratorComponent } from './views/contractors/add-contrator/add-contrator.component';
import { EditContratorComponent } from './views/contractors/edit-contrator/edit-contrator.component';
import { DeleteContratorComponent } from './views/contractors/delete-contrator/delete-contrator.component'

export const DateFormats = {
    parse: {
        dateInput: ['YYYY-MM-DD']
    },
    display: {
        dateInput: 'MM/DD/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    TableComponent,
    HomeComponent,
    UsersComponent,
    GrowersComponent,
    RanchesComponent,
    ReportsComponent,
    BreadcrumbComponent,
    ToolbarComponent,
    SidebarComponent,
    FootbarComponent,
    DailyComponent,
    WeeklyComponent,
    FormsComponent,
    FileManagerComponent,
    ManagerComponent,
    LoaderComponent,
    NoucaComponent,
    PreSeasonComponent,
    TableDialogComponent,
    UserModalAddComponent,
    UserModalEditComponent,
    UserModalDeleteComponent,
    GrowerModalAddComponent,
    GrowerModalEditComponent,
    GrowerModalDeleteComponent,
    MachineComponent,
    RanchesModalAddComponent,
    RanchesModalEditComponent,
    RanchesModalDeleteComponent,
    PreSeasonModalAddComponent,
    NoucaModalAddComponent,
    DailyModalDetailComponent,
    MachinesComponentComponent,
    MachineModalAddComponent,
    MachinesModalEditComponent,
    MachineModalDeleteComponent,
    ReportsV2Component,
    MachineV2Component,
    DailyV2Component,
    WeeklyV2Component,
    DailyModalDetailV2Component,
    MachineModalDetailV2Component,
    FilesComponent,
    FolderAddModalComponent,
    SubFolderAddModalComponent,
    FileAddModalComponent,
    FolderEditModalComponent,
    FolderDeleteModalComponent,
    FileDeleteModalComponent,
    TableV2Component,
    FilesGuestsComponent,
    NoucaModalDeleteComponent,
    NoucaModalEditComponent,
    PreSeasonModalDeleteComponent,
    PreHarvestComponent,
    PreSeasonModalEditComponent,
    PreHarvestModalAddComponent,
    PreHarvestModalEditComponent,
    PreHarvestModalDeleteComponent,
    WaterUsageComponent,
    WaterUsateDetailComponent,
    MachinesV2Component,
    MachinesReportsV2Component,
    CreateMachineV2Component,
    DeleteMachineV2Component,
    TooltipListPipe,
    CustomReportsComponent,
    CreateCustomReportComponent,
    DeleteCustomReportComponent,
    ContractorsComponent,
    AddContratorComponent,
    EditContratorComponent,
    DeleteContratorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MomentDateModule,
    NgxPaginationModule
  ],
  providers: [
    LoaderService,
    UserService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateFormats },
    MachinesAndEquipmentService,
    ReportsV2Service,
    FilesService,
    FilesGuestsService,
    OnlyAdmins,
    OnlyAppUsers,
    OnlyAuditor,
    OnlyGuests,
    
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule { }
