import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsV2Service } from './reports-v2.service';

@Component({
  selector: 'app-reports-v2',
  templateUrl: './reports-v2.component.html',
  styleUrls: ['./reports-v2.component.scss']
})
export class ReportsV2Component implements OnInit {

  constructor(private reportService: ReportsV2Service, private router:Router, private activeRoute: ActivatedRoute) { }
  public reportRoutes = [
    {
      title: 'Daily Reports',
      ruote: 'daily',
      icon: 'assignment'
    },
    {
      title: 'Weekly Reports',
      ruote: 'weekly',
      icon: 'bookmarks'
    },
    {
      title: 'Water usage',
      ruote: 'waterusage',
      icon: 'invert_colors'
    }
  ];

  public goTo = (r) => {
    this.router.navigate([r.ruote], { relativeTo: this.activeRoute });
  }

  ngOnInit(): void {
    // this.test();
  }

  private test = async() => {
    const filters = {
      Date_Create: null,
      Date_Uploaded: null,
      Grower_Id: null,
      Ranch_Id: null,
      User_Id: null
    }
    let result = this.reportService.GetReports(filters);
  }
}
