<div class="container">
  <mat-toolbar>
      <span class="fill-space"></span>
      <span>Edit Nouca Report</span>
      <span class="fill-space"></span>
      <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  <div class="form-title">
      <h1>Nouca Log</h1>
      <h3>Notice of Unusual Occurrence and Corrective Action</h3>
  </div>
  <mat-divider></mat-divider>
  <form class="form-control" [formGroup]="form">
      <div>
          <mat-form-field>
              <mat-label>Grower*</mat-label>
              <mat-select [(ngModel)]="growerId" (ngModelChange)="growerChange(growerId)" [ngModelOptions]="{standalone: true}">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option *ngFor="let grower of newGrowers" [value]="grower.Id">{{grower.Name}}</mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field>
              <mat-label>Ranch*</mat-label>
              <mat-select [(ngModel)]="ranchId" [ngModelOptions]="{standalone: true}">
                <mat-option [value]="null">None</mat-option>
                <mat-option *ngFor="let ranch of ranchesFiltered" [value]="ranch.Id">
                    {{ranch.Name}}
                </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div class="form-long-row">
          <mat-form-field appearance="fill">
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
              <mat-label>Hour*</mat-label>
              <mat-select  formControlName="hours" placeholder="none">
                  <mat-option [value]="null">None</mat-option>
                  <mat-option [value]="null" *ngFor="let hour of hours" [value]="hour">{{hour}}</mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field>
              <mat-label>Minute*</mat-label>
              <mat-select  formControlName="minutes">
                <mat-option [value]="null">None</mat-option>
                <mat-option [value]="null" *ngFor="let minute of minutes" [value]="minute">{{minute}}</mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <div>
          <mat-form-field class="fila">
              <mat-label>Description of problem or occurrence*</mat-label>
              <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
      </div>
      <div>
          <mat-form-field class="fila">
              <mat-label>Corrective action*</mat-label>
              <input matInput type="text" formControlName="corrective">
          </mat-form-field>
      </div>
      <div>
          <mat-form-field>
              <mat-label>Reported by*</mat-label>
              <input matInput type="text" formControlName="reportedBy">
          </mat-form-field>
          <mat-form-field>
              <mat-label>Supervisor on duty*</mat-label>
              <input matInput type="text" formControlName="supervisor">
          </mat-form-field>
      </div>
  </form>
  <footer class="dialog-footer">
      <div class="button-row">
          <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
          <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="edit()">Edit</button>
      </div>
  </footer>
</div>

