import { Component, OnInit } from '@angular/core';
import File from './models/file.model';
import Folder from './models/folder.model';
import SubFolder from './models/sub-folder.model';
import FolderGuest from './models/folder-guest.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FolderAddModalComponent } from './folder-add-modal/folder-add-modal.component';
import { SubFolderAddModalComponent } from './sub-folder-add-modal/sub-folder-add-modal.component';
import { FileAddModalComponent } from './file-add-modal/file-add-modal.component';
import { FilesService } from './files.service';
import { Ranch } from '../../views/ranches/ranches.model';
import { Grower } from '../../models/grower.model';
import { FolderEditModalComponent } from './folder-edit-modal/folder-edit-modal.component';
import { FolderDeleteModalComponent } from './folder-delete-modal/folder-delete-modal.component';
import { FileDeleteModalComponent } from './file-delete-modal/file-delete-modal.component';
import User from '../../models/user.model';
import { UserService } from '../../views/users/user.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {

  public folders: Array<Folder> = [];
  public folderSelected: Folder = new Folder();
  public subFolderSelected: SubFolder = new SubFolder();
  public files: Array<any> = [];
  public disableAddFolder = false;
  public disableAddSubFolder = true;
  public disableAddFile = true;
  public path = '';
  private growers: Array<Grower> = [];
  private ranches: Array<Ranch> = [];
  public backArray: Array<any>;
  public tempID: any;
  public tempIDFile: any;
  public users: Array<User> = [];

  constructor(public dialog: MatDialog, private service: FilesService, private userService: UserService) { }


  ngOnInit(): void {
    this.Init();
  }

  private Init = async () => {
    this.growers = await this.service.GetGrowers();
    this.folders = await this.service.GetFolders();
    this.users = await this.userService.Get();
  }

  public addFolder = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";
    dialogConfig.data = {
      growers: this.growers
    };
    let dialogRef = this.dialog.open(FolderAddModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result: Folder) => {
      if(result){
        this.folders.push(result);
        const newFolderList = this.folders;
        this.folders = newFolderList;
      }
    });
  }

  public editFolder = (folder: Folder) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";
    dialogConfig.data = {
      folder: folder,
      growers: this.growers,
      users: this.users
    };
    let dialogRef = this.dialog.open(FolderEditModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result: any) => {
      if(!result.type){
        let newArray = this.folders;
        newArray.map(folder => {
          if(folder.Id === result.Id){
            folder.IdGrower = result.IdGrower;
            folder.IdRanch = result.IdRanch;
            folder.Name = result.Name;
            folder.PermissionTo = result.PermissionTo;
            folder.Status = result.Status
          }
        })
        this.folders = newArray;
      }else
        this.deleteFolder(result.folder);
    });
  }

  public deleteFolder = async(folder: Folder) =>{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";
    dialogConfig.data = {
      folderId: folder.Id
    };
    let dialogRef = this.dialog.open(FolderDeleteModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result: Array<Folder>) => {
      if(result != null){
        let newArray = this.folders.filter((x)=> x.Id != folder.Id );
        this.folders = newArray;
        this.folderSelected = new Folder();
        this.subFolderSelected = new SubFolder();
        this.files = [];
      }
    });
  }

  public addSubFolder = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";

    dialogConfig.data = this.subFolderSelected.Id ? this.subFolderSelected : this.folderSelected;

    let dialogRef = this.dialog.open(SubFolderAddModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result: SubFolder) => {
      if(result){
        this.files.push(result);
        const newSubFolderList = this.files;
        this.files = newSubFolderList;
      }
    });
  }

  public addFile = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";

    dialogConfig.data = this.subFolderSelected.Id ? this.subFolderSelected : this.folderSelected;


    let dialogRef = this.dialog.open(FileAddModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result: Array<File>) => {
      if(result && result.length > 0){
        await Promise.all(
          result.map((file) => {
            this.files.push(file);
          })
        );
        const newFileList = this.files;
        this.files = newFileList;
      }
    });
  }

  public deleteFile = (subFolder: any) =>{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";
    dialogConfig.data = {itemId: subFolder};
    let dialogRef = this.dialog.open(FileDeleteModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result: Array<any>) => {
      console.log(result)
      if(result != null){
        let newArray = this.files.filter((x)=> x.Id != subFolder.Id );
        this.files = newArray;
      }
    });
  }

  public selectFolder = async(folder: Folder) => {
    this.folderSelected = folder;
    this.path = `${folder.Name}/`;
    this.tempIDFile = folder.Id;
    this.files = await this.service.GetSubFolders(folder.Id);
    let folderFiles =  await this.service.GetFiles(folder.Id);
    this.backArray = this.files;
    if(folderFiles.length > 0){
      folderFiles.map(file => this.files.push(file));
    }
    this.disableAddFile = false;
    this.disableAddSubFolder = false;
  }

  private selectSubFolder = async(subFolder: SubFolder) => {
    this.path += `${subFolder.Name}/`;
    this.subFolderSelected = subFolder;
    this.tempID = subFolder;
    this.files = await this.service.GetSubFolders(subFolder.Id);
    let folderFiles =  await this.service.GetFiles(subFolder.Id);
    if(folderFiles.length > 0){
      folderFiles.map(file => this.files.push(file));
    }
  }

  private selectFile = async(file: File) => {
    window.open(`${environment.STORAGE}/${file.Name}`, '_blank');
  }

  public managerAction = (fileOrFolder: any) => {
    if(fileOrFolder.IdParentFolder)
      this.selectSubFolder(fileOrFolder);
    else
      this.selectFile(fileOrFolder);
  }

  public shortName = (folderName: string) => {
    if(folderName.length > 27) {
      return `${folderName.substring(0, 27)}...`
    }
  }
  public redirectBack = async() => {
    // console.log(this.backArray);
    let cutPath = this.path.indexOf("/") + 1;
    let finalPath = this.path.substr(0, cutPath);
    this.path = finalPath;
    if(!this.backArray[this.backArray.length - 1].IdParentFolder){
      console.log(this.tempID.Id);
      this.files = await this.service.GetSubFolders(this.tempID.IdParentFolder);
      let folderFiles =  await this.service.GetFiles(this.tempIDFile);
      if(folderFiles.length > 0){
        folderFiles.map(file => this.files.push(file));
      }
    }else{
      this.files = await this.service.GetSubFolders(this.backArray[this.backArray.length - 1].IdParentFolder);
      let folderFiles =  await this.service.GetFiles(this.tempIDFile);
      if(folderFiles.length > 0){
        folderFiles.map(file => this.files.push(file));
      }
      console.log(this.files, this.backArray[this.backArray.length - 1].IdParentFolder);
    }

  }

  public updateStatus = async(file: any) => {
    let update = {
      Id: file.Id,
      Status: file.Status
    };

    if(file.IdParentFolder){
      let result = await this.service.ChangeStatusSubFolder(update);
    }else{
      let result = await this.service.ChangeStatusFile(update);
    }
    file.Status != file.Status;
  }
}
