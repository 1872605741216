<mat-sidenav-container>
    <mat-sidenav mode="over" [disableClose]="false" #sidenav>
      <mat-nav-list>
        <a *ngFor="let nav of menu"
          mat-list-item
          [routerLink]="nav.route"
          (click)="sidenav.close()"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
        >
          <mat-icon matListAvatar>{{nav.icon}}</mat-icon>
          <h3 matLine>{{nav.name}}</h3>
        </a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <!-- <app-breadcrumb [appitems]="menu"></app-breadcrumb> -->
      <router-outlet></router-outlet>
      <div class="footer">
        <app-footbar></app-footbar>
      </div>
    </mat-sidenav-content>
</mat-sidenav-container>