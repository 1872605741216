import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Grower } from '../../../growers/growers.model';
import { Ranch } from '../../../ranches/ranches.model';
import { RanchesService } from '../../../ranches/ranches.service';
import { NoucaComponent } from '../nouca.component';
import Nouca from '../nouca.model';
import { NoucaService } from '../nouca.service';

@Component({
  selector: 'app-nouca-modal-edit',
  templateUrl: './nouca-modal-edit.component.html',
  styleUrls: ['./nouca-modal-edit.component.scss']
})
export class NoucaModalEditComponent implements OnInit  {

  public newGrowers:Array<Grower> = [];
  public ranches: Array<Ranch> = [];
  public ranchesFiltered: Array<Ranch> = [];
  public growerId:number;
  public ranchId:number;
  public hours:Array<string> = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24'];
  public minutes:Array<string> = ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50','51','52','53','54','55','56','57','58','59'];
  public dataHours:number;
  public dataMinutes:number;
  public dataGrowerId:number;

  constructor(private service:NoucaService,private ranchService:RanchesService, public dialogRef: MatDialogRef<NoucaComponent>, @Inject(MAT_DIALOG_DATA)public data:{event:any, growers:Array<Grower>, ranch:any}) { }

  ngOnInit(): void {
    console.log(this.data.event);
    this.growerConversion();
    this.timeConversion();
    this.newGrowers = this.data.growers;
    this.form.controls.corrective.setValue(this.data.event.Actions);
    this.form.controls.date.setValue(this.data.event.Date);
    this.form.controls.description.setValue(this.data.event.Description);
    this.form.controls.reportedBy.setValue(this.data.event.ReportedBy);
    this.form.controls.corrective.setValue(this.data.event.Actions);
    this.form.controls.supervisor.setValue(this.data.event.Supervisor);
    this.form.controls.hours.setValue(this.dataHours);
    this.form.controls.minutes.setValue(this.dataMinutes);
    this.growerId = this.data.event.GrowerId;
    this.ranchesFiltered = this.data.ranch;
    this.ranchId = this.data.event.RanchId;

    console.log(this.data.growers);
  }

  public growerConversion = () =>{
    let dataText:string = this.data.event.GrowerName;
    let growers:Array<Grower> = this.data.growers;
    let growersText:string;
    growers.forEach(grower =>{
      if(grower.Name === dataText){
        this.dataGrowerId = grower.Id;
      }
    })
  }

  public timeConversion = () =>{
    let splitIndex = this.data.event.Time.indexOf(":");
    let minutes = this.data.event.Time.substr(splitIndex + 1);
    let hours = this.data.event.Time.substr(0, splitIndex);
    this.dataHours = hours;
    this.dataMinutes = minutes;
  }

  public form:FormGroup = new FormGroup({
    date: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    corrective: new FormControl('',Validators.required),
    reportedBy: new FormControl('', Validators.required),
    supervisor:  new FormControl('', Validators.required),
    hours: new FormControl(0, Validators.required),
    minutes: new FormControl(0, Validators.required),
  });

  public growerChange = async(growerId: number) => {
    this.ranches = await this.ranchService.Get(growerId);
    this.ranchesFiltered = await this.ranches.filter(x => x.IdGrower === growerId).sort((a,b)=> a<b ?-1: 1);
  }

  public cancelModal = (data:Nouca = null) =>{
    this.dialogRef.close(data);
  }
  public edit = async() => {
    // console.log(this.data.growers);
    // console.log(this.data.event);
    let newNouca = new Nouca();

    newNouca.Id = this.data.event.Id;
    newNouca.Description = this.form.controls.description.value;
    newNouca.Actions = this.form.controls.corrective.value;
    newNouca.GrowerId = this.growerId;
    newNouca.RanchId = this.ranchId;
    newNouca.Date = this.form.controls.date.value;
    let time = `${this.form.controls.hours.value}:${this.form.controls.minutes.value}`
    newNouca.Time = time;
    newNouca.Supervisor = this.form.controls.supervisor.value;
    newNouca.ReportedBy = this.form.controls.reportedBy.value;

    console.log(newNouca);
    try{
      let result = await this.service.Edit(newNouca);
      this.cancelModal(result);
    }catch(err){
      console.log(err);
    }
  }

}
