<div class="container" id="modal">
    <mat-toolbar>
      <span class="fill-space"></span>
      <span>New folder</span>
      <span class="fill-space"></span>
      <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
    
    <form [formGroup]="form">
      <div class="identity-information">
        <h2>Information</h2>
      </div>
      <mat-divider></mat-divider>
  
      <div class="block1">
        <mat-form-field>
          <mat-label>Folder name</mat-label>
          <input matInput type="text" formControlName="Name" placeholder="Folder name*">
          <mat-error *ngIf="form.controls['Name'].errors?.required">Folder name is required</mat-error>
          <mat-error *ngIf="form.controls['Name'].errors?.maxLength">Folder name cannot contain more than 50 letters</mat-error>
          <mat-error *ngIf="form.controls['Name'].errors?.minLength">Folder name cannot contain less than 3 letters</mat-error>
        </mat-form-field>
      </div>
      <div>
        <h2>Relationship</h2>
      </div>
      <mat-divider></mat-divider>
      <div class="block2">
        <mat-form-field>
          <mat-label>Grower</mat-label>
          <mat-select formControlName="IdGrower" (ngModelChange)="growerChange()">
            <mat-option [value]="null">
              None
            </mat-option>
            <mat-option *ngFor="let grower of growers" [value]="grower.Id">
              {{grower.Name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Ranch</mat-label>
          <mat-select formControlName="IdRanch">
              <mat-option [value]="null">
                None
              </mat-option>
              <mat-option *ngFor="let ranch of ranches" [value]="ranch.Id">
                {{ranch.Name}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    
    <footer class="dialog-footer">
      <div class="button-row">
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="onAdd()">Create</button>
        <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
      </div>
    </footer>
  </div>
    
  