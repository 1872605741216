export default class Nouca {
  public Id:number = 0;
  public Description:string = '';
  public Actions:string = '';
  public GrowerId:number = 0;
  public RanchId:number = 0;
  public Date:Date = new Date();
  public Time:string = '';
  public Supervisor:string = '';
  public ReportedBy:string = '';
}
