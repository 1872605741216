import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReportV1Filter } from './reports.model';
import { Grower } from '../growers/growers.model';
import { Ranch } from '../ranches/ranches.model';
import User from '../../models/user.model';
import { environment } from '../../../environments/environment';
import { ActiveUser, ts } from '../../app.shared';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private uri:string = environment.URI;
  private _s;
  private _us;

  constructor(private http: HttpClient, private s:ts, private activeUser:ActiveUser) {
    s.getValue().subscribe((value)=> {
      this._s = value;
    });
    activeUser.getValue().subscribe((value)=> {
        this._us = value;
    })
  }

  public GetV1 = async(filters: ReportV1Filter): Promise<any> => {
    return new Promise((resolve,reject) => {
      this.http.post<any>('https://boggiattoprowebapi.azurewebsites.net/api/getReports', filters).subscribe(
        success => resolve(success),
        error => reject(error)
      )
    });
  }

  public getCataloguesV1 = async(): Promise<{growers: Array<Grower>, ranches: Array<Ranch>, users: Array<User>}> => {
    // 
    return new Promise((resolve,reject) => {
      this.http.post<{growers: Array<Grower>, ranches: Array<Ranch>, users: Array<User>}>('https://boggiattoprowebapi.azurewebsites.net/api/getcataloges', {}).subscribe(
        success => resolve(success),
        error => reject(error)
      )
    });
  }

  public ValidateForm = (validateForm:{formId: number, userId?: number, code: string, comments: string, date?: Date}):Promise<Grower> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);
      let validateForm2 = validateForm;
      // validateForm.userId = this._us.Id;
      // validateForm.date = new Date();
      validateForm2.userId = this._us.Id;
      validateForm2.date = new Date();

      this.http.post<Grower>(`${this.uri}/api/validate`, validateForm2,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
}
