import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter, OnChanges} from '@angular/core';
import DataTable from './table-v2.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';

@Component({
  selector: 'app-table-v2',
  templateUrl: './table-v2.component.html',
  styleUrls: ['./table-v2.component.scss']
})
export class TableV2Component implements OnInit, AfterViewInit, OnChanges {
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() dataTable: DataTable;
  @Input() data: Array<any> = [];
  @Input() isReport?: boolean = false;
  @Input() isMachine?: boolean = false;
  @Output() pageChangeEvent? = new EventEmitter();
  @Input() showDownloadAll?: boolean = false;
  
  public displayedColumns: Array<string>;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public checkedList: Array<any> = [];
  public total: number = 0;

  constructor() {}
  
  ngOnInit(): void {
    this.displayedColumns = this.dataTable.Headers;
    if(this.data.length > 0){
      if(this.data[0].total)
        this.total = this.data[0].total ? this.data[0].total : this.data.length;
      else
        this.total = this.data.length;
    }
    this.dataSource.data = this.data;
  }
  
  public pageChange = (event: any) => {
    console.log(event)
    this.pageChangeEvent.emit(event);
  }

  ngAfterViewInit(): void {

    if(this.data.length > 0){
      if(this.data[0].total)
        this.total = this.data[0].total ? this.data[0].total : this.data.length;
      else
        this.total = this.data.length;
    }
    this.dataSource.sort = this.sort;
  }
  
  ngOnChanges(): void {
    if(this.data.length > 0){
      if(this.data[0].total)
        this.total = this.data[0].total ? this.data[0].total : this.data.length;
      else
        this.total = this.data.length;
    }
    this.dataSource.data = this.data;
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  private dateFormatter = (date: string) => {

  }

  public checkData = (data, header) => {
    if(header == 'Actions')
      return '';

      
      if(data == null)
      return 'Not captured';
      
    if(moment(data, 'YYYY-MM-DDTHH:mm:ss.mmmZ', true).isValid()){
      let dates = moment.utc(data).local().format().split('T');
      return `${dates[0]} ${dates[1].substring(0,5)}`;
    }

    if(header == 'Status') {
      if(data)
        return 'Active';
      else
        return 'Inactive';
    }
    
    if(typeof data == 'string'){
      
      if(data.trim() == '' || data == 'null' || data == 'Null'){
        return 'Not captured'
      }
    }
    

    return data;
  }

  public checkRow = (dataRow: any) => {
    if(dataRow.checked) {
      this.checkedList.push(dataRow);
    }else{
      let newArray = this.checkedList.filter(x => x.Id != dataRow.Id);
      this.checkedList = newArray;
    }
  }

  @Output() updateItem? = new EventEmitter();
  @Output() deleteItem? = new EventEmitter();
  @Output() ranchItem? = new EventEmitter();
  @Output() detailItem? = new EventEmitter();
  @Output() downloadItem? = new EventEmitter();
  @Output() downloadAllItems? = new EventEmitter();

  public update = (selected: any) => {
    let i = this.data.findIndex( x => x.Id == selected.Id);
    this.updateItem.emit({ data: selected, index: i});
  }

  public delete = (selected: any) => {
    let i = this.data.findIndex( x => x.Id == selected.Id);
    this.deleteItem.emit({dataId: selected.Id, index: i, secundaryId: selected.LoginId ? selected.LoginId : null});
  }

  public ranch = (growerId: number) => {
    this.ranchItem.emit({growerId: growerId});
  }

  public detail = (selected: any) => {
    this.detailItem.emit({data: selected});
  }

  public download = (selected: any) => {
    this.downloadItem.emit({data: selected});
  }

  public downloadAll = () => {
    if(this.checkedList.length > 0)
      this.downloadAllItems.emit({data: this.checkedList});
      this.checkedList.map(item => item.checked = false);
  }
}
