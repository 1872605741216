import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilesService } from '../files.service';
import Folder from '../models/folder.model';

@Component({
  selector: 'app-folder-delete-modal',
  templateUrl: './folder-delete-modal.component.html',
  styleUrls: ['./folder-delete-modal.component.scss']
})
export class FolderDeleteModalComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<FolderDeleteModalComponent>, private service: FilesService, @Inject(MAT_DIALOG_DATA) public data: {folderId: string}) { }

  ngOnInit(): void {
  }

  public cancelModal = (data: Array<Folder> = null) => {
    this.dialogRef.close(data);
  }

  public onDelete = async() =>{
    let result:any = await this.service.DeleteFolder(this.data.folderId);
    if(result.error){
      this.cancelModal();
    }
    this.cancelModal(result);
  }

}
