import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReportsV2Service } from '../reports-v2.service';
import * as moment from 'moment';
import { Grower } from '../../growers/growers.model';
import { Ranch } from '../../ranches/ranches.model';
import User from '../../../models/user.model';
import { Img, PdfMakeWrapper, Table, Txt } from 'pdfmake-wrapper';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Chlorine_Inspection, Field_Assessment, Harvest_Machine, Machine_Equipment, Machine_Inspection, Report, Weight_Inspection } from '../report.model';
import { environment } from 'src/environments/environment';

const questionsEn1 = [
    'a- We filled the water supply tanks for the harvest with new water before starting the working day and this water has a suitable chlorine and pH level (650 to 950 mV ORP and 6.5 to 8.0 pH)? - This water will be used to spray product, to fill the trays of the knives (knive dip stations), to wash agricultural machinery, etc.-.',
    'b- Was a general cleaning to agricultural machinery (harvesting machine, water tanks, tractors, etc.) before starting to harvest and verified that there are no traces or residues of soap, chlorine or any chemical agent?',
    'c- Was an inspection carried out to verify that there is no need for screws or nuts to product conveyors, elevators or agricultural machinery?',
    'd- Was an inspection made to the lighting system to verify that the lighting is sufficient and verify that there are no broken lamps?',
    'e- All hydraulic hose motors and connections are equipped with a drip prevention tray, these are fixed and free of evidence of dripping oil or grease?',
    'f- Was an inspection made of the chlorinated water system to all the spray nozzles to verify that they work properly?',
    'g- Was a visual inspection performed before starting work to verify that the agricultural machinery and employees are in compliance with the hygiene rules?',
    'h- Are portable toilets being provided, are they in sanitary conditions and contain the necessary items (toilet paper, hand towels, water, soap, water, etc.)?',
    'i- Was chlorinated or potable water added to the water tank of portable toilets, at least once a week? Mark with a popcorn in the box of the day that the water tank is filled and write it on the corresponding date in the corrective actions section.',
    'j- Are there any employees with symptoms of acute cough, watery eyes, runny nose, fever or symptoms of another contagious disease that could cause product contamination?',
    'k- The work team (gloves, sleeves, apron, knife) are in sanitary conditions before starting work?',
    'l- All employees have the necessary equipment and work material (maya for hair and beard - if necessary - plastic gloves, plastic sleeves, plastic apron)?',
    'm- Are employees not observed with personal items such as jewelry, pins, insurance, pins, false nails, false eyelashes, key rings, radios that are not allowed, etc.?',
    'n- All employees washed their hands with soap and water before starting work.',
    'o- Is the agricultural machinery / harvesting machine free of chemical products without labels?'
];

const questionsEn2 = [
    'p- Packing stations (Packing table)',
    'q- Product conveyor belts.',
    'r- Rollers in product conveyor belts.',
    's- Grease or oil spillage in bearings, engines, hydraulic pistons, etc.',
    't- Trays for prevention of oil dripping in tractors or agricultural machinery.',
    'u- Exterior and interior surface in all water supply tanks.',
    'v- The knives and their trays were cleaned and disinfected?',
    'w- # machine',
    'x- Cleaning and disinfection of the machine completed (initials of the responsible)'
];

const questionsEn3 = [
    'Presure washer function.',
    'Machine motor water level',
    'Machine motor oil level',
    'Machina hydraulic oil level',
    'Greased housing',
    'Check hydraulic pressure mano meter',
    'Verify that all the control board indicators work properly.',
    'Inspect all the control board buttons.',
    'Tire presure (visual inspection)',
    'Conveyor belts adjustment (wings, main and cartons conveyor)',
    'Transfer tension (chain tension)',
    //   'Inspect for any leaks on the machinery (motor, radiator, tanks, hydraulic motors and shocks)',
    //   'Grease transfer housing.',
    //   'Grease all the machina housing.'
];

const questionsEn4 = [
    '1- There is excessive evidence of animal intrusion in the lot that is being harvested such as animal tracks, plants eaten by animals, feces, animal remains or blood.',
    "2- Some source of contamination is found in lands adjacent to the lot where it is harvested, such as manure or compost piles(400 '), livestock operations (CAFOs 400'), grazing areas(30 '), septic tanks (30'), manure composting facilities(400 '), etc.",
    '3- Found materials such as plastics, glass, metal parts (loose screws / nuts) or other debris',
    '4- The field that is going to be harvested suffered a flood in the last 60 days.',
    '5- Unexpected activity is observed in the fields that are adjacent to the batch that is being harvested (livestock grazing, piles of compost, etc.)?',
    '6- The fences on the land adjacent to the harvest lot are not in good condition.',
    "7- An employee is observed not complying with the company's policies.If you find any of the evidences described, mark the number of the incident found during the harvest and write the corrective action taken to solve or minimize the risk of contamination of the product on the line corresponding to the day in which it is being harvested.",
    '8- Inspect portable toilets every two hours or more often. Make sure there is no water in the sink, that this well stocked with soap and toilet paper is clean. Mark the time the inspection is done in the inspection column. bathrooms in the lower right corner of this page.',
    '9- Chlorinated or potable water is required to the water tank of the portable toilets, (at least once a week)'
];

const questionsEn5 = [
    'Chlorine (PPM)',
    'pH',
    'Bathroom inspection',
];

const questionsEn6 = [
    'Jumbos 48s',
    'Jumbos 42s',
    'Jumbos 36s',
    '12 x 3',
    'Mini Bin',
    'Colossal',
    'Petites',
    'Iceberg babies 40s',
    'Iceberg babies 20s',
    '7 x 6'
];

@Component({
    selector: 'app-weekly-v2',
    templateUrl: './weekly-v2.component.html',
    styleUrls: ['./weekly-v2.component.scss']
})
export class WeeklyV2Component implements OnInit {

    public growers: Array<Grower> = [];
    public ranches: Array<Ranch> = [];
    public ranchesFiltered: Array<Ranch> = [];
    public users: Array<User> = [];
    public maxDate: Date = new Date();

    public lang: string = 'en';
    public fromDate: Date = null;
    public toDate: Date = null;
    public growerId?: number = null;
    public ranchId?: number = null;
    public userId?: number = null;

    constructor(
        private router: Router,
        private reportService: ReportsV2Service,
    ) {
        this.getCatalogues();
    }

    ngOnInit(): void {
        PdfMakeWrapper.setFonts(pdfFonts);
    }

    public back = () => {
        this.router.navigate(['reports-v2']);
    }

    public getData = async () => {
        let filters = {
            Start_Date: this.fromDate,
            End_Date: this.toDate,
            Grower_Id: this.growerId,
            Ranch_Id: this.ranchId,
            User_Id: this.userId,
        }
        const result = await this.reportService.GetWeeklyReports(filters);
        if (!result.error) {
            this.downloadPdf(result);
        }
    }

    public getCatalogues = async () => {

        let result: any = await this.reportService.getCatalogues();

        this.growers = result.growers;
        this.ranches = result.ranches;
        this.users = result.users;
    }

    public growerChange = (growerId: number) => {
        this.ranchesFiltered = this.ranches.filter(x => x.IdGrower === growerId).sort((a, b) => a < b ? -1 : 1);
    }

    public formatDate = (date) => {
        let dates = moment.utc(date).local().format().split('T');
        return `${dates[0]} ${dates[1].substring(0, 5)}`;
    }

    public downloadPdf = async (report: any) => {
        const grower = this.growers.find(x => x.Id === this.growerId);
        const ranch = this.ranches.find(x => x.Id === this.ranchId);
        const user = this.users.find(x => x.Id === this.userId);
        console.log(grower)
        console.log(ranch)
        console.log(user)
        const pdf = new PdfMakeWrapper();

        pdf.pageOrientation('landscape');

        pdf.header(new Txt(`Printed ${moment().local().format('MM/DD/YYYY')}`).margin(5).alignment('right').end);
        pdf.add(new Txt('Hitchcock Farms').fontSize(30).bold().alignment('center').end);
        pdf.add(new Txt('Weekly report information').alignment('center').end);

        pdf.add(
            new Table([
                ['Grower', grower.Name.trim()],
                ['Ranch', ranch.Name.trim()],
                ['Foreman', `${user.Name.trim()} ${user.LastName.trim()}`],
                ['Date range', `${moment(this.fromDate, 'YYYY-MM-DD').format('MM/DD/YYYY')} to ${moment(this.toDate, 'YYYY-MM-DD').format('MM/DD/YYYY')}`],
            ]).fontSize(15).alignment('left').widths([100, '*']).margin(30).end
        );

        pdf.add(new Txt(`Daily harvest crew and machine inspection - ${moment(this.toDate, 'YYYY-MM-DD').format('MMMM')}`).bold().pageBreak('before').end);
        pdf.add(
            new Table(
                this.formHarvestBuilder(report.day1[0][0], report.day2[0][0], report.day3[0][0], report.day4[0][0], report.day5[0][0], report.day6[0][0], report.day7[0][0])
            ).fontSize(9).alignment('justify').widths(['*', 40, 40, 40, 40, 40, 40, 40]).end
        )

        pdf.add(new Txt(`Harvest machine and equipment cleaning and sanitation - ${moment(this.toDate, 'YYYY-MM-DD').format('MMMM')}`).bold().pageBreak('before').end);
        pdf.add(
            new Table(
                this.formEquipmentBuilder(report.day1[2][0], report.day2[2][0], report.day3[2][0], report.day4[2][0], report.day5[2][0], report.day6[2][0], report.day7[2][0])
            ).fontSize(9).alignment('justify').widths(['*', 40, 40, 40, 40, 40, 40, 40]).end
        )

        pdf.add(new Txt(`Machine inspection - ${moment(this.toDate, 'YYYY-MM-DD').format('MMMM')}`).bold().pageBreak('before').end);
        pdf.add(
            new Table(
                this.formMachineInspectionBuilder(report.day1[4][0], report.day2[4][0], report.day3[4][0], report.day4[4][0], report.day5[4][0], report.day6[4][0], report.day7[4][0])
            ).fontSize(9).alignment('justify').widths(['*', 40, 40, 40, 40, 40, 40, 40]).end
        )

        pdf.add(new Txt(`Daily harvest field assessment - ${moment(this.toDate, 'YYYY-MM-DD').format('MMMM')}`).bold().pageBreak('before').end);
        pdf.add(
            new Table(
                this.formFieldBuilder(report.day1[6][0], report.day2[6][0], report.day3[6][0], report.day4[6][0], report.day5[6][0], report.day6[6][0], report.day7[6][0])
            ).fontSize(9).alignment('justify').widths(['*', 40, 40, 40, 40, 40, 40, 40]).end
        )

        // if(report.day1[0][8].length > 0) {
        //     pdf.add(new Txt(`Periodic chlorine inspection`).bold().pageBreak('before').end);
        //     pdf.add(
        //         new Table(
        //             this.formChlorineBuilder(report.day1[0][8], report.day2[0][8], report.day3[0][8], report.day4[0][8], report.day5[0][8], report.day6[0][8], report.day7[0][8])
        //         ).fontSize(9).alignment('justify').widths(['*', 40, 40, 40, 40, 40, 40, 40]).end
        //     )
        // }

        // if(report.day1[0][9].length > 0) {
        //     pdf.add(new Txt(`Periodic weight inspection`).bold().pageBreak('before').end);
        //     pdf.add(
        //         new Table(
        //             this.formWeightBuilder(report.day1[0][9], report.day2[0][9], report.day3[0][9], report.day4[0][9], report.day5[0][9], report.day6[0][9], report.day7[0][9])
        //         ).fontSize(9).alignment('justify').widths(['*', 40, 40, 40, 40, 40, 40, 40]).end
        //     )
        // }

        if (grower.Name.includes(".")) {
            let newName = grower.Name;
            newName.replace(/blue/g, "-");
            pdf.create().download(`${newName}-${moment().local().format('MM/DD/YYYY')}`);
        } else {
            pdf.create().download(`${grower.Name}-${moment().local().format('MM/DD/YYYY')}`);
        }
    }

    public toDateChange = () => {
        this.fromDate = new Date(moment(this.toDate).subtract('day', 6).format('MM/DD/YYYY'));
    }

    private formHarvestBuilder = (day1: Harvest_Machine, day2: Harvest_Machine, day3: Harvest_Machine, day4: Harvest_Machine, day5: Harvest_Machine, day6: Harvest_Machine, day7: Harvest_Machine,) => {
        let data = [
            [
                new Txt('Question').bold().end,
                new Txt(this.getDayName(this.toDate, 6)).bold().end,
                new Txt(this.getDayName(this.toDate, 5)).bold().end,
                new Txt(this.getDayName(this.toDate, 4)).bold().end,
                new Txt(this.getDayName(this.toDate, 3)).bold().end,
                new Txt(this.getDayName(this.toDate, 2)).bold().end,
                new Txt(this.getDayName(this.toDate, 1)).bold().end,
                new Txt(this.getDayName(this.toDate, 0)).bold().end,
            ],
        ];
        for(let i = 0; i < questionsEn1.length; i++){
            data.push([
                new Txt(questionsEn1[i]).end,
                new Txt(this.formHarvestAnswer(day7 && day7[`answer${i+1}`] ? day7[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day6 && day6[`answer${i+1}`] ? day6[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day5 && day5[`answer${i+1}`] ? day5[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day4 && day4[`answer${i+1}`] ? day4[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day3 && day3[`answer${i+1}`] ? day3[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day2 && day2[`answer${i+1}`] ? day2[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day1 && day1[`answer${i+1}`] ? day1[`answer${i+1}`] : null)).end,
            ]);
        }
        return data;
    }

    private formHarvestAnswer = (answer: any) => {
        if(answer == 2){
            return 'N/A';
        }else if(answer == 1){
            return 'Done';
        }else if(answer == 0){
            return 'Not Done'
        }else {
            return 'N/A';
        }
    }

    private formEquipmentBuilder = (day1: Machine_Equipment, day2: Machine_Equipment, day3: Machine_Equipment, day4: Machine_Equipment, day5: Machine_Equipment, day6: Machine_Equipment, day7: Machine_Equipment,) => {
        let data = [
            [
                new Txt('Question').bold().end,
                new Txt(this.getDayName(this.toDate, 6)).bold().end,
                new Txt(this.getDayName(this.toDate, 5)).bold().end,
                new Txt(this.getDayName(this.toDate, 4)).bold().end,
                new Txt(this.getDayName(this.toDate, 3)).bold().end,
                new Txt(this.getDayName(this.toDate, 2)).bold().end,
                new Txt(this.getDayName(this.toDate, 1)).bold().end,
                new Txt(this.getDayName(this.toDate, 0)).bold().end,
            ],
        ];
        for(let i = 0; i < questionsEn2.length; i++){
            data.push([
                new Txt(questionsEn2[i]).end,
                new Txt(this.formEquipmentAnswer(day7 && day7[`answer${i+1}`] ? day7[`answer${i+1}`] : null, i)).end,
                new Txt(this.formEquipmentAnswer(day6 && day6[`answer${i+1}`] ? day6[`answer${i+1}`] : null, i)).end,
                new Txt(this.formEquipmentAnswer(day5 && day5[`answer${i+1}`] ? day5[`answer${i+1}`] : null, i)).end,
                new Txt(this.formEquipmentAnswer(day4 && day4[`answer${i+1}`] ? day4[`answer${i+1}`] : null, i)).end,
                new Txt(this.formEquipmentAnswer(day3 && day3[`answer${i+1}`] ? day3[`answer${i+1}`] : null, i)).end,
                new Txt(this.formEquipmentAnswer(day2 && day2[`answer${i+1}`] ? day2[`answer${i+1}`] : null, i)).end,
                new Txt(this.formEquipmentAnswer(day1 && day1[`answer${i+1}`] ? day1[`answer${i+1}`] : null, i)).end,
            ]);
        }
        return data;
    }

    private formEquipmentAnswer = (answer: any, index: number) => {
        if(index == 9){
            return answer;
        }
        if(index == 10) {
            return answer;
        }
        if(answer == 2){
            return 'N/A';
        }else if(answer == 1){
            return 'Done';
        }else if(answer == 0){
            return 'Not Done'
        }else {
            return 'N/A';
        }
    }

    private formMachineInspectionBuilder = (day1: Machine_Inspection, day2: Machine_Inspection, day3: Machine_Inspection, day4: Machine_Inspection, day5: Machine_Inspection, day6: Machine_Inspection, day7: Machine_Inspection,) => {
        let data = [
            [
                new Txt('Question').bold().end,
                new Txt(this.getDayName(this.toDate, 6)).bold().end,
                new Txt(this.getDayName(this.toDate, 5)).bold().end,
                new Txt(this.getDayName(this.toDate, 4)).bold().end,
                new Txt(this.getDayName(this.toDate, 3)).bold().end,
                new Txt(this.getDayName(this.toDate, 2)).bold().end,
                new Txt(this.getDayName(this.toDate, 1)).bold().end,
                new Txt(this.getDayName(this.toDate, 0)).bold().end,
            ],
        ];
        for(let i = 0; i < questionsEn3.length; i++){
            data.push([
                new Txt(questionsEn3[i]).end,
                new Txt(this.formHarvestAnswer(day7 && day7[`answer${i+1}`] ? day7[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day6 && day6[`answer${i+1}`] ? day6[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day5 && day5[`answer${i+1}`] ? day5[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day4 && day4[`answer${i+1}`] ? day4[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day3 && day3[`answer${i+1}`] ? day3[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day2 && day2[`answer${i+1}`] ? day2[`answer${i+1}`] : null)).end,
                new Txt(this.formHarvestAnswer(day1 && day1[`answer${i+1}`] ? day1[`answer${i+1}`] : null)).end,
            ]);
        }
        return data;
    }

    private formFieldBuilder = (day1: Field_Assessment, day2: Field_Assessment, day3: Field_Assessment, day4: Field_Assessment, day5: Field_Assessment, day6: Field_Assessment, day7: Field_Assessment,) => {
        let data = [
            [
                new Txt('Question').bold().end,
                new Txt(this.getDayName(this.toDate, 6)).bold().end,
                new Txt(this.getDayName(this.toDate, 5)).bold().end,
                new Txt(this.getDayName(this.toDate, 4)).bold().end,
                new Txt(this.getDayName(this.toDate, 3)).bold().end,
                new Txt(this.getDayName(this.toDate, 2)).bold().end,
                new Txt(this.getDayName(this.toDate, 1)).bold().end,
                new Txt(this.getDayName(this.toDate, 0)).bold().end,
            ],
        ];
        for(let i = 0; i < questionsEn4.length; i++){
            data.push([
                new Txt(questionsEn4[i]).end,
                new Txt(this.formFieldAnswer(day7 && day7[`answer${i+1}`] ? day7[`answer${i+1}`] : null)).end,
                new Txt(this.formFieldAnswer(day6 && day6[`answer${i+1}`] ? day6[`answer${i+1}`] : null)).end,
                new Txt(this.formFieldAnswer(day5 && day5[`answer${i+1}`] ? day5[`answer${i+1}`] : null)).end,
                new Txt(this.formFieldAnswer(day4 && day4[`answer${i+1}`] ? day4[`answer${i+1}`] : null)).end,
                new Txt(this.formFieldAnswer(day3 && day3[`answer${i+1}`] ? day3[`answer${i+1}`] : null)).end,
                new Txt(this.formFieldAnswer(day2 && day2[`answer${i+1}`] ? day2[`answer${i+1}`] : null)).end,
                new Txt(this.formFieldAnswer(day1 && day1[`answer${i+1}`] ? day1[`answer${i+1}`] : null)).end,
            ]);
        }
        return data;
    }

    private formFieldAnswer = (answer: any) => {
        if(answer == 2){
            return 'N/A';
        }else if(answer == 1){
            return 'Yes';
        }else if(answer == 0){
            return 'No'
        }else {
            return 'N/A';
        }
    }
    

    private formChlorineBuilder = (day1: Chlorine_Inspection, day2: Chlorine_Inspection, day3: Chlorine_Inspection, day4: Chlorine_Inspection, day5: Chlorine_Inspection, day6: Chlorine_Inspection, day7: Chlorine_Inspection,) => {
        let data = [
            [
                new Txt('Question').bold().end,
                new Txt(this.getDayName(this.toDate, 6)).bold().end,
                new Txt(this.getDayName(this.toDate, 5)).bold().end,
                new Txt(this.getDayName(this.toDate, 4)).bold().end,
                new Txt(this.getDayName(this.toDate, 3)).bold().end,
                new Txt(this.getDayName(this.toDate, 2)).bold().end,
                new Txt(this.getDayName(this.toDate, 1)).bold().end,
                new Txt(this.getDayName(this.toDate, 0)).bold().end,
            ],
        ];
        for(let i = 0; i < questionsEn5.length; i++){
            data.push([
                new Txt(questionsEn5[i]).end,
                new Txt(this.formChlorineAnswer(day7 && day7[`answer${i+1}`] ? day7[`answer${i+1}`] : null, i)).end,
                new Txt(this.formChlorineAnswer(day6 && day6[`answer${i+1}`] ? day6[`answer${i+1}`] : null, i)).end,
                new Txt(this.formChlorineAnswer(day5 && day5[`answer${i+1}`] ? day5[`answer${i+1}`] : null, i)).end,
                new Txt(this.formChlorineAnswer(day4 && day4[`answer${i+1}`] ? day4[`answer${i+1}`] : null, i)).end,
                new Txt(this.formChlorineAnswer(day3 && day3[`answer${i+1}`] ? day3[`answer${i+1}`] : null, i)).end,
                new Txt(this.formChlorineAnswer(day2 && day2[`answer${i+1}`] ? day2[`answer${i+1}`] : null, i)).end,
                new Txt(this.formChlorineAnswer(day1 && day1[`answer${i+1}`] ? day1[`answer${i+1}`] : null, i)).end,
            ]);
        }
        return data;
    }

    private formChlorineAnswer = (answer: any, index: number) => {
        if(index == 2){
            if(answer == 2){
                return 'N/A';
            }else if(answer == 1){
                return 'Done';
            }else if(answer == 0){
                return 'Not Done'
            }else {
                return 'N/A';
            }
        }else{
            return answer;
        }
    }
    
    private formWeightBuilder = (day1: Weight_Inspection, day2: Weight_Inspection, day3: Weight_Inspection, day4: Weight_Inspection, day5: Weight_Inspection, day6: Weight_Inspection, day7: Weight_Inspection,) => {
        let data = [
            [
                new Txt('Question').bold().end,
                new Txt(this.getDayName(this.toDate, 6)).bold().end,
                new Txt(this.getDayName(this.toDate, 5)).bold().end,
                new Txt(this.getDayName(this.toDate, 4)).bold().end,
                new Txt(this.getDayName(this.toDate, 3)).bold().end,
                new Txt(this.getDayName(this.toDate, 2)).bold().end,
                new Txt(this.getDayName(this.toDate, 1)).bold().end,
                new Txt(this.getDayName(this.toDate, 0)).bold().end,
            ],
        ];
        for(let i = 0; i < questionsEn6.length; i++){
            data.push([
                new Txt(questionsEn6[i]).end,
                new Txt(day7[`answer${i+1}`]).end,
                new Txt(day6[`answer${i+1}`]).end,
                new Txt(day5[`answer${i+1}`]).end,
                new Txt(day4[`answer${i+1}`]).end,
                new Txt(day3[`answer${i+1}`]).end,
                new Txt(day2[`answer${i+1}`]).end,
                new Txt(day1[`answer${i+1}`]).end,
            ]);
        }
        return data;
    }

    private getDayName = (date: any, day: number) => {
        return `${moment(date, 'YYYY-MM-DD').subtract('day', day).format('ddd')} - ${moment(date, 'YYYY-MM-DD').subtract('day', day).format('DD')}`;
    }
}
