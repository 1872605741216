<div class="container" id="modal">
    <mat-toolbar>
        <span class="fill-space"></span>
        <span>Daily Report Detail</span>
        <span class="fill-space"></span>
        <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
    <div class="detail" *ngIf="!isValidating">
        <h1>Main information</h1>
        <mat-divider></mat-divider>
        <div>
            <mat-form-field>
                <mat-label>Grower</mat-label>
                <input matInput [value]="form.GrowerName" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Ranch</mat-label>
                <input matInput [value]="form.RanchName" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Contact Name</mat-label>
                <input matInput [value]="form.ContactName" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Harvesting</mat-label>
                <input matInput [value]="form.Harvesting" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Field</mat-label>
                <input matInput [value]="form.Yard" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Field ID</mat-label>
                <input matInput [value]="form.SubYard" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Harvest Crew</mat-label>
                <input matInput [value]="form.Squad" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Foreman</mat-label>
                <input matInput [value]="form.Foreman" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput [value]="form.Adress" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Location</mat-label>
                <input matInput [value]="form.Location" disabled>
            </mat-form-field>
        </div>
        <div *ngIf="form.ValidatedBy > 0">
            <mat-form-field>
                <mat-label>Validated Date</mat-label>
                <input matInput [value]="form.ValidateDate" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Validated By</mat-label>
                <input matInput [value]="form.ValidatedName + ' ' + form.ValidatedLastname" disabled>
            </mat-form-field>
        </div>
        <div *ngIf="form.ValidatedBy > 0">
            <mat-form-field style="width: 98% !important; margin: auto auto">
                <mat-label>Validator comments</mat-label>
                <textarea matInput [value]="form.CommentByValidator" disabled></textarea>
            </mat-form-field>
        </div>
        <h1>Form - Daily harvest crew and machine inspection</h1>
        <p>Done at {{form.FormJson.Form1Date | date: 'MM/dd/yyyy hh:mm'}}</p>
        <mat-divider></mat-divider>
        <table>
            <thead>
                <tr>
                    <th>Question identify</th>
                    <th>Answer</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let question of form.FormJson.Form1;let i = index">
                    <td>Question - {{i + 1}}</td>
                    <td>{{answerTypeDone(question.answer)}}</td>
                </tr>
            </tbody>
        </table>
        <p><b>Comments</b>: {{form.FormJson.Form1Comment.length > 0 ? form.FormJson.Form1Comment : "There is no comments."}}</p>
        <p><b>Evidence</b>: </p>
        <figure *ngIf="form.FormJson.Form1Image.length > 0">
            <img [src]="form.FormJson.Form1Image"/>
        </figure>
        <p *ngIf="form.FormJson.Form1Image.length == 0">There is no evidence.</p>

        <mat-divider></mat-divider>

        <h1>Form - Harvest machine and equipment cleaning and sanitation</h1>
        <p>Done at {{form.FormJson.Form3Date | date: 'MM/dd/yyyy hh:mm'}}</p>
        <mat-divider></mat-divider>
        <table>
            <thead>
                <tr>
                    <th>Question identify</th>
                    <th>Answer</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let question of form.FormJson.Form3;let i = index">
                    <td>Question - {{i + 1}}</td>
                    <td>{{answerTypeDone(question.answer)}}</td>
                </tr>
            </tbody>
        </table>
        <p><b>Comments</b>: {{form.FormJson.Form3Comment.length > 0 ? form.FormJson.Form3Comment : "There is no comments."}}</p>
        <p><b>Evidence</b>: </p>
        <figure *ngIf="form.FormJson.Form3Image.length > 0">
            <img [src]="form.FormJson.Form3Image"/>
        </figure>
        <p *ngIf="form.FormJson.Form3Image.length == 0">There is no evidence.</p>

        <mat-divider></mat-divider>

        <h1>Form - Daily harvest field assessment</h1>
        <p>Done at {{form.FormJson.Form2Date | date: 'MM/dd/yyyy hh:mm'}}</p>
        <mat-divider></mat-divider>
        <table>
            <thead>
                <tr>
                    <th>Question identify</th>
                    <th>Answer</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let question of form.FormJson.Form2;let i = index">
                    <td>Question - {{i + 1}}</td>
                    <td>{{answerTypeYes(question.answer)}}</td>
                </tr>
            </tbody>
        </table>
        <p><b>Comments</b>: {{form.FormJson.Form2Comment.length > 0 ? form.FormJson.Form2Comment : "There is no comments."}}</p>
        <p><b>Evidence</b>: </p>
        <figure *ngIf="form.FormJson.Form2Image.length > 0">
            <img [src]="form.FormJson.Form2Image"/>
        </figure>
        <p *ngIf="form.FormJson.Form2Image.length == 0">There is no evidence.</p>
        
        <mat-divider></mat-divider>

        <h1>Form - Machine inspection</h1>
        <p>Done at {{form.FormJson.Form4Date | date: 'MM/dd/yyyy hh:mm' }}</p>
        <mat-divider></mat-divider>
        <table>
            <thead>
                <tr>
                    <th>Question identify</th>
                    <th>Answer</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let question of form.FormJson.Form4;let i = index">
                    <td>Question - {{i + 1}}</td>
                    <td>{{answerTypeYes(question.answer)}}</td>
                </tr>
            </tbody>
        </table>
        <p><b>Comments</b>: {{form.FormJson.Form4Comment.length > 0 ? form.FormJson.Form4Comment : "There is no comments."}}</p>
        <p><b>Evidence</b>: </p>
        <figure *ngIf="form.FormJson.Form4Image.length > 0">
            <img [src]="form.FormJson.Form4Image"/>
        </figure>
        <p *ngIf="form.FormJson.Form4Image.length == 0">There is no evidence.</p>

        <mat-divider></mat-divider>

        <h1>Form - Chlorine inspection</h1>
        <h2>Inspections - {{form.FormJsonPeriodic.ChlorineInspectionForm.length}}</h2>
        <div *ngFor="let chlorine of form.FormJsonPeriodic.ChlorineInspectionForm; let i = index;">
            <h3>Form - {{ (i + 1) }}</h3>
            <p>Done at {{chlorine.CreatedDate | date: 'MM/dd/yyyy hh:mm'}}</p>
            <mat-divider></mat-divider>
            <table>
                <thead>
                    <tr>
                        <th>Question identify</th>
                        <th>Answer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Question - {{ chlorine.Questions["1"].question }}</td>
                        <td>{{ chlorine.Answers["0"].answer.length > 0 ? chlorine.Answers["0"].answer : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ chlorine.Questions["2"].question }}</td>
                        <td>{{ chlorine.Answers["1"].answer.length > 0 ? chlorine.Answers["1"].answer : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ chlorine.Questions["3"].question }}</td>
                        <td>{{ chlorine.Answers["2"].answer.length > 0 ? chlorine.Answers["2"].answer : "Not captured" }}</td>
                    </tr>
                </tbody>
            </table>
            <p><b>Comments</b>: {{chlorine.Comment}}</p>
            <figure>
                <img [src]="chlorine.Image"/>
            </figure>
        </div>

        <mat-divider></mat-divider>

        <h1>Form - Weight inspection</h1>
        <h2>Inspections - {{form.FormJsonPeriodic.WeightInspectionForm.length}}</h2>
        <div *ngFor="let Weight of form.FormJsonPeriodic.WeightInspectionForm; let i = index;">
            <h3>Form - {{ (i + 1) }}</h3>
            <p>Done at {{Weight.CreatedDate | date: 'MM/dd/yyyy hh:mm'}}</p>
            <mat-divider></mat-divider>
            <table>
                <thead>
                    <tr>
                        <th>Question identify</th>
                        <th>Answer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Question - {{ Weight.Questions["1"].question }}</td>
                        <td>{{ Weight.Answers["0"].answer.length > 0 ? Weight.Answers["0"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["2"].question }}</td>
                        <td>{{ Weight.Answers["1"].answer.length > 0 ? Weight.Answers["1"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["3"].question }}</td>
                        <td>{{ Weight.Answers["2"].answer.length > 0 ? Weight.Answers["2"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["4"].question }}</td>
                        <td>{{ Weight.Answers["3"].answer.length > 0 ? Weight.Answers["3"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["5"].question }}</td>
                        <td>{{ Weight.Answers["4"].answer.length > 0 ? Weight.Answers["4"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["6"].question }}</td>
                        <td>{{ Weight.Answers["5"].answer.length > 0 ? Weight.Answers["5"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["7"].question }}</td>
                        <td>{{ Weight.Answers["6"].answer.length > 0 ? Weight.Answers["6"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["8"].question }}</td>
                        <td>{{ Weight.Answers["7"].answer.length > 0 ? Weight.Answers["7"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["9"].question }}</td>
                        <td>{{ Weight.Answers["8"].answer.length > 0 ? Weight.Answers["8"].answer.length : "Not captured" }}</td>
                    </tr>
                    <tr>
                        <td>Question - {{ Weight.Questions["10"].question }}</td>
                        <td>{{ Weight.Answers["9"].answer.length > 0 ? Weight.Answers["9"].answer.length : "Not captured" }}</td>
                    </tr>
                </tbody>
            </table>
            <p><b>Comments</b>: {{Weight.Comment}}</p>
            <figure>
                <img [src]="Weight.Image"/>
            </figure>
        </div>
    </div>
    <div class="detail" *ngIf="isValidating">
        <div class="center-mat">
            <mat-form-field style="margin-top: 80px; width: 20% !important;">
                <mat-label>Validate Code</mat-label>
                <input matInput  type="password" [(ngModel)]="code" style="text-align: center;"/>
            </mat-form-field>
        </div>
        <div class="center-mat">
            <mat-form-field>
                <mat-label>Comments</mat-label>
                <textarea matInput placeholder="Ex. It needs to be..." [(ngModel)]="comments"></textarea>
            </mat-form-field>
        </div>
    </div>
    <footer class="dialog-footer">
        <div class="button-row">
            <div *ngIf="!isValidating">
                <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
                <button mat-raised-button color="primary" (click)="isValidating = true;" [disabled]="form.ValidatedBy > 0">Validate</button>
            </div>
            <div *ngIf="isValidating">
                <button mat-raised-button color="secondary" (click)="cancelValidate()">Cancel</button>
                <button mat-raised-button color="primary" (click)="validate()">Confirm</button>
            </div>
        </div>
    </footer>
</div>