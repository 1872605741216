import { Component, OnInit } from '@angular/core';
import DataTable from '../../components/table/table.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';
import { MachineModalAddComponent } from './machine-modal-add/machine-modal-add.component';
import { MachinesAndEquipmentService } from './machines-component.service';
import { MachinesAndEquipment } from '../../models/machinesAndEquipment.model';
import { MachinesModalEditComponent } from './machine-modal-edit/machines-modal-edit.component';
import { MachineModalDeleteComponent } from './machine-modal-delete/machine-modal-delete.component';

@Component({
  selector: 'app-machines-component',
  templateUrl: './machines-component.component.html',
  styleUrls: ['./machines-component.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MachinesComponentComponent implements OnInit {

  public dataTable: DataTable = new DataTable();
  public machinesAndEquipment: Array<MachinesAndEquipment> = [];

  constructor(public dialog: MatDialog, private machinesAndEquipmentService:MachinesAndEquipmentService) { }

  async ngOnInit() {
    this.configTable();
    this.get();
    // let a = await this.machinesAndEquipmentService.Get();
  }

  private get = async() => {
    try {
      this.machinesAndEquipment = await this.machinesAndEquipmentService.Get();
    }catch(error){
      console.log(error);
    }
  }

  private configTable = () => {
    this.dataTable.Headers.push('Description');
    this.dataTable.Headers.push('Manufacturer');
    this.dataTable.Headers.push('HF');
    this.dataTable.Headers.push('LicensePlate');
    this.dataTable.Headers.push('Date');
    this.dataTable.Headers.push('Actions');
  }

  // UPDATE --------------->
  public update = (event: {data: MachinesAndEquipment, index: number}) => {
    this.modalEdit(event);
  }

  // DELETE --------------->
  public delete = (event: {dataId: number, index: number}) => {
    this.modalDelete(event);
  }

  public modalAdd = () => {
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    let dialogRef = this.dialog.open(MachineModalAddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.machinesAndEquipment.concat(result);
        this.machinesAndEquipment = newArray;
      }
    });
  }

  //Open Modal Edit
  private modalEdit = (event: {data: MachinesAndEquipment, index: number}) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {machinesAndEquipment: event.data};
    let dialogRef = this.dialog.open(MachinesModalEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.machinesAndEquipment;
        newArray.map(machine => {
          if(machine.Id === result.Id){
            machine.Description = result.Description,
            machine.Manufacturer = result.Manufacturer,
            machine.HF = result.HF,
            machine.LicensePlate = result.LicensePlate,
            machine.Date = result.Date,
            machine.Status = result.Status
          }
        });
        this.machinesAndEquipment = newArray;
      }
    });
  }

    // Open Modal Delete
    private modalDelete = (event: {dataId: number, index: number}) => {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {machinesAndEquipmentId: event.dataId};
      let dialogRef = this.dialog.open(MachineModalDeleteComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(async(result) => {
        if(result != null && !result.error){
          let newArray = this.machinesAndEquipment.filter((x, i)=> i != event.index);
          this.machinesAndEquipment = newArray;
        }
      });
    }

}
