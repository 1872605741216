import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { Grower } from '../growers.model';
import { ErrorStateMatcher } from '@angular/material/core';
import { GrowersService } from '../growers.service';

export class GrowerEditErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-grower-modal-edit',
  templateUrl: './grower-modal-edit.component.html',
  styleUrls: ['./grower-modal-edit.component.scss']
})
export class GrowerModalEditComponent implements OnInit {

  public matcher:GrowerEditErrorStateMatcher = new GrowerEditErrorStateMatcher();

  constructor(public dialogRef: MatDialogRef<GrowerModalEditComponent>, @Inject(MAT_DIALOG_DATA) public data: {grower: Grower}, private service: GrowersService) { }

  ngOnInit(): void {
    this.form.controls.name.setValue(this.data.grower.Name);
    this.form.controls.contactName.setValue(this.data.grower.ContactName);
    this.form.controls.cellNumber.setValue(this.data.grower.CellNumber);
    this.form.controls.phoneNumber.setValue(this.data.grower.PhoneNumber);
    this.form.controls.faxNumber.setValue(this.data.grower.FaxNumber);
    this.form.controls.email.setValue(this.data.grower.Email);
    this.form.controls.adress.setValue(this.data.grower.Adress);
    this.form.controls.location.setValue(this.data.grower.Location);
    this.form.controls.city.setValue(this.data.grower.City);
    this.form.controls.county.setValue(this.data.grower.County);
    this.form.controls.state.setValue(this.data.grower.State);
    this.form.controls.country.setValue(this.data.grower.Country);
    this.form.controls.zipCode.setValue(this.data.grower.ZipCode);
    this.form.controls.size.setValue(this.data.grower.Size);
  }

  public form: FormGroup = new FormGroup({
    name: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
    contactName: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
    cellNumber: new FormControl('', Validators.maxLength(20)),
    phoneNumber: new FormControl('', Validators.maxLength(20)),
    faxNumber: new FormControl('', Validators.maxLength(20)),
    email: new FormControl('', Validators.email),
    adress: new FormControl('', Validators.maxLength(200)),
    location: new FormControl('', Validators.maxLength(500)),
    city: new FormControl('', Validators.maxLength(80)),
    county: new FormControl('', Validators.maxLength(80)),
    state: new FormControl('', Validators.maxLength(80)),
    country: new FormControl('', Validators.maxLength(80)),
    zipCode: new FormControl('', Validators.maxLength(10)),
    size: new FormControl('', Validators.maxLength(100))
  });
  
  public cancelModal = (data: Grower = null) => {
    this.dialogRef.close(data);
  }

  public onEdit = async() => {

    let grower: Grower = new Grower();
    
    grower.Id = this.data.grower.Id;
    grower.Name = this.form.controls.name.value;
    grower.ContactName = this.form.controls.contactName.value;
    grower.CellNumber = this.form.controls.cellNumber.value;
    grower.PhoneNumber = this.form.controls.phoneNumber.value;
    grower.FaxNumber = this.form.controls.faxNumber.value;
    grower.Email = this.form.controls.email.value;
    grower.Adress = this.form.controls.adress.value;
    grower.Location = this.form.controls.location.value;
    grower.City = this.form.controls.city.value;
    grower.County = this.form.controls.county.value;
    grower.State = this.form.controls.state.value;
    grower.Country = this.form.controls.country.value;
    grower.ZipCode = this.form.controls.zipCode.value;
    grower.Size = this.form.controls.size.value;

    let result: any = await this.service.Edit(grower);
    
    if(result.error){
      return;
    }
    this.cancelModal(result);

  }

}
