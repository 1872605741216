import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import DataTable from '../../components/table/table.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GrowerModalAddComponent } from './grower-modal-add/grower-modal-add.component';
import { Grower } from './growers.model';
import { GrowersService } from './growers.service';
import { GrowerModalEditComponent } from './grower-modal-edit/grower-modal-edit.component';
import { GrowerModalDeleteComponent } from './grower-modal-delete/grower-modal-delete.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-growers',
  templateUrl: './growers.component.html',
  styleUrls: ['./growers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GrowersComponent implements OnInit {

  public growers: Array<Grower> = [];
  public dataTable: DataTable = new DataTable();

  constructor(public dialog: MatDialog,private growersService: GrowersService, private router: Router) { }

  async ngOnInit() {
    await this.get();
    this.configTable();
  }

  private get = async() => {
    try {
      this.growers = await this.growersService.Get();
    }catch(error){
      console.log(error);
    }
  }

  private configTable = () => {
    this.dataTable.Headers.push('Name');
    this.dataTable.Headers.push('ContactName');
    this.dataTable.Headers.push('PhoneNumber');
    this.dataTable.Headers.push('Email');
    this.dataTable.Headers.push('Status');
    this.dataTable.Headers.push('Actions');
  }

  // UPDATE --------------->
  public update = (event: {data: Grower, index: number}) => {
    this.modalEdit(event);
  }

  // DELETE --------------->
  public delete = (event: {dataId: number, index: number}) => {
    this.modalDelete(event);
  }

  //Open Modal Create
  public modalAdd = () => {
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    let dialogRef = this.dialog.open(GrowerModalAddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.growers.concat(result);
        this.growers = newArray;
      }
    });
  }

  //Open Modal Edit
  private modalEdit = (event: {data: Grower, index: number}) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {grower: event.data};
    let dialogRef = this.dialog.open(GrowerModalEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.growers;
        newArray.map(grower => {
          if(grower.Id === result.Id){
            grower.Adress = result.Adress
            grower.CellNumber = result.CellNumber
            grower.City = result.City
            grower.ContactName = result.ContactName
            grower.Country = result.Country
            grower.County = result.County
            grower.Email = result.Email
            grower.FaxNumber = result.FaxNumber
            grower.Location = result.Location
            grower.Name = result.Name
            grower.PhoneNumber = result.PhoneNumber
            grower.Size = result.Size
            grower.State = result.State
            grower.Status = result.Status
            grower.ZipCode = result.ZipCode
          }
        })
        this.growers = newArray;
      }
    });
  }

  // Open Modal Delete
  private modalDelete = (event: {dataId: number, index: number}) => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {growerId: event.dataId};
    let dialogRef = this.dialog.open(GrowerModalDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.growers.filter((x, i)=> i != event.index);
        this.growers = newArray;
      }
    });
  }

  public navigateToRanch = (event: {growerId: number}) => {
    this.router.navigate(['growers/ranch', event.growerId]);
  }

}
