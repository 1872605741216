import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomReportService } from '../custom-report.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-create-custom-report',
  templateUrl: './create-custom-report.component.html',
  styleUrls: ['./create-custom-report.component.scss']
})
export class CreateCustomReportComponent implements OnInit {

  public questionList: any[] = [];
  public title: string = '';
  public typeName: boolean = false;
  
  constructor(public dialogRef: MatDialogRef<CreateCustomReportComponent>, private service: CustomReportService, @Inject(MAT_DIALOG_DATA) public data: {report: any}) { }

  ngOnInit(): void {
    
    if(this.data.report !== null) {
      this.title = this.data.report.Title;
      this.typeName = this.data.report.Type;
      this.questionList = [];
      if(this.data.report.questions.length > 0) {
        console.log(this.data.report.questions)
        this.data.report.questions.map((elem: any, i: number) => {

          let obj: any = {};
          
          obj.id = elem.Id;
          obj.customFormHead = elem.Custom_Form_Head_Id;
          obj['question'+i] = elem.Question;
          obj['translation'+i] = elem.Translation;
          obj['inputType'+i] = elem.Custom_Form_Input_Id;
          
          this.questionList.push(obj);
        });
      }
    }
  }

  //Function Para cerrar y crear modal
  public cancelModal = (result: any) => {
    this.dialogRef.close(result);
  }

  public createMachinesAndEquipment = async(update: boolean) => {

    let report: any = {Id: 0, title: '', typeName: '', status: true, questions: []};
    
    if(this.data.report !== null) {
      report.Id = this.data.report.id;
    }
    report.title = this.title;   
    report.typeName = this.typeName;

    this.questionList.map((q:any, i: number) => {
      report.questions.push({
        id: q['id'],
        customFormHead: q['customFormHead'],
        question: q['question'+i],
        translation: q['translation'+i],
        inputType: q['inputType'+i]
      })
    });
    
    let result: any = update ? (await this.service.editData(report)) : (await this.service.createData(report));
    if(result.error){
      return;
    }
    this.cancelModal(result);
  }

  public addNewQuestion = () => {
    this.questionList.push({
      id: uuidv4(),
      customFormHead: 0,
      question: '',
      translation: '',
      inputType: 0
    });
  }

  public removeQuestion = (question: any) => {
    this.questionList = this.questionList.filter(x => x.id !== question.id);
  }
}
