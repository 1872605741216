<div class="container" id="modal">
    <mat-toolbar>
      <span class="fill-space"></span>
      <span>New Sub folder</span>
      <span class="fill-space"></span>
      <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
    
    <form [formGroup]="form">
      <div class="identity-information">
        <h2>Information</h2>
      </div>
      <mat-divider></mat-divider>
  
      <div class="block1">
        <mat-form-field>
          <mat-label>Folder name</mat-label>
          <input matInput type="text" formControlName="name" placeholder="Folder name*">
          <mat-error *ngIf="form.controls['name'].errors?.required">Folder name is required</mat-error>
          <mat-error *ngIf="form.controls['name'].errors?.maxLength">Folder name cannot contain more than 50 letters</mat-error>
          <mat-error *ngIf="form.controls['name'].errors?.minLength">Folder name cannot contain less than 3 letters</mat-error>
        </mat-form-field>
      </div>
    </form>
    
    <footer class="dialog-footer">
      <div class="button-row">
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="onAdd()">Create</button>
        <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
      </div>
    </footer>
</div>
    
  