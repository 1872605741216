<div class="container-form">
   <mat-card>
       <h1>Forms</h1>
       <mat-selection-list [multiple]="false">
            <mat-list-option *ngFor="let route of formRoutes" [value]="route" (click)="goTo(route)">
                <div class="item">
                    <mat-icon>{{route.icon}}</mat-icon>{{route.title}}
                </div>
            </mat-list-option>
       </mat-selection-list>
   </mat-card>
</div>
