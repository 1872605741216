import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'app-daily-modal-detail',
  templateUrl: './daily-modal-detail.component.html',
  styleUrls: ['./daily-modal-detail.component.scss']
})
export class DailyModalDetailComponent implements OnInit {

  public isValidating: boolean = false;
  public form: any = {};
  public code: string = "";
  public comments: string = "";

  constructor(
    public dialogRef: MatDialogRef<DailyModalDetailComponent>,
    private service: ReportsService,
    @Inject(MAT_DIALOG_DATA) private data: {form: any}){}

  ngOnInit(): void {

    this.form = this.data.form;
    
    try {
      this.form.FormJson = JSON.parse(this.form.FormJson);
      this.form.FormJsonPeriodic = JSON.parse(this.form.FormJsonPeriodic);
    }catch(err) {}

    this.form.Foreman = `${this.form.UserName} ${this.form.UserLastName}`;
  }
  
  public cancelModal = (result: any = null) => {
    this.dialogRef.close(result);
  }
  
  public validate = async() => {
    if(this.code.trim().length == 0) return;

    let result = await this.service.ValidateForm({ formId: this.form.Id, userId: null, code: this.code, comments: this.comments});
    this.cancelModal(result);
  }

  public cancelValidate = () => {
    this.code = "";
    this.comments = "";
    this.isValidating = false;
  }

  public answerTypeDone = (answer: any) => {
    let result = 'N/A';
    switch(answer) {
      case 0: result = 'Not Done'; break;
      case 1: result = 'Done'; break;
      case 2: break;
      default: result = answer; break;
    }
    return result;
  }

  public answerTypeYes = (answer: any) => {
    let result = 'N/A';
    switch(answer) {
      case 0: result = 'No'; break;
      case 1: result = 'Yes'; break;
      case 2: break;
      default: result = answer; break;
    }
    return result;
  }
}
