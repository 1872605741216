<div class="container">
  <mat-card>
      <div class="title">
          <button mat-icon-button (click)="back()">
          <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <h1>Water Reports</h1>
      </div>
      <mat-tab-group>
          <mat-tab label="">
              <div class="tab-content">
                  <h1>Filters</h1>
                  <div>
                      <mat-form-field appearance="fill" class="input-custom">
                          <mat-label>Select User</mat-label>
                          <mat-select [(ngModel)]="userId">
                              <mat-option [value]="null">None</mat-option>
                              <mat-option *ngFor="let user of users" [value]="user.Id">
                                  {{user.Name}} {{user.LastName}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
                  
                  <div>
                      <mat-form-field appearance="fill" class="input-custom" (click)="dp1.open()">
                          <mat-label>From Date</mat-label>
                          <input matInput [matDatepicker]="dp1" [(ngModel)]="fromDate" [max]="maxDate" autocomplete="off">
                          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                          <mat-datepicker #dp1 startView="multi-year" (monthSelected)="closeDatePicker($event, dp1)" disabled="false"></mat-datepicker>
                      </mat-form-field>
                  </div>
                  <div>
                      <button mat-raised-button color="primary" (click)="getData()">
                          Submit filters
                      </button>
                  </div>
                  <div class="main-table">
                      <app-table-v2 [data]="reports" [dataTable]="waterReportsdataTable" [isReport]="true" (detailItem)="detail($event)" (downloadItem)="download($event)" [showDownloadAll]="false"></app-table-v2>
                  </div>
              </div>
          </mat-tab>
      </mat-tab-group>
  </mat-card>
</div>