import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: ['./weekly.component.scss']
})
export class WeeklyComponent implements OnInit {

  constructor(
    private router:Router
  ) { }

  ngOnInit(): void {
    this.displayedColumns = ['Actions'];
    this.dataSource = new MatTableDataSource([]);
  }

  public back = () => {
    this.router.navigate(['reports']);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public displayedColumns: Array<string>;
  public dataSource: MatTableDataSource<any>;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  public checkData = (data, header) => {
    if(header == 'Actions')
      return '';

    if(data == null)
      return 'Not captured';

    if(typeof data == 'string'){
      if(data.trim() == '' || data == 'null' || data == 'Null')
        return 'Not captured'
    }
    
    if(header == 'Status') {
      if(data)
        return 'Active';
      else
        return 'Inactive';
    }
    
    return data;
  }

  public update = (selected: any, index: number) => {
    
  }

  public delete = (selected: any, index: number) => {
    
  }

  public ranch = (growerId: number) => {
    
  }

}
