import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import User from './models/user.model';

@Injectable({
    providedIn: 'root'
})
export class ts {
    
    private _s:BehaviorSubject<string>;

    constructor() {
        this._s = new BehaviorSubject<string>('');
    }

    public exist = (): boolean => {
        return this._s.value.length > 0;
    }
    public setValue = (newValue: string): void => {
        this._s.next(newValue);
    }

    public getValue = (): Observable<string> => {
        return this._s.asObservable();
    }

    public delete = (): void => {
        this._s.next('');
    }

    public persist = (): void => {
        localStorage.setItem('_ss', this._s.value);
    }

    public cleanPersist = (): void => {
        localStorage.removeItem('_ss');
    }

    public checkPersist = (): boolean => {
        return localStorage.getItem('_ss') != null && localStorage.getItem('_ss') != void 0;
    }

    public loadPersist = (): void => {
        if(this.checkPersist()){
            this.setValue(localStorage.getItem('_ss'));
        }
    }

    public updatePersist = (): void => {
        this.cleanPersist();
        this.persist();
    }
}

@Injectable({
    providedIn: 'root'
})
export class ActiveUser {

    private _user: BehaviorSubject<User>;

    constructor() {
        this._user = new BehaviorSubject<User>(new User());
    }

    public setValue = (newValue: User): void => {
        this._user.next(newValue);
    }

    public getValue = (): Observable<User> => {
        return this._user.asObservable();
    }

    public delete = (): void => {
        this._user.next(new User());
    }

    public persist = (): void => {
        localStorage.setItem('_us', JSON.stringify(this._user.value));
    }

    public cleanPersist = (): void => {
        localStorage.removeItem('_us');
    }

    public checkPersist = (): boolean => {
        return localStorage.getItem('_us') != null && localStorage.getItem('_us') != void 0;
    }

    public loadPersist = (): void => {
        if(this.checkPersist()){
            this.setValue(JSON.parse(localStorage.getItem('_us')));
        }
    }

    public updatePersist = (): void => {
        this.cleanPersist();
        this.persist();
    }
}