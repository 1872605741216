import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {GrowersService} from '../growers.service';

@Component({
  selector: 'app-grower-modal-delete',
  templateUrl: './grower-modal-delete.component.html',
  styleUrls: ['./grower-modal-delete.component.scss']
})
export class GrowerModalDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GrowerModalDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: {growerId: number}, private service: GrowersService) { }

  ngOnInit(): void {
  }

  public cancelModal = (data) => {
    this.dialogRef.close(data);
  }

  public delete = async() => {
    let result: any = await this.service.Delete(this.data.growerId);
    if(!result.error)
      this.cancelModal(this.data.growerId);
  }

}
