import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MachinesAndEquipment } from '../../../models/machinesAndEquipment.model';
import { MachinesAndEquipmentService } from '../machines-component.service';

export class GrowerEditErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-machines-modal-edit',
  templateUrl: './machines-modal-edit.component.html',
  styleUrls: ['./machines-modal-edit.component.scss']
})
export class MachinesModalEditComponent implements OnInit {

  public matcher:GrowerEditErrorStateMatcher = new GrowerEditErrorStateMatcher();

  constructor(public dialogRef: MatDialogRef<MachinesModalEditComponent>, @Inject(MAT_DIALOG_DATA) public data: {machinesAndEquipment: MachinesAndEquipment}, private service: MachinesAndEquipmentService) { }

  ngOnInit(): void {
    this.form.controls.description.setValue(this.data.machinesAndEquipment.Description);
    this.form.controls.manufacturer.setValue(this.data.machinesAndEquipment.Manufacturer);
    this.form.controls.hf.setValue(this.data.machinesAndEquipment.HF);
    this.form.controls.plate.setValue(this.data.machinesAndEquipment.LicensePlate);
    this.form.controls.date.setValue(this.data.machinesAndEquipment.Date);
  }

  public form: FormGroup = new FormGroup({
    id: new FormControl(0),
    description: new FormControl('', [Validators.required, Validators.maxLength(150)]),
    manufacturer: new FormControl('', Validators.required),
    hf: new FormControl('', Validators.required),
    plate: new FormControl('', [Validators.maxLength(50), Validators.minLength(3)]),
    date: new FormControl('', [Validators.required]),
  });

  public cancelModal = (result: any) => {
    this.dialogRef.close(result);
  }

  public onEdit = async() => {

    let machines: MachinesAndEquipment = new MachinesAndEquipment();
    
    machines.Id = this.data.machinesAndEquipment.Id;
    machines.Description = this.form.controls.description.value;
    machines.Manufacturer = this.form.controls.manufacturer.value;
    machines.HF = this.form.controls.hf.value;
    machines.LicensePlate = this.form.controls.plate.value;
    machines.Date = this.form.controls.date.value;

    let result: any = await this.service.Edit(machines);
    
    if(result.error){
      return;
    }
    this.cancelModal(result);

  }

}
