import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import PreSeason from '../pre-season.model';
import { Grower } from 'src/app/models/grower.model';
import { Ranch } from 'src/app/views/ranches/ranches.model';
import { RanchesService } from 'src/app/views/ranches/ranches.service';
import { PreSeasonService } from '../pre-season.service';


@Component({
  selector: 'app-pre-season-modal-add',
  templateUrl: './pre-season-modal-add.component.html',
  styleUrls: ['./pre-season-modal-add.component.scss']
})

export class PreSeasonModalAddComponent implements OnInit {
  public isPreSeason: boolean = true;
  public hours:Array<number> = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];
  public minutes:Array<number> = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,];
  public growers: Array<Grower> = [];
  public ranches: Array<Ranch> = [];
  public ranchesFiltered: Array<Ranch> = [];
  public growerId:number;

  constructor(public dialogRef: MatDialogRef<PreSeasonModalAddComponent>, private ranchService:RanchesService, @Inject(MAT_DIALOG_DATA) public data:{growers:any}, private service:PreSeasonService) { }

  ngOnInit(): void {
    this.growers = this.data.growers;
  }

  public cancelModal = (result: any = null) => {
    this.dialogRef.close(result);
  }

  public form:FormGroup = new FormGroup({
    //grower Id es un standalone NgModel
    ranchId: new FormControl(0, Validators.required),
    Date: new FormControl('', Validators.required),
    hours: new FormControl('', Validators.required),
    minutes: new FormControl('', Validators.required),
    Comodities: new FormControl('', Validators.required),
    Lots: new FormControl('', Validators.required),
    InspectedBy: new FormControl('', Validators.required),
    ReviewedBy: new FormControl('', Validators.required),
    ReviewedDate: new FormControl('', Validators.required),
    WellWater: new FormControl(null),
    TerrainWater: new FormControl(null),
    SupplyWater: new FormControl(null),
    Other: new FormControl(null),
    OtherExplain: new FormControl(null, Validators.maxLength(800)),
    Water1: new FormControl(null),
    Water2: new FormControl(null),
    Water3: new FormControl(null),
    Soil1: new FormControl(null),
    Soil2: new FormControl(null),
    Soil2Explain: new FormControl(null),
    Soil3: new FormControl(null),
    Soil3Explain: new FormControl(null),
    Soil4: new FormControl(null),
    Soil4Explain: new FormControl(null),
    Soil5: new FormControl(null),
    Soil5Explain: new FormControl(null),
    Environment1: new FormControl(null),
    Environment1Explain: new FormControl(null),
    Environment2: new FormControl(null),
    Environment2Explain: new FormControl(null),
    EnvironmentTemperature: new FormControl(null),
    EnvironmentPrecipitation: new FormControl(null),
    EnvironmentWind: new FormControl(null),
    Environment3: new FormControl(null),
    Environment4: new FormControl(null),
    EnvironmentCafo: new FormControl(null),
    EnvironmentAmendments: new FormControl(null),
    EnvironmentGrazing: new FormControl(null),
    EnvironmentSeptic: new FormControl(null),
    EnvironmentProtection: new FormControl(null),
    EnvironmentAreas: new FormControl(null),
    EnvironmentCafoExplain1: new FormControl(null),
    EnvironmentCafoExplain2: new FormControl(null),
    EnvironmentCafoExplain3: new FormControl(null),
    EnvironmentCafoExplain4: new FormControl(null),
    Environment5: new FormControl(null),
    EmployeeHygiene: new FormControl(null),
    EmployeeHygieneExplain: new FormControl(null),
    Sanitation: new FormControl(null),
    WaterComments: new FormControl(null, Validators.maxLength(800)),
    SoilComments: new FormControl(null, Validators.maxLength(800)),
    EnvironmentComments: new FormControl(null, Validators.maxLength(800)),
    EmployeeComments: new FormControl(null, Validators.maxLength(800)),
    SanitationComments: new FormControl(null, Validators.maxLength(800)),
    AllergentsComments: new FormControl(null, Validators.maxLength(800)),
  });
  public growerChange = async(growerId: number) => {
    this.ranches = await this.ranchService.Get(growerId);
    this.ranchesFiltered = await this.ranches.filter(x => x.IdGrower === growerId).sort((a,b)=> a<b ?-1: 1);
  }

  public Create = async() => {
    let preseason = new PreSeason();
    preseason.GrowerId = this.growerId;
    preseason.RanchId = this.form.controls.ranchId.value;
    preseason.Date = this.form.controls.Date.value;
    let time:string = `${this.form.controls.hours.value}:${this.form.controls.minutes.value}`;
    preseason.Time = time;
    preseason.Comodities = this.form.controls.Comodities.value;
    preseason.Lots = this.form.controls.Lots.value;
    preseason.InspectedBy = this.form.controls.InspectedBy.value;
    preseason.ReviewedBy = this.form.controls.ReviewedBy.value;
    preseason.ReviewedDate = this.form.controls.ReviewedDate.value;
    preseason.WellWater = this.form.controls.WellWater.value;
    preseason.TerrainWater = this.form.controls.TerrainWater.value;
    preseason.SupplyWater = this.form.controls.SupplyWater.value;
    preseason.Other = this.form.controls.Other.value;
    preseason.OtherExplain = this.form.controls.OtherExplain.value;
    preseason.Water1 = this.form.controls.Water1.value;
    preseason.Water2 = this.form.controls.Water2.value;
    preseason.Water3 = this.form.controls.Water3.value;
    preseason.Soil1 = this.form.controls.Soil1.value;
    preseason.Soil2 = this.form.controls.Soil2.value;
    preseason.Soil2Explain = this.form.controls.Soil2Explain.value;
    preseason.Soil3 = this.form.controls.Soil3.value;
    preseason.Soil3Explain = this.form.controls.Soil3Explain.value;
    preseason.Soil4 = this.form.controls.Soil4.value;
    preseason.Soil4Explain = this.form.controls.Soil4Explain.value;
    preseason.Soil5 = this.form.controls.Soil5.value;
    preseason.Soil5Explain = this.form.controls.Soil5Explain.value;
    preseason.Environment1 = this.form.controls.Environment1.value;
    preseason.Environment1Explain = this.form.controls.Environment1Explain.value;
    preseason.Environment2 = this.form.controls.Environment2.value;
    preseason.Environment2Explain = this.form.controls.Environment2Explain.value;
    preseason.EnvironmentTemperature = this.form.controls.EnvironmentTemperature.value;
    preseason.EnvironmentPrecipitation = this.form.controls.EnvironmentPrecipitation.value;
    preseason.EnvironmentWind = this.form.controls.EnvironmentWind.value;
    preseason.Environment3 = this.form.controls.Environment3.value;
    preseason.Environment4 = this.form.controls.Environment4.value;
    preseason.EnvironmentCafo = this.form.controls.EnvironmentCafo.value;
    preseason.EnvironmentAmendments = this.form.controls.EnvironmentAmendments.value;
    preseason.EnvironmentGrazing = this.form.controls.EnvironmentGrazing.value;
    preseason.EnvironmentSeptic = this.form.controls.EnvironmentSeptic.value;
    preseason.EnvironmentProtection = this.form.controls.EnvironmentProtection.value;
    preseason.EnvironmentAreas = this.form.controls.EnvironmentAreas.value;
    preseason.EnvironmentCafoExplain1 = this.form.controls.EnvironmentCafoExplain1.value;
    preseason.EnvironmentCafoExplain2 = this.form.controls.EnvironmentCafoExplain2.value;
    preseason.EnvironmentCafoExplain3 = this.form.controls.EnvironmentCafoExplain3.value;
    preseason.EnvironmentCafoExplain4 = this.form.controls.EnvironmentCafoExplain4.value;
    preseason.Environment5 = this.form.controls.Environment5.value;
    preseason.EmployeeHygiene = this.form.controls.EmployeeHygiene.value;
    preseason.EmployeeHygieneExplain = this.form.controls.EmployeeHygieneExplain.value;
    preseason.Sanitation = this.form.controls.Sanitation.value;
    preseason.WaterComments = this.form.controls.WaterComments.value;
    preseason.SoilComments = this.form.controls.SoilComments.value;
    preseason.EnvironmentComments = this.form.controls.EnvironmentComments.value;
    preseason.EmployeeComments = this.form.controls.EmployeeComments.value;
    preseason.SanitationComments = this.form.controls.SanitationComments.value;
    preseason.AllergentsComments = this.form.controls.AllergentsComments.value;


    preseason.Preseason = this.isPreSeason;
    try{
      let result:any = await this.service.Create(preseason);
      this.cancelModal(result);
    }catch(err){
      console.log(err);
    }



  }


}
