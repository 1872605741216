import { Component, OnInit, Output } from '@angular/core';
import { UserService } from '../user.service';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import User from '../../../models/user.model';

export class UserAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-user-modal-add',
  templateUrl: './user-modal-add.component.html',
  styleUrls: ['./user-modal-add.component.scss']
})
export class UserModalAddComponent implements OnInit {

  public matcher:UserAddErrorStateMatcher;

  constructor(private service: UserService, public dialogRef: MatDialogRef<UserModalAddComponent>) {}

  ngOnInit(): void {}

  public roles = [
    {id: 1, role: 'Admin'},
    {id: 2, role: 'App User'},
    {id: 3, role: 'Auditor'},
    {id: 4, role: 'Client Guest'},
    {id: 5, role: 'Mechanic'},
    {id: 6, role: 'Trucker'},
  ];
  
  //Useradd LOGIC
  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    phone: new FormControl('', [Validators.minLength(8), Validators.maxLength(10)]),
    mail: new FormControl('', [Validators.email, Validators.maxLength(100)]),
    role: new FormControl(1, Validators.required),
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  //Function Para cerrar y crear modal
  public cancelModal = (result: any) => {
    this.dialogRef.close(result);
  }

  //Function para llenar USER Y PASS
  public generateUsername = () => {
    this.form.controls['username'].setValue(
      `${this.form.controls['name'].value.substring(0, 2)}${this.form.controls['lastName'].value.substring(0, 1)}${(moment(Date.now()).date() * 10)+(moment(Date.now()).month()+1)}`
    );
    this.form.controls['username'].disable();
  }

  public generatePassword = () => {
    this.form.controls['password'].setValue(
      `bogg${(moment(Date.now()).date() * 10)+(moment(Date.now()).month()+1)}`
    );
    this.form.controls['password'].disable();
  }

  public create = async() => {

    let user: User = new User();

    user.Name = this.form.controls.name.value;
    user.LastName = this.form.controls.lastName.value;
    user.Phone = this.form.controls.phone.value;
    user.Mail = this.form.controls.mail.value;
    user.Role = this.form.controls.role.value;
    user.Username = this.form.controls.username.value;
    user.Password = this.form.controls.password.value;

    let result:any = await this.service.Create(user);

    // HAY QUE MANEJAR ERROR
    if(result.error)
      return;
    
    this.cancelModal(result[0]);
  }
}
