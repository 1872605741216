import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ts, ActiveUser } from '../../app.shared';
import User from '../../models/user.model';
import { environment } from '../../../environments/environment';
import { Login } from './login.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _s: string;
  private _us: User;
  private uri:string = environment.URI;

  constructor(private http:HttpClient, private s:ts, private activeUser:ActiveUser) {
    s.getValue().subscribe((value)=> {
      this._s = value;
    });
    activeUser.getValue().subscribe((value)=> {
        this._us = value;
    })
  }

  public login = (user: Login):Promise<any> => {
    return new Promise((resolve, reject) => {
        this.http.post<any>(`${this.uri}/api/auth`, user, {observe:'response'}).subscribe(
            success => {
              this.s.setValue(success.headers.get('x-auth'));
              this.activeUser.setValue(success.body);
              resolve(success.body);
            }, 
            error => {
              reject({error: error});
        });
    });
  }
}
