<div class="container" id="modal">
    <mat-toolbar>
      <span class="fill-space"></span>
      <span>{{ history.Type }} Report</span>
      <span class="fill-space"></span>
      <button mat-icon-button (click)="cancelModal(null)"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
    <div class="detail">
      <h1>Main information</h1>
      <div>
        <mat-form-field>
            <mat-label>HF</mat-label>
            <input matInput [value]="history.HF" disabled>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Status</mat-label>
            <input matInput [value]="history.Status_Name" disabled>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
            <mat-label>Description</mat-label>
            <input matInput [value]="history.Description" disabled>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
            <mat-label>Reported date</mat-label>
            <input matInput [value]="history.Date_Create_Formatted" disabled>
        </mat-form-field>
        <mat-form-field *ngIf="!history.Status">
          <mat-label>Finish date</mat-label>
          <input matInput [value]="history.Date_Finish_Formatted" disabled>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
            <mat-label>Reported by</mat-label>
            <input matInput [value]="history.Reported_By" disabled>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Assigned mechanic</mat-label>
          <input matInput [value]="history.Assigned_Mechanic" disabled>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field>
            <mat-label>Hours used before report</mat-label>
            <input matInput [value]="history.Hours_Used" disabled>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Hours spend in repairment</mat-label>
          <input matInput [value]="history.Time_Since_Repair" disabled>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <h2>Repairs / Upgrades</h2>
      <div>
        <mat-list *ngIf="history.Bullets.length > 0">
          <mat-list-item *ngFor="let bullet of history.Bullets; let i = index">{{ i+1 }}.- {{ bullet.Description }}</mat-list-item>
        </mat-list>
      </div>
    </div>
    <footer class="dialog-footer">
      <div class="button-row">
        <button mat-raised-button color="secondary" (click)="cancelModal(null)">Close</button>
      </div>
    </footer>
  </div>
