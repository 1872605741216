import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import User from '../../models/user.model';
import DataTable from '../../components/table/table.model';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
//cancela el padding forzado del dialog
import { ViewEncapsulation } from '@angular/core';

import { UserModalAddComponent } from './user-modal-add/user-modal-add.component';
import { UserModalEditComponent } from './user-modal-edit/user-modal-edit.component';
import { UserModalDeleteComponent } from './user-modal-delete/user-modal-delete.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  //cancela el padding forzado del dialog
  encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit {

  public users: Array<User> = [];
  public dataTable: DataTable = new DataTable();

  constructor(private userService:UserService, public dialog: MatDialog) { }

  async ngOnInit() {
    await this.get();
    this.configTable();
  }

  private get = async() => {
    try {
      this.users = await this.userService.Get();
    }catch(error){
      console.log(error);
    }
  }

  private configTable = () => {
    // this.dataTable.Headers.push('Id');
    this.dataTable.Headers.push('Name');
    this.dataTable.Headers.push('LastName');
    this.dataTable.Headers.push('Username');
    this.dataTable.Headers.push('Phone');
    this.dataTable.Headers.push('Mail');
    this.dataTable.Headers.push('Status');
    this.dataTable.Headers.push('Actions');
  }

  // UPDATE --------------->
  public update = (event: {data: User, index: number}) => {
    this.modalEdit(event);
  }

  // DELETE --------------->
  public delete = (event: {dataId: number, index: number, secundaryId: number}) => {
    this.modalDelete(event);
  }

  //Open Modal Create
  public modalAdd = () => {
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    let dialogRef = this.dialog.open(UserModalAddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.users.concat(result);
        this.users = newArray;
      }
    });
  }

  //Open Modal Edit
  private modalEdit = (event: {data: User, index: number}) => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {user: event.data};
    let dialogRef = this.dialog.open(UserModalEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.users;
        newArray.map(user => {
          if(user.Id === result.Id){
            user.IdGrower = result.IdGrower;
            user.IdRanch = result.IdRanch;
            user.Name = result.Name;
            user.LastName = result.LastName;
            user.Phone = result.Phone;
            user.Mail = result.Mail;
            user.Status = result.Status;
            user.Username = result.Username;
            user.Password = result.Password;
            user.Role = result.Role;
          }
        })
        this.users = newArray;
      }
    });
  }

  // Open Modal Delete
  private modalDelete = (event: {dataId: number, index: number, secundaryId: number}) => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {userId: event.dataId, loginId: event.secundaryId};
    let dialogRef = this.dialog.open(UserModalDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.users.filter((x, i)=> i != event.index);
        this.users = newArray;
      }
    });
  }
}
