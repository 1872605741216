import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../user.service';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import User from '../../../models/user.model';

export class UserAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-user-modal-edit',
  templateUrl: './user-modal-edit.component.html',
  styleUrls: ['./user-modal-edit.component.scss'],
})
export class UserModalEditComponent implements OnInit {

  public matcher:UserAddErrorStateMatcher;

  constructor(private service: UserService, public dialogRef: MatDialogRef<UserModalEditComponent>, @Inject(MAT_DIALOG_DATA) public data: {user: User}, private userService: UserService) { 
    this.matcher = new UserAddErrorStateMatcher();
  }

  ngOnInit(): void {
    this.form.controls.name.setValue(this.data.user.Name);
    this.form.controls.lastName.setValue(this.data.user.LastName);
    this.form.controls.phone.setValue(this.data.user.Phone);
    this.form.controls.mail.setValue(this.data.user.Mail);
    this.form.controls.role.setValue(this.data.user.Role);
    this.form.controls.username.setValue(this.data.user.Username);
    this.form.controls.password.setValue(this.data.user.Password);
  }

  public roles = [
    {id: 1, role: 'Admin'},
    {id: 2, role: 'App User'},
    {id: 3, role: 'Auditor'},
    {id: 4, role: 'Client Guest'},
    {id: 5, role: 'Mechanic'},
    {id: 6, role: 'Trucker'}
  ];
  
  //UserEdit LOGIC
  public form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(3)]),
    lastName: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    phone: new FormControl('', [Validators.minLength(8), Validators.maxLength(10)]),
    mail: new FormControl('', [Validators.email, Validators.maxLength(100)]),
    role: new FormControl(1, Validators.required),
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  //Function Para cerrar y crear modal
  public cancelModal = (user: User = null) => {
    this.dialogRef.close(user);
  }

  public edit = async() => {

    let user: User = new User();

    user.Id = this.data.user.Id;
    user.IdGrower = this.data.user.IdGrower;
    user.IdRanch = this.data.user.IdRanch;
    user.LoginId = this.data.user.LoginId;
    user.Name = this.form.controls.name.value;
    user.LastName = this.form.controls.lastName.value;
    user.Phone = this.form.controls.phone.value;
    user.Mail = this.form.controls.mail.value;
    user.Role = this.form.controls.role.value;
    user.Username = this.form.controls.username.value;
    user.Password = this.form.controls.password.value;
    user.Status = true;

    let result: any = await this.userService.Edit(user);
    
    if(result.error){
      console.log('ERROR');
      return;
    }

    this.cancelModal(result);
  }
}
