import { Component, OnInit } from '@angular/core';
import { MachinesAndEquipmentService } from '../machines-component/machines-component.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateMachineV2Component } from './create-machine-v2/create-machine-v2.component';
import { DeleteMachineV2Component } from './delete-machine-v2/delete-machine-v2.component';
import { MachinesReportsV2Component } from './machines-reports-v2/machines-reports-v2.component';

@Component({
  selector: 'app-machines-v2',
  templateUrl: './machines-v2.component.html',
  styleUrls: ['./machines-v2.component.scss'],
})
export class MachinesV2Component implements OnInit {

  public machineList: any[] = [];
  public loading: boolean = false;
  public filter: string = '';
  public filteredReports: any[] = [];
  public hf: any = '';
  public showDetails: boolean = false;
  public showDetailBullets: boolean = false;
  public bulletsList: any[] = [];

  constructor(private services: MachinesAndEquipmentService, public dialog: MatDialog) { }

  ngOnInit(): void {
    document.getElementById('focus-me').focus();
  }

  public getData = async() => {
    this.loading = true;
    this.showDetails = false;
    this.hf = '';
    this.filteredReports = [];
    let result = await this.services.GetV2(this.filter);
    result.map((machine: any) => {
      machine.activeReports =  this.getActiveReports(machine.reports);
      machine.correctiveReports =  this.getCorrectiveReports(machine.reports);
      machine.preventiveReports =  this.getPreventiveReports(machine.reports);
      machine.predictiveReports =  this.getPredictibleReports(machine.reports);
      machine.useHours =  this.getTotalUseHours(machine.reports);
    })
    this.machineList = result;
    this.hf = this.machineList[0].hf;
    this.loading = false;
  }

  public getActiveReports = (reports: any) => {
    let count = 0;
    if(reports.length > 0) {
      reports.map((report: any) => {
        if(report.Status) count++;
      })
    }
    return count;
  }

  public getCorrectiveReports = (reports: any) => {
    let count = 0;
    if(reports.length > 0) {
      reports.map((report: any) => {
        if(report.Type_Id === 1) count++;
      })
    }
    return count;
  }

  public getPreventiveReports = (reports: any) => {
    let count = 0;
    if(reports.length > 0) {
      reports.map((report: any) => {
        if(report.Type_Id === 2) count++;
      })
    }
    return count;
  }

  public getPredictibleReports = (reports: any) => {
    let count = 0;
    if(reports.length > 0) {
      reports.map((report: any) => {
        if(report.Type_Id === 3) count++;
      })
    }
    return count;
  }

  public getTotalUseHours = (reports: any) => {
    let count = 0;
    
    if(reports.length === 0) return 0;
    
    let filteredData = reports.filter(x => x.Hours_Used !== null);

    if(filteredData.length === 0) return 0;

    filteredData.sort((a: any, b: any) => a.Hours_Used - b.Hours_Used).map((report: any, index: number) => {
      if((index + 1) === filteredData.length) {
        count = report.Hours_Used;
      }
    })
    return count;
  }

  public showReports = (hf: any, reports: any, type: number) => {
    if(reports.length === 0) return;
    let reportsToShow = [];

    switch(type) {
      case 0: reportsToShow = reports.filter(x => x.Status); break;
      case 1: reportsToShow = reports.filter(x => x.Type_Id === 1); break;
      case 2: reportsToShow = reports.filter(x => x.Type_Id === 2); break;
      case 3: reportsToShow = reports.filter(x => x.Type_Id === 3); break;
      default: break;
    }

    if(reportsToShow.length === 0) return;
    this.hf = hf;
    this.filteredReports = reportsToShow;
    this.showDetails = true;
  }
  
  public showCreate = async() => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      machine: null
    };
    let dialogRef = this.dialog.open(CreateMachineV2Component, dialogConfig);
  
    dialogRef.afterClosed().subscribe(async(result) => {
      // if(result != null && !result.error){
      //   let newArray = this.machinesAndEquipment.concat(result);
      //   this.machinesAndEquipment = newArray;
      // }
    });
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.width = "60%";
    // dialogConfig.data = {
      //   machine: null
      // };
      // let dialogRef = this.dialog.open(CreateMachineV2Component, dialogConfig);
      
      // dialogRef.afterClosed().subscribe(async(result) => {
        //   // if(result != null && !result.error){
          //   //   let newArray = this.machinesAndEquipment.concat(result);
          //   //   this.machinesAndEquipment = newArray;
          //   // }
          // });
          
        }
        
public showDetail = async(machine: any) => {
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {
      machine
    };
    let dialogRef = this.dialog.open(CreateMachineV2Component, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      // if(result != null && !result.error){
      //   let newArray = this.machinesAndEquipment.concat(result);
      //   this.machinesAndEquipment = newArray;
      // }
    });
  }

  public deleteMachine = async(machine_id: number, softDelete: boolean) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      machine_id,
      softDelete
    };
    let dialogRef = this.dialog.open(DeleteMachineV2Component, dialogConfig);
  
    dialogRef.afterClosed().subscribe(async(result) => {
      // if(result != null && !result.error){
      //   let newArray = this.machinesAndEquipment.concat(result);
      //   this.machinesAndEquipment = newArray;
      // }
    });
  }

  public showBullets = (e: any) => {
    this.showDetailBullets = true;
    this.bulletsList = e;
    console.log(e)
  }
}
