<div class="container" id="modal">
  <mat-toolbar>
    <span class="fill-space"></span>
    <span>Create User</span>
    <span class="fill-space"></span>
    <button mat-icon-button (click)="cancelModal(null)"><mat-icon>close</mat-icon></button>
  </mat-toolbar>

  <form [formGroup]="form" class="modalForm" (change)="generateUsername(); generatePassword();">

    <div class="personalInfo">
      <h2>Personal Information</h2>
    </div>

    <mat-divider></mat-divider>

    <div class="block1">
      <mat-form-field>
        <input type="text" formControlName="name" matInput placeholder="Full Name*" >
        <mat-error *ngIf="form.controls['name'].errors?.required">Full name is required</mat-error>
        <mat-error *ngIf="form.controls['name'].errors?.maxLength">Name cannot contain more than 50 letters</mat-error>
        <mat-error *ngIf="form.controls['name'].errors?.minLength">Name cannot contain less than 3 letters</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input type="text" formControlName="lastName" matInput placeholder="Last Name*">
        <mat-error>Last name is required</mat-error>
        <mat-error *ngIf="form.controls['lastName'].errors?.maxLength">Last name cannot contain more than 50 letters</mat-error>
      </mat-form-field>
    </div>

    <div class="block2">
      <mat-form-field>
        <input type="number" formControlName="phone" matInput placeholder="Phone" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['phone'].errors?.minLength">At least 8 digits are required</mat-error>
        <mat-error *ngIf="form.controls['phone'].errors?.maxLength">No more than 8 digits are required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input type="mail" formControlName="mail" matInput placeholder="E-mail" [errorStateMatcher]="matcher">
        <mat-error>Invalid e-mail adress</mat-error>
        <mat-error *ngIf="form.controls['mail'].errors?.maxLength">E-mail cannot contain more than 100 characters</mat-error>
      </mat-form-field> 
    </div>
            
    <div class="block3">
      <mat-form-field>  
        <mat-select formControlName="role" placeholder="Role" style="width: 100%;" [errorStateMatcher]="matcher">
          <mat-option>None</mat-option>
          <ng-container *ngFor="let role of roles">
              <mat-option value={{role.id}}>{{role.role}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
      
    <div class="formCredential">
      <h2>Credential Information</h2>
    </div>

    <mat-divider></mat-divider>

    <div class="block4">
      <mat-form-field appearance="legacy">
        <mat-label>Username*</mat-label>
        <input type="text" formControlName="username" matInput placeholder="Username*" [formControl]="form.controls['username']" [errorStateMatcher]="matcher">
        <mat-hint>Double Click to auto generate</mat-hint>
        <mat-error>Username is required</mat-error>
      </mat-form-field>
    </div>

    <div class="block5">
      <mat-form-field appearance="legacy">
        <mat-label>Password*</mat-label>
        <input type="text" formControlName="password" matInput placeholder="Password*" (click)="generatePassword()" [formControl]="form.controls['password']" [errorStateMatcher]="matcher">
        <mat-hint>Double Click to auto generate</mat-hint>
        <mat-error>Password is required</mat-error>
      </mat-form-field> 
    </div>
  </form>

  <footer class="dialog-footer">
    <div class="button-row">
      <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="create()">Create</button>
      <button mat-raised-button color="secondary" (click)="cancelModal(null)">Cancel</button>
    </div>
  </footer>
</div>
