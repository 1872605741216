import { Injectable } from '@angular/core';
import { ts, ActiveUser } from '../../../app.shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import User from '../../../models/user.model';
import Nouca from './nouca.model';

@Injectable({
  providedIn: 'root'
})
export class NoucaService {

  private uri:string = environment.URI;
  private _s: string;
  private _us: User;

  constructor(private http:HttpClient, private s:ts, private activeUser:ActiveUser) {
    s.getValue().subscribe((value)=> {
      this._s = value;
    });
    activeUser.getValue().subscribe((value)=> {
        this._us = value;
    })
  }

  public Get = ():Promise<Array<Nouca>> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.get<Array<Nouca>>(`${this.uri}/api/nouca`, {headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          },
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
  public Create = (nouca:Nouca):Promise<Nouca> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.post<Nouca>(`${this.uri}/api/nouca`, nouca,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          },
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public Edit = (nouca:Nouca):Promise<Nouca> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.put<Nouca>(`${this.uri}/api/nouca`, nouca,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          },
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
  public Delete = (noucaId: number):Promise<Array<Nouca>> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.delete<Array<Nouca>>(`${this.uri}/api/nouca?id=${noucaId}`, {headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          },
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }


}
