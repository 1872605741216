import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RanchesModalAddComponent } from './ranches-modal-add/ranches-modal-add.component';
import { ViewEncapsulation } from '@angular/core';
import { RanchesModalEditComponent } from './ranches-modal-edit/ranches-modal-edit.component';
import { RanchesModalDeleteComponent } from './ranches-modal-delete/ranches-modal-delete.component';
import { Router, ActivatedRoute } from '@angular/router';
import DataTable from '../../components/table/table.model';
import { Ranch } from './ranches.model';
import { RanchesService } from './ranches.service';

// const dataDummy = [{
//   Id: 1,
//   GrowerId: 1,
//   Name: 'Algún Grower',
//   Location: 'alguna cordenada',
//   Status: 1
// }]

@Component({
  selector: 'app-ranches',
  templateUrl: './ranches.component.html',
  styleUrls: ['./ranches.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RanchesComponent implements OnInit {

  private initId: number = 0;
  public ranches: Array<Ranch> = [];
  public dataTable: DataTable = new DataTable();

  constructor(public dialog: MatDialog,private ranchesService: RanchesService, private router: Router, private activeRouter: ActivatedRoute) { }

  async ngOnInit() {
    try {
      this.initId = parseInt(this.activeRouter.snapshot.paramMap.get('id'));
    }catch(error) {
      this.back();
    }
    await this.get();
    this.configTable();
  }

  private get = async() => {
    try {
      this.ranches = await this.ranchesService.Get(this.initId);
    }catch(error){
      console.log(error);
    }
  }

  private configTable = () => {
    this.dataTable.Headers.push('Name');
    this.dataTable.Headers.push('Location');
    this.dataTable.Headers.push('Status');
    this.dataTable.Headers.push('Actions');
  }

  // UPDATE --------------->
  public update = (event: {data: Ranch, index: number}) => {
    this.modalEdit(event);
  }

  // DELETE --------------->
  public delete = (event: {dataId: number, index: number}) => {
    this.modalDelete(event);
  }

  //Open Modal Create
  public modalAdd = () => {
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {growerId: this.initId};
    let dialogRef = this.dialog.open(RanchesModalAddComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.ranches.concat(result);
        this.ranches = newArray;
      }
    });
  }

  //Open Modal Edit
  private modalEdit = (event: {data: Ranch, index: number}) => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = {ranch: event.data};
    let dialogRef = this.dialog.open(RanchesModalEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.ranches;
        newArray.map(ranch => {
          if(ranch.Id === result.Id){
            ranch.IdGrower = result.IdGrower;
            ranch.Name = result.Name;
            ranch.Location = result.Location;
            ranch.Status = result.Status;
          }
        })
        this.ranches = newArray;
      }
    });
  }

  // Open Modal Delete
  private modalDelete = (event: {dataId: number, index: number}) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {ranchId: event.dataId, growerId: this.initId};
    let dialogRef = this.dialog.open(RanchesModalDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        let newArray = this.ranches.filter((x, i)=> i != event.index);
        this.ranches = newArray;
      }
    });
  }

  public back = () => {
    this.router.navigate(['growers']);
  }
}
