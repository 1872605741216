import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Ranch } from '../ranches.model';
import { RanchesService } from '../ranches.service';
@Component({
  selector: 'app-ranches-modal-edit',
  templateUrl: './ranches-modal-edit.component.html',
  styleUrls: ['./ranches-modal-edit.component.scss']
})
export class RanchesModalEditComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RanchesModalEditComponent>, @Inject(MAT_DIALOG_DATA) public data: {ranch: Ranch}, private service: RanchesService) { }

  ngOnInit(): void {
    this.form.controls.name.setValue(this.data.ranch.Name);
    this.form.controls.location.setValue(this.data.ranch.Location);
  }

  public form: FormGroup = new FormGroup({
    name: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
    location: new FormControl('', Validators.maxLength(500)),
  });
  
  public cancelModal = (data: any = null) => {
    this.dialogRef.close(data);
  }
  
  public onEdit = async() => {

    let ranches = new Ranch();
    
    ranches.Id = this.data.ranch.Id;
    ranches.IdGrower = this.data.ranch.IdGrower;
    ranches.Name = this.form.controls.name.value;
    ranches.Location = this.form.controls.location.value;

    let result: any = await this.service.Edit(ranches);
    
    if(result.error){
      return;
    }
    this.cancelModal(result);
  }
}
