export class History_Report {
    public Id: number;
    public Machine_Id: number;
    public HF: string;
    public Type_Id: number;
    public Type: string;
    public Description: string;
    public Reported_By: string;
    public Assigned_Mechanic: string;
    public Work_Order?: string = "";
    public Repairs_Order?: string = "";
    public Hours_Used: number;
    public Washed?: boolean = false;
    public Time_Since_Repair?: number = 0;
    public Date_Create: Date;
    public Date_Finish: Date;
    public Status: boolean;
    public Bullets: Array<Bullets> = [];

    // INFO
    public Status_Name?: string = "";
    public Date_Create_Formatted?: string = "";
    public Date_Finish_Formatted?: string = "";
}

export class Bullets {
    public Id: number;
    public Report_Id: number;
    public Description: string;
}