import { Component, OnInit } from '@angular/core';
import { CustomReportService } from './custom-report.service';
import { CreateCustomReportComponent } from './create-custom-report/create-custom-report.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteCustomReportComponent } from './delete-custom-report/delete-custom-report.component';

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['./custom-reports.component.scss']
})
export class CustomReportsComponent implements OnInit {

  public reportsDaily: any[] = [];
  public reportsPeriodic: any[] = [];

  constructor(private service: CustomReportService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.get();
  }

  private get = async() => {
    const result = await this.service.getData();
    this.reportsDaily = result.filter(x => x.Type === false);
    this.reportsPeriodic = result.filter(x => x.Type === true);
  }

  public openCreateModal = async() => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    dialogConfig.data = {
      report: null
    };
    let dialogRef = this.dialog.open(CreateCustomReportComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){

        if(result.Type) {
          let newArray = this.reportsPeriodic.concat(result);
          this.reportsPeriodic = newArray;
        } else {
          let newArray = this.reportsDaily.concat(result);
          this.reportsDaily = newArray;
        }
      }
    });
  }

  public openEditModal = async(report: any, type: boolean) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    dialogConfig.data = {
      report
    };
    let dialogRef = this.dialog.open(CreateCustomReportComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
      //   let newArray = type ? this.reportsPeriodic : this.reportsDaily;
      //   await Promise.all(
      //     newArray.map(async(report) => {
      //       if(report.Id === result.Id){
      //         report.Title = report.title;
      //         report.Type = report.typeName;
      //         report.Status = report.status;
      //         await Promise.all(
      //           report.questions.map((question, i) => {
      //             question.Id = question.id;
      //             question.Custom_Form_Head_Id = question.customFormHead;
      //             question.Custom_Form_Input_Id = question.inputType;
      //             question.Question = question['question'+i];
      //             question.Translation = question['translation'+i];
      //           })
      //         )
      //       }
      //     })
      //   )
      //   if(type)
      //     this.reportsPeriodic = newArray;
      //   else
      //     this.reportsDaily = newArray;
        
        this.get();
      }
    });
  }

  public openDeleteModal = async(periodic: any, soft: boolean) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      report_id: periodic.Id,
      soft
    };
    let dialogRef = this.dialog.open(DeleteCustomReportComponent, dialogConfig);
  
    dialogRef.afterClosed().subscribe(async(result) => {
      if(result != null && !result.error){
        periodic.Status =! periodic.Status;
      }
    });
  }

  public showInput = (input_id: number) => {
    switch(input_id) {
      case 1: return 'Switch';
      case 2: return 'Text';
      case 3: return 'Number';
    }
  }
}
