import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActiveUser } from '../../../app.shared';
import User from '../../../models/user.model';

const admin = [
  {icon: 'home', name: 'Home', route: './'},
  {icon: 'perm_identity', name: 'Users', route: './users'},
  {icon: 'spa', name:'Growers', route: './growers'},
  {icon: 'group', name:'Contractors', route: './contractors'},
  {icon: 'import_contacts', name:'Reports', route:'./reports-v2'},
  {icon: 'apps', name:'Custom reports', route:'./custom-reports'},
  // {icon: 'call_to_action', name:'Reports legacy', route:'./reports'},
  {icon: 'cloud_upload', name:'Upload Files', route:'./upload'},
  {icon: 'library_books', name:'Forms', route:'./forms'},
  {icon: 'agriculture', name:'Machines & Equipment', route:'./machines-equipment-v2'},
];



const appUser = [
  {icon: 'agriculture', name:'Machines & Equipment', route:'./machines-equipment-v2-app'},
];
const auditor = [
  {icon: 'import_contacts', name:'Reports', route:'./reports-v2-aud'},
  {icon: 'spa', name:'Forms', route:'./forms-aud'},
  {icon: 'library_books', name: 'Files', route: './file-viewer-aud'}
];
const guest = [
  {icon: 'library_books', name: 'Files', route: './file-viewer'}
];
const mechanic = [
  {icon: 'agriculture', name:'Machines & Equipment', route:'./machines-equipment-v2-mech'},
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements AfterViewInit, OnInit {
  private _us: User;
  public menu: Array<any> = [];

  @ViewChild('sidenav') sidenav: ElementRef;

  @Output() toggle = new EventEmitter();

  constructor(private activeUser:ActiveUser) {
    activeUser.getValue().subscribe((value)=> {
      this._us = value;
    });
  }

  ngOnInit(): void {
    this.setMenuByUserRole();
  }


  private setMenuByUserRole = () => {
    console.log(this._us.Role)
    switch(this._us.Role){
      case 1 : this.menu  = admin; break;
      case 2 : this.menu  = appUser; break;
      case 3 : this.menu  = auditor; break;
      case 4 : this.menu  = guest; break;
      case 5 : this.menu  = mechanic; break;
    }
  }

  ngAfterViewInit(): void {
    this.toggle.emit(this.sidenav);
  }
}
