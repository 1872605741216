import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chlorine_Inspection, Field_Assessment, Harvest_Machine, Machine_Equipment, Machine_Inspection, Report, Weight_Inspection } from '../../report.model';
import { ReportsV2Service } from '../../reports-v2.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-daily-modal-detail-v2',
  templateUrl: './daily-modal-detail-v2.component.html',
  styleUrls: ['./daily-modal-detail-v2.component.scss']
})
export class DailyModalDetailV2Component implements OnInit {

  public isValidating: boolean = false;
  public form: Report = new Report();
  public Code: string = "";
  public Comments: string = "";
  public validatedBy: string = "";
  public userLetters: string = "";
  public invalidCode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DailyModalDetailV2Component>,
    private service: ReportsV2Service,
    @Inject(MAT_DIALOG_DATA) private data: {form: Report}){
      this.form.chlorineInspections = new Array<Chlorine_Inspection>();
      this.form.weightInspections = new Array<Weight_Inspection>();
      this.form.fieldAssessment = new Field_Assessment();
      this.form.harvestMachine = new Harvest_Machine();
      this.form.machineEquipment = new Machine_Equipment();
      this.form.machineInspection = new Machine_Inspection();
    }

  ngOnInit(): void {

    this.form = this.data.form;
    this.form.Foreman = `${this.form.User_Name} ${this.form.User_Last_Name}`;
    this.validatedBy = `${this.form.Validated_Name} ${this.form.Validated_Last_Name}`;

    let userNameSplit = this.form.User_Name.split(' ');
    let userLastNameSplit = this.form.User_Last_Name.split(' ');

    this.userLetters = '';

    if(userNameSplit.length > 0) {
        for(let i = 0; i< userNameSplit.length; i++){
            this.userLetters += userNameSplit[i].substring(0, 1);
        }
    }
    if(userLastNameSplit.length > 0) {
        for(let i = 0; i< userLastNameSplit.length; i++){
            this.userLetters += userLastNameSplit[i].substring(0, 1);
        }
    }
  }

  public cancelModal = (result: any = null) => {
    this.dialogRef.close(result);
  }

  public validate = async() => {
    this.invalidCode = false;
    if(this.Code.trim().length == 0){
      this.Code = "";
      this.invalidCode = true;
      return;
    };
    
    let validateInfo = {
      Id: this.form.Id,
      User_Id: null,
      Comment_By_Validator: this.Comments,
      Validate_Date: new Date(),
      Code: this.Code
    };

    let result = await this.service.ValidateFormV2(validateInfo);
    
    if(result.error){
      this.Code = "";
      this.invalidCode = true;
    }else{
      this.invalidCode = false;
      this.cancelModal(result);
    }
  }

  public cancelValidate = () => {
    this.Code = "";
    this.Comments = "";
    this.isValidating = false;
  }

  public formatDate = (date) => {
    let dates = moment.utc(date).local().format().split('T');
    return `${dates[0]} ${dates[1].substring(0,5)}`;
  }

  public imageUrl = (id: any) => {
    return `${environment.STORAGE}/${id.toLowerCase()}.jpeg`
  }

  public formHarvestAnswer = (answer: any) => {
    if(answer == 2){
        return 'N/A';
    }else if(answer == 1){
        return 'Done';
    }else if(answer == 0){
        return 'Not Done'
    }else {
        return 'N/A';
    }
  }
  public formEquipmentAnswer = (answer: any) => {
    if(answer == 2){
        return 'N/A';
    }else if(answer == 1){
        return 'Done';
    }else if(answer == 0){
        return 'Not Done'
    }else {
        return 'N/A';
    }
  }

  public formFieldAnswer = (answer: any) => {
    if(answer == 2){
        return 'N/A';
    }else if(answer == 1){
        return 'Yes';
    }else if(answer == 0){
        return 'No'
    }else {
        return 'N/A';
    }
  }

  public handleMissingImage(event: Event, img: string) {
    (event.target as HTMLImageElement).src = `${environment.STORAGE}/${environment.CONTAINER}/${img.toLowerCase()}.jpeg`;
  }
}
