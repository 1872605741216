import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PreSeasonService } from '../../pre-season/pre-season.service';
import { PreHarvestModalEditComponent } from '../pre-harvest-modal-edit/pre-harvest-modal-edit.component';

@Component({
  selector: 'app-pre-harvest-modal-delete',
  templateUrl: './pre-harvest-modal-delete.component.html',
  styleUrls: ['./pre-harvest-modal-delete.component.scss']
})
export class PreHarvestModalDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PreHarvestModalDeleteComponent>, @Inject(MAT_DIALOG_DATA) public data: {preseasonId: number}, private service: PreSeasonService) { }

  ngOnInit(): void {
  }

  public cancelModal = (data) => {
    this.dialogRef.close(data);
  }

  public delete = async() => {
    let result: any = await this.service.Delete(this.data.preseasonId);
    if(!result.error)
      this.cancelModal(this.data.preseasonId);
  }

}
