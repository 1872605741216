<div class="container" id="modal">
  <mat-toolbar>
    <span class="fill-space"></span>
    <span>Create Ranch</span>
    <span class="fill-space"></span>
    <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  
  <form [formGroup]="form">
    <div class="identity-information">
      <h2>Identity Information</h2>
    </div>
    <mat-divider></mat-divider>

    <div class="block1">
      <mat-form-field>
        <mat-label>Ranch name</mat-label>
        <input matInput type="text" formControlName="name" placeholder="Ranch name*">
        <mat-error *ngIf="form.controls['name'].errors?.required">Ranch name is required</mat-error>
        <mat-error *ngIf="form.controls['name'].errors?.maxLength">Ranch name cannot contain more than 50 letters</mat-error>
        <mat-error *ngIf="form.controls['name'].errors?.minLength">Ranch name cannot contain less than 3 letters</mat-error>
      </mat-form-field>
    </div>
    <div class="location-information">
      <h2>Location Information</h2>
    </div>
    <mat-divider></mat-divider>
    <div class="block2">
      <mat-form-field>
        <mat-label>Location(GPS, physical location)</mat-label>
        <input matInput type="text" formControlName="location">
        <mat-error *ngIf="form.controls['location'].errors?.maxLength">Location cannot contain more than 500 characters</mat-error>        
      </mat-form-field>
    </div>
  </form>
  
  <footer class="dialog-footer">
    <div class="button-row">
      <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="onAdd()">Create</button>
      <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
    </div>
  </footer>
</div>
  
