import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WaterUsageService {
  private api: string = environment.URI;

  constructor(private http: HttpClient) { }

  public getAll = (userId: any, fromDate: any, toDate: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.api}/api/mobile/waterUsage?user_id=${userId}&date_start=${fromDate}&date_end=${toDate}`).subscribe({
        next: success => resolve(success),
        error: err => reject(err)
      });
    })
  }
}
