import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public reportRoutes = [
    {
      title: 'Daily Reports',
      ruote: 'daily',
      icon: 'assignment'
    },
    // {
    //   title: 'Weekly Reports',
    //   ruote: 'weekly',
    //   icon: 'bookmarks'
    // },
    // {
    //   title: 'Machine Reports',
    //   ruote: 'machine',
    //   icon: 'agriculture'
    // }
  ];

  constructor(private router:Router, private activeRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  public goTo = (r) => {
    this.router.navigate([r.ruote], { relativeTo: this.activeRoute });
  }

}
