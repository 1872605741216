<div class="container" id="modal">
    <mat-toolbar>
        <span class="fill-space"></span>
        <span>Daily Report Detail</span>
        <span class="fill-space"></span>
        <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
    <div class="detail" *ngIf="!isValidating">
        <h1>Main information</h1>
        <mat-divider></mat-divider>
        <div>
            <mat-form-field>
                <mat-label>Grower</mat-label>
                <input matInput [value]="form.Grower_Name" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Ranch</mat-label>
                <input matInput [value]="form.Ranch_Name" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Contractor name</mat-label>
                <input matInput [value]="form.Contractor_Name" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Contractor contact</mat-label>
                <input matInput [value]="form.Contractor_Contact" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Contact Name</mat-label>
                <input matInput [value]="form.Grower_Contact_Name" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Harvesting</mat-label>
                <input matInput [value]="form.Harvesting" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Field</mat-label>
                <input matInput [value]="form.Yard" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Field ID</mat-label>
                <input matInput [value]="form.Sub_Yard" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Harvest Crew</mat-label>
                <input matInput [value]="form.Squad" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Foreman</mat-label>
                <input matInput [value]="form.Foreman" disabled>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput [value]="form.Grower_Address" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Location</mat-label>
                <input matInput [value]="form.Ranch_Location" disabled>
            </mat-form-field>
        </div>
        <div *ngIf="form.Validated_By">
            <mat-form-field>
                <mat-label>Validated Date</mat-label>
                <input matInput [value]="form.Validate_Date" disabled>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Validated By</mat-label>
                <input matInput [value]="validatedBy" disabled>
            </mat-form-field>
        </div>
        <div *ngIf="form.Validated_By">
            <mat-form-field style="width: 98% !important; margin: auto auto">
                <mat-label>Validator comments</mat-label>
                <textarea matInput [value]="form.Comment_By_Validator" disabled></textarea>
            </mat-form-field>
        </div>
        <div *ngIf="form.harvestMachine">
            <h1>Form - Daily harvest crew and machine inspection</h1>
            <p>Done at {{ formatDate(form.harvestMachine.Date_Create) }}</p>
            <mat-divider></mat-divider>
            <table>
                <thead>
                    <tr>
                        <th>Question identify</th>
                        <th>Answer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Question - 1</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer1)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 2</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer2)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 3</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer3)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 4</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer4)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 5</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer5)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 6</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer6)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 7</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer7)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 8</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer8)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 9</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer9)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 10</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer10)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 11</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer11)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 12</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer12)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 13</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer13)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 14</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer14)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 15</td>
                        <td>{{formHarvestAnswer(form.harvestMachine.answer15)}}</td>
                    </tr>
                </tbody>
            </table>
            <p><b>Comments</b>: {{ form.harvestMachine.Comment }}</p>
            <p><b>Evidences</b>: </p>
            <div *ngIf="form.harvestMachine.images.length > 0">
                <figure *ngFor="let image of form.harvestMachine.images">
                    <img [src]="imageUrl(image.Image_Url)" (error)="handleMissingImage($event, image.Image_Url)"/>
                </figure>
            </div>
    
            <mat-divider></mat-divider>
        </div>
        <div *ngIf="form.machineEquipment">
            <h1>Form - Harvest machine and equipment cleaning and sanitation</h1>
            <p>Done at {{ formatDate(form.machineEquipment.Date_Create) }}</p>
            <mat-divider></mat-divider>
            <table>
                <thead>
                    <tr>
                        <th>Question identify</th>
                        <th>Answer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Question - 1</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer1)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 2</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer2)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 3</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer3)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 4</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer4)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 5</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer5)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 6</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer6)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 7</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer7)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 8</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer8)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 9</td>
                        <td>{{formEquipmentAnswer(form.machineEquipment.answer9)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 10</td>
                        <td>{{form.machineEquipment.answer10}}</td>
                    </tr>
                    <tr>
                        <td>Question - 11</td>
                        <td>{{userLetters}} - {{formEquipmentAnswer(form.machineEquipment.answer11)}}</td>
                    </tr>
                </tbody>
            </table>
            <p><b>Comments</b>: {{ form.machineEquipment.Comment }}</p>
            <p><b>Evidences</b>: </p>
            <div *ngIf="form.machineEquipment.images.length > 0">
                <figure *ngFor="let image of form.machineEquipment.images">
                    <img [src]="imageUrl(image.Image_Url)" (error)="handleMissingImage($event, image.Image_Url)"/>
                </figure>
            </div>
    
            <mat-divider></mat-divider>
        </div>
        <div *ngIf="form.machineInspection">
            <h1>Form - Machine inspection</h1>
            <p>Done at {{ formatDate(form.machineInspection.Date_Create) }}</p>
            <mat-divider></mat-divider>
            <table>
                <thead>
                    <tr>
                        <th>Question identify</th>
                        <th>Answer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Question - 1</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer1)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 2</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer2)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 3</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer3)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 4</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer4)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 5</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer5)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 6</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer6)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 7</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer7)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 8</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer8)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 9</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer9)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 10</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer10)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 11</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer11)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 12</td>
                        <td>{{formHarvestAnswer(form.machineInspection.answer12)}}</td>
                    </tr>
                </tbody>
            </table>
            <p><b>Comments</b>: {{ form.machineInspection.Comment }}</p>
            <p><b>Evidences</b>: </p>
            <div *ngIf="form.machineInspection.images.length > 0">
                <figure *ngFor="let image of form.machineInspection.images">
                    <img [src]="imageUrl(image.Image_Url)" (error)="handleMissingImage($event, image.Image_Url)"/>
                </figure>
            </div>
            
            <mat-divider></mat-divider>
        </div>
        
        <div *ngIf="form.fieldAssessment">

            <h1>Form - Daily harvest field assessment</h1>
            <p>Done at {{ formatDate(form.fieldAssessment.Date_Create) }}</p>
            <mat-divider></mat-divider>
            <table>
                <thead>
                    <tr>
                        <th>Question identify</th>
                        <th>Answer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Question - 1</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer1)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 2</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer2)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 3</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer3)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 4</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer4)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 5</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer5)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 6</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer6)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 7</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer7)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 8</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer8)}}</td>
                    </tr>
                    <tr>
                        <td>Question - 9</td>
                        <td>{{formFieldAnswer(form.fieldAssessment.answer9)}}</td>
                    </tr>
                </tbody>
            </table>
            <p><b>Comments</b>: {{ form.fieldAssessment.Comment }}</p>
            <p><b>Evidences</b>: </p>
            <div *ngIf="form.fieldAssessment.images.length > 0">
                <figure *ngFor="let image of form.fieldAssessment.images">
                    <img [src]="imageUrl(image.Image_Url)" (error)="handleMissingImage($event, image.Image_Url)"/>
                </figure>
            </div>
    
            <mat-divider></mat-divider>
        </div>

        <div *ngIf="form.chlorineInspections">

            <h1>Form - Chlorine inspection</h1>
            <h2>Inspections - {{form.chlorineInspections.length}}</h2>
            <div *ngFor="let chlorine of form.chlorineInspections; let i = index;">
                <h3>Form - {{ (i + 1) }}</h3>
                <p>Done at {{ formatDate(chlorine.Date_Create) }}</p>
                <mat-divider></mat-divider>
                <table>
                    <thead>
                        <tr>
                            <th>Question identify</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Question - 1</td>
                            <td>{{chlorine.answer1}}</td>
                        </tr>
                        <tr>
                            <td>Question - 2</td>
                            <td>{{chlorine.answer2}}</td>
                        </tr>
                        <tr>
                            <td>Question - 3</td>
                            <td>{{formHarvestAnswer(chlorine.answer3)}}</td>
                        </tr>
                    </tbody>
                </table>
                <p><b>Comments</b>: {{ chlorine.Comment }}</p>
                <p><b>Evidences</b>: </p>
                <div *ngIf="chlorine.images.length > 0">
                    <figure *ngFor="let image of chlorine.images">
                        <img [src]="imageUrl(image.Image_Url)" (error)="handleMissingImage($event, image.Image_Url)"/>
                    </figure>
                </div>
            </div>
    
            <mat-divider></mat-divider>
        </div>
        <div *ngIf="form.weightInspections">
            <h1>Form - Weight inspection</h1>
            <h2>Inspections - {{form.weightInspections.length}}</h2>
            <div *ngFor="let weight of form.weightInspections; let i = index;">
                <h3>Form - {{ (i + 1) }}</h3>
                <p>Done at {{ formatDate(weight.Date_Create) }}</p>
                <mat-divider></mat-divider>
                <table>
                    <thead>
                        <tr>
                            <th>Question identify</th>
                            <th>Answer</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Question - 1</td>
                            <td>{{weight.answer1}}</td>
                        </tr>
                        <tr>
                            <td>Question - 2</td>
                            <td>{{weight.answer2}}</td>
                        </tr>
                        <tr>
                            <td>Question - 3</td>
                            <td>{{weight.answer3}}</td>
                        </tr>
                        <tr>
                            <td>Question - 4</td>
                            <td>{{weight.answer4}}</td>
                        </tr>
                        <tr>
                            <td>Question - 5</td>
                            <td>{{weight.answer5}}</td>
                        </tr>
                        <tr>
                            <td>Question - 6</td>
                            <td>{{weight.answer6}}</td>
                        </tr>
                        <tr>
                            <td>Question - 7</td>
                            <td>{{weight.answer7}}</td>
                        </tr>
                        <tr>
                            <td>Question - 8</td>
                            <td>{{weight.answer8}}</td>
                        </tr>
                        <tr>
                            <td>Question - 9</td>
                            <td>{{weight.answer9}}</td>
                        </tr>
                        <tr>
                            <td>Question - 10</td>
                            <td>{{weight.answer10}}</td>
                        </tr>
                    </tbody>
                </table>
                <p><b>Comments</b>: {{ weight.Comment }}</p>
                <p><b>Evidences</b>: </p>
                <div *ngIf="weight.images.length > 0">
                    <figure *ngFor="let image of weight.images">
                        <img [src]="imageUrl(image.Image_Url)" (error)="handleMissingImage($event, image.Image_Url)"/>
                    </figure>
                </div>
            </div>
        </div>
    </div>
    <div class="detail" *ngIf="isValidating">
        <div class="center-mat">
            <mat-form-field style="margin-top: 80px; width: 20% !important;">
                <mat-label>Validate Code</mat-label>
                <input matInput  type="password" [(ngModel)]="Code" style="text-align: center;"/>
            </mat-form-field>
            <p *ngIf="invalidCode" style="text-align: center;color:rgb(219, 54, 54)">Invalid code, please try again</p>
        </div>
        <div class="center-mat">
            <mat-form-field>
                <mat-label>Comments</mat-label>
                <textarea matInput placeholder="Ex. It needs to be..." [(ngModel)]="Comments"></textarea>
            </mat-form-field>
        </div>
    </div>
    <footer class="dialog-footer">
        <div class="button-row">
            <div *ngIf="!isValidating">
                <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
                <button mat-raised-button color="primary" (click)="isValidating = true;" [disabled]="form.Validated_By > 0">Validate</button>
            </div>
            <div *ngIf="isValidating">
                <button mat-raised-button color="secondary" (click)="cancelValidate()">Cancel</button>
                <button mat-raised-button color="primary" (click)="validate()">Confirm</button>
            </div>
        </div>
    </footer>
</div>