export default class User {
    public Id: number = 0;
    public LoginId: number = 0;
    public IdGrower?: number;
    public IdRanch?: number;
    public Name: string = '';
    public LastName: string = '';
    public Phone?: string;
    public Mail?: string;
    public Role: number = 2;
    public Username: string = '';
    public Password: string = '';
    public Status: boolean = false;
}