import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActiveUser, ts } from '../../app.shared';
import { Grower } from '../growers/growers.model';
import { Ranch } from '../ranches/ranches.model';
import User from '../../models/user.model';
import MachinesEquipmentReports, { MachineAndEquipment, MachineAndEquipmentTypes } from './report.model';
import { History_Report } from './machine-v2/machineReports.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsV2Service {

  private uri:string = environment.URI;
  private _s;
  private _us;

  constructor(private http: HttpClient, private s:ts, private activeUser:ActiveUser) {
    s.getValue().subscribe((value)=> {
      this._s = value;
    });
    activeUser.getValue().subscribe((value)=> {
        this._us = value;
    })
  }

  public GetReports = (filters:{Date_Create?: Date, Date_Uploaded?: Date, Grower_Id?:number, Ranch_Id?: number, User_Id?: number, Page_Number?: number, Row_Number?: number }):Promise<any> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.post<any>(`${this.uri}/api/reports`, filters,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }



  public getCatalogues = ():Promise<{ growers: Array<{Id: number, Name: string}>, ranches: Array<{Id: number, GrowerId: number, Name: string}>, Users: Array<{Id: number, FullName: string}>}> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http
      .get<{ growers: Array<{Id: number, Name: string}>, ranches: Array<{Id: number, GrowerId: number, Name: string}>, Users: Array<{Id: number, FullName: string}>}>
      (`${this.uri}/api/getReportCatalogues`,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public getCataloguesV1 = async(): Promise<{growers: Array<Grower>, ranches: Array<Ranch>, users: Array<User>}> => {
    return new Promise((resolve,reject) => {
      this.http.post<{growers: Array<Grower>, ranches: Array<Ranch>, users: Array<User>}>('https://boggiattoprowebapi.azurewebsites.net/api/getcataloges', {}).subscribe(
        success => resolve(success),
        error => reject(error)
      )
    });
  }

  public ValidateFormV2 = (validateInfo:{Id: number, User_Id?: number, Comment_By_Validator: string, Validate_Date: Date, Code: string}):Promise<any> => {
    return new Promise((resolve, reject) => {

      validateInfo.User_Id = this._us.Id;

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.post<any>(`${this.uri}/api/validatev2`, validateInfo,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public GetMachinesAndEquipment = ():Promise<Array<MachineAndEquipment>> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http
      .get<Array<MachineAndEquipment>>
      (`${this.uri}/api/machinesAndEquipment`,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public GetMachinesAndEquipmentTypes = ():Promise<Array<MachineAndEquipmentTypes>> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.get<Array<MachineAndEquipmentTypes>>(`${this.uri}/api/machinesAndEquipmentreportstypes`,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public GetMachineAndEquipmentReports = (filter:{Machine_HF: string,Type_Id: number}):Promise<Array<History_Report>> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.post<Array<History_Report>>(`${this.uri}/api/machineandequipmentreportshistory`, filter,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
  
  public GetWeeklyReports = (filters:{Start_Date?: Date, End_Date?: Date, Grower_Id?:number, Ranch_Id?: number, User_Id?: number, Page_Number?: number, Row_Number?: number }):Promise<any> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.post<any>(`${this.uri}/api/getWeeklyReports`, filters,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
}
