import { Component } from '@angular/core';
import { ts, ActiveUser } from './app.shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  constructor(private s: ts,  private activeUser:ActiveUser){
    if(s.checkPersist()){
      s.loadPersist();
      s.cleanPersist();
      activeUser.loadPersist();
      activeUser.cleanPersist();
    }
    
    window.addEventListener("beforeunload", (event) => {
      event.preventDefault();
      if(s.exist()){
        s.persist();
        activeUser.persist();
      }
      return event;
    });

  }
}
