import { Component, OnInit } from '@angular/core';
import { ts } from '../../app.shared';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(private s:ts) {}

  async ngOnInit() {
    this.s.getValue().subscribe((value) => {
      this.userLogged = value ? value.length > 0 : false;
    });
  }

  private matDrawer;
  public username: string = 'Jose luis';

  public userLogged: boolean = false;

  public toggleSidebar = (event) => {
    this.matDrawer = event;
  }
  public toggleToolbar = (event) => {
    this.matDrawer._opened ? 
      this.matDrawer.close() :
      this.matDrawer.open();
  }
}
