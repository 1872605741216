import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractorsService } from '../contractors.service';

@Component({
  selector: 'app-delete-contrator',
  templateUrl: './delete-contrator.component.html',
  styleUrls: ['./delete-contrator.component.scss']
})
export class DeleteContratorComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteContratorComponent>, @Inject(MAT_DIALOG_DATA) public data: {contractorId: number}, private service: ContractorsService) { }

  ngOnInit(): void {
  }

  public cancelModal = (data) => {
    this.dialogRef.close(data);
  }

  public delete = async() => {
    let result: any = await this.service.Delete(this.data.contractorId);
    if(!result.error)
      this.cancelModal(this.data.contractorId);
  }

}
