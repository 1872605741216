<div class="main-content">
  <div class="header">
    <h1>Custom forms</h1>
    <button mat-icon-button (click)="openCreateModal()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <div class="container">
    <mat-card>
      <mat-card-header>
        <h2>Daily reports</h2>
      </mat-card-header>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let daily of reportsDaily">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Questionary
            </mat-panel-title>
            <mat-panel-description>
              {{ daily.Title }} - {{ daily.Status ? 'Active' : 'Disabled' }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="expansion-content">
            <div class="actions">
              <button mat-icon-button (click)="openEditModal(daily, false)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="openDeleteModal(daily.Id, false)">
                <mat-icon>hide_source</mat-icon>
              </button>
            </div>
            <mat-list>
              <mat-list-item *ngFor="let dailyItem of daily.questions">
                <div class="item-questions">
                  <p>
                    <strong>Type</strong>: {{ showInput(dailyItem.Custom_Form_Input_Id) }}
                  </p>
                  <div class="questions-pa">
                    <p>
                      <strong>Question</strong>: {{ dailyItem.Question }}
                    </p>
                    <p>
                      <strong>Translation</strong>: {{ dailyItem.Translation }}
                    </p>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
    <mat-card>
      <mat-card-header>
        <h2>Periodic reports</h2>
      </mat-card-header>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let periodic of reportsPeriodic">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Questionary
            </mat-panel-title>
            <mat-panel-description>
              {{ periodic.Title }} - {{ periodic.Status ? 'Active' : 'Disabled' }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="expansion-content">
            <div class="actions">
              <button mat-icon-button (click)="openEditModal(periodic, true)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button (click)="openDeleteModal(periodic, false)">
                <mat-icon>hide_source</mat-icon>
              </button>
            </div>
            <mat-list>
              <mat-list-item *ngFor="let periodicItem of periodic.questions">
                <div class="item-questions">
                  <p>
                    <strong>Type</strong>: {{ showInput(periodicItem.Custom_Form_Input_Id) }}
                  </p>
                  <div class="questions-pa">
                    <p>
                      <strong>Question</strong>: {{ periodicItem.Question }}
                    </p>
                    <p>
                      <strong>Translation</strong>: {{ periodicItem.Translation }}
                    </p>
                  </div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card>
  </div>
</div>