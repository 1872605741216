import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ActiveUser, ts } from "./app.shared";
import User from "./models/user.model";

@Injectable({
    providedIn: 'root'
})
export class OnlyAdmins implements CanActivate {
    private _us: User;
    
    constructor(private activeUser:ActiveUser) {
        activeUser.getValue().subscribe((value)=> {
            this._us = value;
        });
    };

    canActivate() {
        if (this._us.Role === 1) {
            return true;
        } else {
            window.alert("You don't have permission to view this page");
            return false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class OnlyAppUsers implements CanActivate {
    private _us: User;
    
    constructor(private activeUser:ActiveUser) {
        activeUser.getValue().subscribe((value)=> {
            this._us = value;
        });
    };

    canActivate() {
        if (this._us.Role === 2) {
            return true;
        } else {
            window.alert("You don't have permission to view this page");
            return false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class OnlyAuditor implements CanActivate {
    private _us: User;
    
    constructor(private activeUser:ActiveUser) {
        activeUser.getValue().subscribe((value)=> {
            this._us = value;
        });
    };

    canActivate() {
        if (this._us.Role === 3) {
            return true;
        } else {
            window.alert("You don't have permission to view this page");
            return false;
        }
    }
}

@Injectable({
  providedIn: 'root'
})
export class OnlyGuests implements CanActivate {
    private _us: User;
    
    constructor(private activeUser:ActiveUser) {
        activeUser.getValue().subscribe((value)=> {
            this._us = value;
        });
    };

    canActivate() {
        if (this._us.Role === 4) {
            return true;
        } else {
            window.alert("You don't have permission to view this page");
            return false;
        }
    }
}

@Injectable({
  providedIn: 'root'
})
export class OnlyMechanic implements CanActivate {
    private _us: User;
    
    constructor(private activeUser:ActiveUser) {
        activeUser.getValue().subscribe((value)=> {
            this._us = value;
        });
    };

    canActivate() {
        if (this._us.Role === 5) {
            return true;
        } else {
            window.alert("You don't have permission to view this page");
            return false;
        }
    }
}