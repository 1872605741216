import { Component, OnInit } from '@angular/core';
import { WaterUsageService } from './water-usage.service';
import { Router } from '@angular/router';
import { ReportsV2Service } from '../reports-v2.service';
import { Grower } from 'src/app/models/grower.model';
import User from 'src/app/models/user.model';
import { Ranch } from '../../ranches/ranches.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import DataTable from 'src/app/components/table/table.model';
import { WaterUsateDetailComponent } from './water-usate-detail/water-usate-detail.component';
import * as moment from 'moment';
import { ActiveUser } from 'src/app/app.shared';

@Component({
  selector: 'app-water-usage',
  templateUrl: './water-usage.component.html',
  styleUrls: ['./water-usage.component.scss']
})
export class WaterUsageComponent implements OnInit {

  public action: boolean = true;

  public waterReportsdataTable: DataTable = new DataTable();
  public reports: any[] = [];
  public growers: Grower[] = [];
  public users: User[] = [];
  public ranches: Ranch[] = [];
  public userId: number = null;
  public fromDate: any;
  public maxDate: any;
  
  constructor(
    public service: WaterUsageService,
    private router: Router,
    private reportService: ReportsV2Service,
    private dialog: MatDialog
  ) {
   
  }

  ngOnInit(): void {
    this.fromDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');

    this.waterReportsdataTable.Headers.push('Name');
    this.waterReportsdataTable.Headers.push('Lastname');
    this.waterReportsdataTable.Headers.push('Days');
    this.waterReportsdataTable.Headers.push('Total_Consumed');
    this.waterReportsdataTable.Headers.push('Actions');
    this.getCatalogues();
  }

  public getData = async() => {

    let startDate = moment(this.fromDate).startOf('month').format('YYYY-MM-DD');
    let endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

    let dataReports = await this.service.getAll(this.userId, startDate, endDate);
    let result = [{
      Name: '',
      Lastname: '',
      Total_Consumed: 0,
      Days: 0
    }];

    if(dataReports.length > 0) {
      dataReports.map((report, index) => {
        if(index === 0) {
          result[0].Name = report.Name;
          result[0].Lastname = report.LastName;
        }
        result[0].Days = index + 1;
        result[0].Total_Consumed =+ report.Total;
      });
    }
    this.reports = result;
    console.log(this.reports);
    this.action = false;
  }

  public getCatalogues = async() => {

    let result: any = await this.reportService.getCatalogues();

    this.growers = result.growers;
    this.ranches = result.ranches;
    this.users = result.waterUsers;
  }

  public filterData = () => {

  }

  public showDetail = () => {

  }

  public downloadPDF = () => {    

  }

  public generatePDF = () => {

  }

  public back = () => {
    this.router.navigate(['reports-v2']);
  }
  public detail = (event: {data: any}) => {
    this.modalDetail(event.data);
  }
  public download = (event: {data: any}) => {
    this.downloadPdf(event.data);
  }

  public downloadPdf = (data: any) => {

  }

  private modalDetail = (data: any) => {
        
    let dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "80%";
    dialogConfig.height = "84vh";
    dialogConfig.data = {form: data};

    let dialogRef = this.dialog.open(WaterUsateDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(async(_result) => {
        
    });
  }

  public closeDatePicker(eventData: any, dp?:any) {
    
    // this.fromDate = moment().startOf('month').format('YYYY-MM-DD');
    // this.toDate = moment().endOf('month').format('YYYY-MM-DD');
    dp.close();    
  }
}
