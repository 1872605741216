export default class PreSeason {
  Id: number = 0;
  GrowerId: number = 0;
  RanchId: number = 0;
  Date: Date = new Date();
  Time: string = '';
  Comodities: string = '';
  Lots: string = '';
  InspectedBy:string= '';
  ReviewedBy:string = '';
  ReviewedDate:Date = new Date();
  Preseason: boolean = null;
  WellWater: boolean = null;
  TerrainWater:boolean = null;
  SupplyWater:boolean = null;
  Other:boolean = null;
  OtherExplain:string = '';
  Water1:boolean = null;
  Water2:boolean = null;
  Water3:boolean = null;
  Soil1:boolean = null;
  Soil2:boolean = null;
  Soil2Explain:boolean = null;
  Soil3:boolean = null;
  Soil3Explain:boolean = null;
  Soil4:boolean = null;
  Soil4Explain:boolean = null;
  Soil5:boolean = null;
  Soil5Explain:boolean = null;
  Environment1:boolean = null;
  Environment1Explain:boolean = null;
  Environment2:boolean = null;
  Environment2Explain:boolean = null;
  EnvironmentTemperature:boolean = null;
  EnvironmentPrecipitation:boolean = null;
  EnvironmentWind:boolean = null;
  Environment3:boolean = null;
  Environment4:boolean = null;
  EnvironmentCafo:boolean = null;
  EnvironmentAmendments:boolean = null;
  EnvironmentGrazing:boolean = null;
  EnvironmentSeptic:boolean = null;
  EnvironmentProtection:boolean = null;
  EnvironmentAreas:boolean = null;
  EnvironmentCafoExplain1:boolean = null;
  EnvironmentCafoExplain2:boolean = null;
  EnvironmentCafoExplain3:boolean = null;
  EnvironmentCafoExplain4:boolean = null;
  Environment5:boolean = null;
  EmployeeHygiene:boolean = null;
  EmployeeHygieneExplain:string = '';
  Sanitation:boolean = null;
  WaterComments:string = '';
  SoilComments:string = '';
  EnvironmentComments:string = '';
  EmployeeComments:string = '';
  SanitationComments:string = '';
  AllergentsComments:string = '';
}
