import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ContractorsService } from '../contractors.service';

export class ContractorEditErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-edit-contrator',
  templateUrl: './edit-contrator.component.html',
  styleUrls: ['./edit-contrator.component.scss']
})
export class EditContratorComponent implements OnInit {

  public matcher:ContractorEditErrorStateMatcher = new ContractorEditErrorStateMatcher();

  constructor(public dialogRef: MatDialogRef<EditContratorComponent>, @Inject(MAT_DIALOG_DATA) public data: {contractor: any}, private service: ContractorsService) { }

  ngOnInit(): void {
    this.form.controls.contact.setValue(this.data.contractor.Contact);
    this.form.controls.contactName.setValue(this.data.contractor.ContactName);
  }

  public form: FormGroup = new FormGroup({
    contact: new FormControl('',[Validators.required, Validators.maxLength(150), Validators.minLength(3)]),
    contactName: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
  });
  
  public cancelModal = (data: any = null) => {
    this.dialogRef.close(data);
  }

  public onEdit = async() => {

    let contractor: any = {};
    
    contractor.Id = this.data.contractor.Id;
    contractor.Contact = this.form.controls.contact.value;
    contractor.ContactName = this.form.controls.contactName.value;
    contractor.Status = this.data.contractor.Status;

    let result: any = await this.service.Edit(contractor);
    
    if(result.error){
      return;
    }
    this.cancelModal(result);

  }

}
