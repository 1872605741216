import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import User from '../../../models/user.model';
import { ts, ActiveUser } from '../../../app.shared';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  private _us: User;
  public userName: string = '';
  
  constructor(private s: ts, private activeUser: ActiveUser) {
    activeUser.getValue().subscribe((value)=> {
      this._us = value;
    })
  }

  ngOnInit(): void {
    this.userName = `${this._us.Name} ${this._us.LastName}`;
  }

  @Output() toggle = new EventEmitter();

  @Input() user: string;

  public toggleSidebar = () => {
    this.toggle.emit(1);
  }

  public logout = async() => {
    this.s.setValue('');
  }
}
