import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoucaComponent } from '../nouca.component';
import { NoucaService } from '../nouca.service';

@Component({
  selector: 'app-nouca-modal-delete',
  templateUrl: './nouca-modal-delete.component.html',
  styleUrls: ['./nouca-modal-delete.component.scss']
})
export class NoucaModalDeleteComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NoucaComponent>, private service:NoucaService,  @Inject(MAT_DIALOG_DATA) public data: {noucaId: number}) { }

  ngOnInit(): void {
    console.log(this.data.noucaId)
  }

  public cancelModal = (data:any = null) =>{
    this.dialogRef.close(data);
  }
  public delete = async() => {
    let result: any = await this.service.Delete(this.data.noucaId);
    if(!result.error)
      this.cancelModal(this.data.noucaId);
  }

}
