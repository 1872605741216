<div class="container" id="modal">
    <mat-toolbar>
      <span class="fill-space"></span>
      <span>Upload files</span>
      <span class="fill-space"></span>
      <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
    </mat-toolbar>
    
    <form>
      <div class="identity-information">
        <mat-checkbox [(ngModel)]="multiple" name="multiple" (change)="reset()">Multiple files</mat-checkbox>
        <h2>Choose file</h2>
        <div class="form-group">
          <input #csvInput hidden="true" multiple="true" type="file" onclick="this.value=null" (change)="csvInputChange($event)"/>
          <button mat-fab color="primary" (click)="csvInput.click()">
            <mat-icon>upload</mat-icon>
          </button>
          <p *ngIf="!multiple"><b>File:</b> {{ fileToUpload ? fileToUpload.name : 'Choose single file'}}</p>
          <div>
            <p *ngIf="multiple"><b>Files:</b></p>
            <mat-list role="list" *ngFor="let files of filesToUpload">
              <mat-list-item role="listitem">
                {{ files.name }}
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
    </form>

    <footer class="dialog-footer">
      <div class="button-row">
        <button mat-raised-button color="primary" [disabled]="action" (click)="onAdd()">Create</button>
        <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
      </div>
    </footer>
  </div>
    
  