import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Grower } from '../../../models/grower.model';
import { GrowersService } from '../growers.service'; 

export class GrowerAddErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-grower-modal-add',
  templateUrl: './grower-modal-add.component.html',
  styleUrls: ['./grower-modal-add.component.scss']
})
export class GrowerModalAddComponent implements OnInit {

  public matcher:GrowerAddErrorStateMatcher = new GrowerAddErrorStateMatcher();

  constructor(public dialogRef: MatDialogRef<GrowerModalAddComponent>, private service: GrowersService) {}

  ngOnInit(): void {
  }

  public form: FormGroup = new FormGroup({
    name: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
    contactName: new FormControl('',[Validators.required, Validators.maxLength(100), Validators.minLength(3)]),
    cellNumber: new FormControl('', Validators.maxLength(20)),
    phoneNumber: new FormControl('', Validators.maxLength(20)),
    faxNumber: new FormControl('', Validators.maxLength(20)),
    email: new FormControl('', Validators.email),
    adress: new FormControl('', Validators.maxLength(200)),
    location: new FormControl('', Validators.maxLength(500)),
    city: new FormControl('', Validators.maxLength(80)),
    county: new FormControl('', Validators.maxLength(80)),
    state: new FormControl('', Validators.maxLength(80)),
    country: new FormControl('', Validators.maxLength(80)),
    zipCode: new FormControl('', Validators.maxLength(10)),
    size: new FormControl('', Validators.maxLength(100))
  });
  
  public cancelModal = (result: Grower = null) => {
    this.dialogRef.close(result);
  }
  
  createGrower = async() => {
    let grower: Grower = new Grower();

    grower.Name = this.form.controls.name.value;
    grower.ContactName = this.form.controls.contactName.value;
    grower.CellNumber = this.form.controls.cellNumber.value;
    grower.PhoneNumber = this.form.controls.phoneNumber.value;
    grower.FaxNumber = this.form.controls.faxNumber.value;
    grower.Email = this.form.controls.email.value;
    grower.Adress = this.form.controls.adress.value;
    grower.Location = this.form.controls.location.value;
    grower.City = this.form.controls.city.value;
    grower.County = this.form.controls.county.value;
    grower.State = this.form.controls.state.value;
    grower.Country = this.form.controls.country.value;
    grower.ZipCode = this.form.controls.zipCode.value;
    grower.Size = this.form.controls.size.value;

    let result: any = await this.service.Create(grower);
    
    if(result.error){
      return;
    }
    this.cancelModal(result);

  }

}
