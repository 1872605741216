import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomReportService } from '../custom-report.service';

@Component({
  selector: 'app-delete-custom-report',
  templateUrl: './delete-custom-report.component.html',
  styleUrls: ['./delete-custom-report.component.scss']
})
export class DeleteCustomReportComponent implements OnInit {

  constructor(
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {report_id: number},
    public dialogRef: MatDialogRef<DeleteCustomReportComponent>,
    private service: CustomReportService
  ) { }

  ngOnInit(): void {
  }

  public cancelModal = (data: any = null) => {
    this.dialogRef.close(data);
  }

  public delete = async() => {
    let result: any = await this.service.deleteData(this.data.report_id, false);
    if(result.error){
      this._snackBar.open(`This item can't be disabled, because is used in another place`, 'Undo');
      this.cancelModal();
    } else
      this.cancelModal(this.data.report_id);
    
  }

}
