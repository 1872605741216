import { Injectable } from '@angular/core';
import { ts, ActiveUser } from '../../app.shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import User from '../../models/user.model';
import { MachinesAndEquipment } from '../../models/machinesAndEquipment.model';

@Injectable({
    providedIn: 'root'
})
export class MachinesAndEquipmentService {
  
  private uri:string = environment.URI;
  private _s: string;
  private _us: User;

  constructor(private http:HttpClient, private s:ts, private activeUser:ActiveUser) {
      s.getValue().subscribe((value)=> {
        this._s = value;
      });
      activeUser.getValue().subscribe((value)=> {
          this._us = value;
      })
  }

  public GetById = (machinesAndEquipmentId: number):Promise<Array<MachinesAndEquipment>> => {
      return new Promise((resolve, reject) => {
  
        let headers = new HttpHeaders();
        headers = headers.set('x-auth', this._s);
  
        this.http.get<Array<MachinesAndEquipment>>(`${this.uri}/api/machinesAndEquipment?machinesAndEquipmentId=${machinesAndEquipmentId}`, {headers: headers, observe:'response'}).subscribe(
            success => {
              this.s.setValue(success.headers.get('x-auth'));
              resolve(success.body);
            }, 
            error => {
              if(error.status == 401){
                this.s.cleanPersist()
                this.activeUser.cleanPersist()
                this.s.delete()
                this.activeUser.delete();
              }
              reject({error: error});
        });
      });
  }

  public Get = ():Promise<Array<MachinesAndEquipment>> => {
      return new Promise((resolve, reject) => {
  
        let headers = new HttpHeaders();
        headers = headers.set('x-auth', this._s);
  
        this.http.get<Array<MachinesAndEquipment>>(`${this.uri}/api/machinesAndEquipment`, {headers: headers, observe:'response'}).subscribe(
            success => {
              this.s.setValue(success.headers.get('x-auth'));
              resolve(success.body);
            }, 
            error => {
              if(error.status == 401){
                this.s.cleanPersist()
                this.activeUser.cleanPersist()
                this.s.delete()
                this.activeUser.delete();
              }
              reject({error: error});
        });
      });
  }

  public GetV2 = (filter: string):Promise<Array<MachinesAndEquipment>> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.get<Array<MachinesAndEquipment>>(`${this.uri}/api/machinesAndEquipment/v2/${filter}`, {headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public Create = (machinesAndEquipment:MachinesAndEquipment):Promise<MachinesAndEquipment> => {
      return new Promise((resolve, reject) => {
  
        let headers = new HttpHeaders();
        headers = headers.set('x-auth', this._s);
  
        this.http.post<MachinesAndEquipment>(`${this.uri}/api/machinesAndEquipment`, machinesAndEquipment,{headers: headers, observe:'response'}).subscribe(
            success => {
              this.s.setValue(success.headers.get('x-auth'));
              resolve(success.body);
            }, 
            error => {
              if(error.status == 401){
                this.s.cleanPersist()
                this.activeUser.cleanPersist()
                this.s.delete()
                this.activeUser.delete();
              }
              reject({error: error});
        });
      });
  }

  public Edit = (machinesAndEquipment:MachinesAndEquipment):Promise<MachinesAndEquipment> => {
      return new Promise((resolve, reject) => {
  
        let headers = new HttpHeaders();
        headers = headers.set('x-auth', this._s);
  
        this.http.put<MachinesAndEquipment>(`${this.uri}/api/machinesAndEquipment`, machinesAndEquipment,{headers: headers, observe:'response'}).subscribe(
            success => {
              this.s.setValue(success.headers.get('x-auth'));
              resolve(success.body);
            }, 
            error => {
              if(error.status == 401){
                this.s.cleanPersist()
                this.activeUser.cleanPersist()
                this.s.delete()
                this.activeUser.delete();
              }
              reject({error: error});
        });
      });
  }

  public Delete = (soft: boolean = true, machinesAndEquipmentId: number):Promise<MachinesAndEquipment> => {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);
  
      this.http.delete<MachinesAndEquipment>(`${this.uri}/api/machinesAndEquipment?Id=${machinesAndEquipmentId}&soft=${soft}`, {headers: headers, observe:'response'}).subscribe(
        success => {
          this.s.setValue(success.headers.get('x-auth'));
          resolve(success.body);
          }, 
            error => {
              if(error.status == 401){
                this.s.cleanPersist()
                this.activeUser.cleanPersist()
                this.s.delete()
                this.activeUser.delete();
              }
              reject({error: error});
        });
    });
  }
}