<div class="container">
    <mat-card>
        <div class="title">
            <button mat-icon-button (click)="back()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <h1>Nouca Log</h1>
        </div>
        <div>
            <button mat-raised-button (click)="modalAdd()">Create Nouca Report</button>
        </div>
        <div class="main-table">
            <!-- <div class="filter" fxLayout="row">
                <mat-form-field>
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
                </mat-form-field>
            </div> -->
            <app-table [data]="noucas" [dataTable]="dataTable" (updateItem)="update($event)" (deleteItem)="delete($event)"></app-table>
            <div class="mat-elevation-z8">
                <!-- <mat-table [dataSource]="dataSource" matSort>

                    <ng-container *ngFor="let headCol of displayedColumns; let i = index" matColumnDef="{{ headCol }}">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ headCol }} </mat-header-cell>
                        <mat-cell *matCellDef="let row" [attr.data-label]="headCol">
                            <div *ngIf="headCol == 'Actions'">
                                <button *ngIf="row.ContactName != null" mat-button (click)="ranch(row.Id);">
                                 <mat-icon>map</mat-icon>
                                </button>
                                <button mat-button (click)="update(row, i);">
                                 <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-button (click)="delete(row, i)">
                                 <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            {{ checkData(row[headCol], headCol) }}
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                </mat-table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
            </div>
        </div>
    </mat-card>
</div>
