import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { UsersComponent } from './views/users/users.component';
import { GrowersComponent } from './views/growers/growers.component';
import { ReportsComponent } from './views/reports/reports.component';
import { FileManagerComponent } from './views/file-manager/file-manager.component';
import { FormsComponent } from './views/forms/forms.component';
import { DailyComponent } from './views/reports/daily/daily.component';
import { WeeklyComponent } from './views/reports/weekly/weekly.component';
import { MachineComponent } from './views/reports/machine/machine.component';
import { RanchesComponent } from './views/ranches/ranches.component';
import { NoucaComponent } from './views/forms/nouca/nouca.component';
import { PreSeasonComponent } from './views/forms/pre-season/pre-season.component';
import { MachinesComponentComponent } from './views/machines-component/machines-component.component';
import { ReportsV2Component } from './views/reports-v2/reports-v2.component';
import { DailyV2Component } from './views/reports-v2/daily-v2/daily-v2.component';
import { WeeklyV2Component } from './views/reports-v2/weekly-v2/weekly-v2.component';
import { MachineV2Component } from './views/reports-v2/machine-v2/machine-v2.component';
import { OnlyAdmins, OnlyAppUsers, OnlyAuditor, OnlyGuests, OnlyMechanic } from './guard.app';
import { FilesGuestsComponent } from './components/files-guests/files-guests.component';
import { PreHarvestComponent } from './views/forms/pre-harvest/pre-harvest.component';
import { WaterUsageComponent } from './views/reports-v2/water-usage/water-usage.component';
import { MachinesV2Component } from './views/machines-v2/machines-v2.component';
import { CustomReportsComponent } from './views/custom-reports/custom-reports.component';
import { ContractorsComponent } from './views/contractors/contractors.component';

// {path: '', component: HomeComponent},
// {path: 'users', component: UsersComponent, canActivate: [OnlyAdmins]},
// {path: 'growers', component: GrowersComponent, canActivate: [OnlyAdmins]},
// {path: 'upload', component: FileManagerComponent, canActivate: [OnlyAdmins]},
// {path: 'forms', component: FormsComponent, canActivate: [OnlyAdmins, OnlyAuditor]},
// {path: 'forms/nouca', component: NoucaComponent, canActivate: [OnlyAdmins, OnlyAuditor]},
// {path: 'forms/pre', component: PreSeasonComponent, canActivate: [OnlyAdmins, OnlyAuditor]},
// {path: 'reports', component: ReportsComponent, canActivate: [OnlyAdmins]},
// {path: 'reports/daily', component: DailyComponent, canActivate: [OnlyAdmins]},
// {path: 'reports/weekly', component: WeeklyComponent, canActivate: [OnlyAdmins]},
// {path: 'reports/machine', component: MachineComponent, canActivate: [OnlyAdmins, OnlyAppUsers]},
// {path: 'growers/ranch/:id', component: RanchesComponent, canActivate: [OnlyAdmins]},
// {path: 'machines', component: MachinesComponentComponent, canActivate: [OnlyAdmins, OnlyAppUsers]},
// {path: 'reports-v2', component: ReportsV2Component, canActivate: [OnlyAdmins, OnlyAuditor]},
// {path: 'reports-v2/daily', component: DailyV2Component, canActivate: [OnlyAdmins, OnlyAuditor]},
// {path: 'reports-v2/weekly', component: WeeklyV2Component, canActivate: [OnlyAdmins, OnlyAuditor]},
// {path: 'reports-v2/machine', component: MachineV2Component, canActivate: [OnlyAdmins, OnlyAuditor]},
// {path: 'file-viewer', component: FilesGuestsComponent, canActivate: [OnlyGuests, OnlyAuditor]},

const routes: Routes = [
  //ADMIN
  {path: '', component: HomeComponent},
  {path: 'users', component: UsersComponent, canActivate: [OnlyAdmins]},
  {path: 'growers', component: GrowersComponent, canActivate: [OnlyAdmins]},
  {path: 'contractors', component: ContractorsComponent, canActivate: [OnlyAdmins]},
  {path: 'upload', component: FileManagerComponent, canActivate: [OnlyAdmins]},
  {path: 'forms', component: FormsComponent, canActivate: [OnlyAdmins]},
  {path: 'forms/nouca', component: NoucaComponent, canActivate: [OnlyAdmins]},
  {path: 'forms/pre', component: PreSeasonComponent, canActivate: [OnlyAdmins]},
  {path: 'forms/preh', component: PreHarvestComponent, canActivate: [OnlyAdmins]},
  {path: 'reports', component: ReportsComponent, canActivate: [OnlyAdmins]},
  {path: 'reports/daily', component: DailyComponent, canActivate: [OnlyAdmins]},
  {path: 'reports/weekly', component: WeeklyComponent, canActivate: [OnlyAdmins]},
  {path: 'reports/machine', component: MachineComponent, canActivate: [OnlyAdmins]},
  {path: 'growers/ranch/:id', component: RanchesComponent, canActivate: [OnlyAdmins]},
  {path: 'machines', component: MachinesComponentComponent, canActivate: [OnlyAdmins]},
  {path: 'machines-equipment-v2', component: MachinesV2Component, canActivate: [OnlyAdmins]},
  {path: 'reports-v2', component: ReportsV2Component, canActivate: [OnlyAdmins]},
  {path: 'reports-v2/daily', component: DailyV2Component, canActivate: [OnlyAdmins]},
  {path: 'reports-v2/weekly', component: WeeklyV2Component, canActivate: [OnlyAdmins]},
  {path: 'reports-v2/machine', component: MachineV2Component, canActivate: [OnlyAdmins]},
  {path: 'reports-v2/waterusage', component: WaterUsageComponent, canActivate: [OnlyAdmins]},
  {path: 'custom-reports', component: CustomReportsComponent, canActivate: [OnlyAdmins]},

  //APPUSERS
  {path: 'reports-app', component: MachineV2Component, canActivate: [OnlyAppUsers]},
  {path: 'machines-equipment-v2-app', component: MachinesV2Component, canActivate: [OnlyAppUsers]},

  //AUDITOR
  {path: 'reports-v2-aud', component: ReportsV2Component, canActivate: [OnlyAuditor]},
  {path: 'reports-v2-aud/daily', component: DailyV2Component, canActivate: [OnlyAuditor]},
  {path: 'reports-v2-aud/weekly', component: WeeklyV2Component, canActivate: [OnlyAuditor]},
  {path: 'reports-v2-aud/machine', component: MachineV2Component, canActivate: [OnlyAuditor]},
  {path: 'forms-aud', component: FormsComponent, canActivate: [OnlyAuditor]},
  {path: 'forms-aud/nouca', component: NoucaComponent, canActivate: [OnlyAuditor]},
  {path: 'forms-aud/pre', component: PreSeasonComponent, canActivate: [OnlyAuditor]},
  {path: 'file-viewer-aud', component: FilesGuestsComponent, canActivate: [OnlyAuditor]},

  //GUESTS
  {path: 'file-viewer', component: FilesGuestsComponent, canActivate: [OnlyGuests]},

  // MECHANICS
  {path: 'machines-equipment-v2-mech', component: MachinesV2Component, canActivate: [OnlyMechanic]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
