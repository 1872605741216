<div class="container">
    <mat-card>
        <div class="title">
            <button mat-icon-button (click)="back()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <h1>Pre-Season</h1>
        </div>
        <div>
            <button mat-raised-button (click)="modalAdd()">Create Report</button>
        </div>
        <app-table [data]="preSeasons" [dataTable]="dataTable" (updateItem)="update($event)" (deleteItem)="delete($event)" (detailItem)="detail($event)"></app-table>
    </mat-card>
</div>
