import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReportsV2Service } from '../reports-v2.service';
import * as moment from 'moment';
import DataTable from '../../../components/table/table.model';
import { Grower } from '../../growers/growers.model';
import { Ranch } from '../../ranches/ranches.model';
import User from '../../../models/user.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DailyModalDetailV2Component } from './daily-modal-detail-v2/daily-modal-detail-v2.component';
import { Img, PdfMakeWrapper, Table, Txt } from 'pdfmake-wrapper';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Chlorine_Inspection, Field_Assessment, Harvest_Machine, Machine_Equipment, Machine_Inspection, Report, Weight_Inspection } from '../report.model';
import { environment } from 'src/environments/environment';

const questionsEn1 = [
  'a- We filled the water supply tanks for the harvest with new water before starting the working day and this water has a suitable chlorine and pH level (650 to 950 mV ORP or 100 to 200 ppm and 6.5 to 7.5 pH)? - This water will be used to spray product, to fill the trays of the knives (knive dip stations), to wash agricultural machinery, etc.-.',
  'b- Was a general cleaning to agricultural machinery (harvesting machine, water tanks, tractors, etc.) before starting to harvest and verified that there are no traces or residues of soap, chlorine or any chemical agent?',
  'c- Was an inspection carried out to verify that there is no need for screws or nuts to product conveyors, elevators or agricultural machinery?',
  'd- Was an inspection made to the lighting system to verify that the lighting is sufficient and verify that there are no broken lamps?',
  'e- All hydraulic hose motors and connections are equipped with a drip prevention tray, these are fixed and free of evidence of dripping oil or grease?',
  'f- Was an inspection made of the chlorinated water system to all the spray nozzles to verify that they work properly?',
  'g- Was a visual inspection performed before starting work to verify that the agricultural machinery and employees are in compliance with the hygiene rules?',
  'h- Are portable toilets being provided, are they in sanitary conditions and contain the necessary items (toilet paper, hand towels, water, soap, water, etc.)?',
  'i- Was chlorinated or potable water added to the water tank of portable toilets, at least once a week?',
  'j- Are there any employees with symptoms of acute cough, watery eyes, runny nose, fever or symptoms of another contagious disease that could cause product contamination?',
  'k- The work team (gloves, sleeves, apron, knife) are in sanitary conditions before starting work?',
  'l- All employees have the necessary equipment and work material (maya for hair and beard - if necessary - plastic gloves, plastic sleeves, plastic apron)?',
  'm- Are employees not observed with personal items such as jewelry, pins, insurance, pins, false nails, false eyelashes, key rings, radios that are not allowed, etc.?',
  'n- All employees washed their hands with soap and water before starting work.',
  'o- Is the agricultural machinery / harvesting machine free of chemical products without labels?'
];

const questionsEn4 = [
  '1- There is excessive evidence of animal intrusion in the lot that is being harvested such as animal tracks, plants eaten by animals, feces, animal remains or blood.',
  "2- Some source of contamination is found in lands adjacent to the lot where it is harvested, such as manure or compost piles(400 '), livestock operations (CAFOs 400'), grazing areas(30 '), septic tanks (30'), manure composting facilities(400 '), etc.",
  '3- Found materials such as plastics, glass, metal parts (loose screws / nuts) or other debris',
  '4- The field that is going to be harvested suffered a flood in the last 60 days or are any changes in weather condition or weather events (e.g. severe wind, hail, freeze, excessive rain, or consecutive weather events) since the last assessment?.',
  '5- Unexpected activity is observed in the fields that are adjacent to the batch that is being harvested (livestock grazing, piles of compost, etc.)?',
  '6- The fences on the land adjacent to the harvest lot are not in good condition.',
  "7- An employee is observed not complying with the company's policies.If you find any of the evidences described, mark the number of the incident found during the harvest and write the corrective action taken to solve or minimize the risk of contamination of the product on the line corresponding to the day in which it is being harvested.",
  '8- Inspect portable toilets every two hours or more often. Make sure there is no water in the sink, that this well stocked with soap and toilet paper is clean. Mark the time the inspection is done in the inspection column. bathrooms in the lower right corner of this page.',
  '9- Chlorinated or potable water is required to the water tank of the portable toilets, (at least once a week)'
];

const questionsEn2 = [
  'p- Packing stations (Packing table)',
  'q- Product conveyor belts',
  'r- Rollers on product conveyors',
  's- Hydraulic or grease leaks on motors, bearings, hydraulic pistons, etc.',
  't- Catch pans on harvest machine/tractors',
  'u- Interior and exterior of all water tanks',
  'v- Knives and harvest utensils',
  'w- Harvest machine structure',
  'x- Reusable containers (the ones under our company control)',
  'y- Harvest machine #',
  'z- Harvest machine cleaning and sanitation completed ok (initials)'
];

const questionsEn3 = [
  'Presure washer function.',
  'Machine motor water level',
  'Machine motor oil level',
  'Machina hydraulic oil level',
  'Greased housing',
  'Check hydraulic pressure mano meter',
  'Verify that all the control board indicators work properly.',
  'Inspect all the control board buttons.',
  'Tire presure (visual inspection)',
  'Conveyor belts adjustment (wings, main and cartons conveyor)',
  'Transfer tension (chain tension)',
  'Was the scale verify?'
//   'Inspect for any leaks on the machinery (motor, radiator, tanks, hydraulic motors and shocks)',
//   'Grease transfer housing.',
//   'Grease all the machina housing.'
];

const questionsEn5 = [
  'Chlorine (PPM)',
  'pH',
  'Bathroom inspection',
];

const questionsEn6 = [
  'Jumbos 48s',
  'Jumbos 42s',
  'Jumbos 36s',
  '12 x 3',
  'Mini Bin',
  'Colossal',
  'Petites',
  'Iceberg babies 40s',
  'Iceberg babies 20s',
  '7 x 6'
];

@Component({
  selector: 'app-daily-v2',
  templateUrl: './daily-v2.component.html',
  styleUrls: ['./daily-v2.component.scss']
})
export class DailyV2Component implements OnInit {

    // Pagination
    public pageSize: number = 15;
    public pageIndex: number = 0;
    public previousPageIndex: number = 0;

    public paginationChange = async(event: any) => {
        this.pageSize = event.pageSize;
        this.previousPageIndex = event.previousPageIndex;
        this.pageIndex = event.pageIndex;
        await this.getData();
    }

    public dailyReports: Array<Report> = [];
    public dailyReportsdataTable: DataTable = new DataTable();
    public growers: Array<Grower> = [];
    public ranches: Array<Ranch> = [];
    public ranchesFiltered: Array<Ranch> = [];
    public users: Array<User> = [];
    public maxDate: Date = new Date();

    public lang: string = 'en';
    public fromDate: Date = null;
    public toDate: Date = null;
    public growerId?: number = null;
    public ranchId?: number = null;
    public userId?: number = null;

    constructor(
    private router:Router,
    private reportService: ReportsV2Service,
    private dialog: MatDialog
    ) {
        this.getCatalogues();
    }

    ngOnInit(): void {
        this.dailyReportsdataTable.Headers.push('Select');
        this.dailyReportsdataTable.Headers.push('Date_Create');
        this.dailyReportsdataTable.Headers.push('Grower_Name');
        this.dailyReportsdataTable.Headers.push('Ranch_Name');
        this.dailyReportsdataTable.Headers.push('User_Name');
        this.dailyReportsdataTable.Headers.push('User_Last_Name');
        this.dailyReportsdataTable.Headers.push('Validated_Name');
        this.dailyReportsdataTable.Headers.push('Actions');
        PdfMakeWrapper.setFonts(pdfFonts);
    }

    public detail = (event: {data: any}) => {
        this.modalDetail(event.data);
    }

    public download = (event: {data: any}) => {
        this.downloadPdf(event.data);
    }

    public downloadAll = (event: {data: any}) => {
        if(event.data.length > 0){
            event.data.map(async report => await this.downloadPdf(report));
        }
    }

    public back = () => {
        this.router.navigate(['reports-v2']);
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public displayedColumns: Array<string>;
    public dataSource: MatTableDataSource<any>;

    public applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    public getData = async() => {
        let filters = {
            Start_Date: this.fromDate,
            End_Date: this.toDate,
            Grower_Id: this.growerId,
            Ranch_Id: this.ranchId,
            User_Id: this.userId,
            Page_Number: this.pageIndex,
            Row_Number: this.pageSize
        }
        this.dailyReports = await this.reportService.GetReports(filters);
    }

    public getCatalogues = async() => {

        let result: any = await this.reportService.getCatalogues();

        this.growers = result.growers;
        this.ranches = result.ranches;
        this.users = result.users;
    }

    public growerChange = async(growerId: number) => {
    this.ranchesFiltered = await this.ranches.filter(x => x.IdGrower === growerId).sort((a,b)=> a<b ?-1: 1);
    }

    public formatDate = (date) => {
        let dates = moment.utc(date).local().format().split('T');
        return `${dates[0]} ${dates[1].substring(0,5)}`;
    }

    public downloadPdf = async(report: Report) => {
        const pdf = new PdfMakeWrapper();
        
        if(report.Validated_By)
            pdf.watermark( new Txt(`Form Validated at ${this.formatDate(moment())}`).color('blue').opacity(0.1).end );
        
        pdf.header( new Txt(`Printed ${moment().local().format('MM/DD/YYYY')}`).margin(5).alignment('right').end);
        pdf.add(new Txt('Hitchcock Farms').fontSize(30).bold().alignment('center').end);
        pdf.add(new Txt('Report information').alignment('center').end);
        pdf.add(new Txt('').alignment('center').end);
        
        pdf.add(
            new Table([
                [ 'Grower', report.Grower_Name.trim()],
                [ 'Ranch', report.Ranch_Name.trim()],
                [ 'Contractor name', report.Contractor_Name],
                [ 'Contractor contact', report.Contractor_Contact],
                [ 'Field', report.Yard.trim()],
                [ 'Field ID', report.Sub_Yard.trim()],
                [ 'Foreman', `${report.User_Name.trim()} ${report.User_Last_Name.trim()}`],
                [ 'Harvest Crew', `${report.Squad.trim()}`],
                [ 'Adress', `${report.Grower_Address.trim()}`],
                [ 'Contact Name', `${report.Grower_Contact_Name.trim()}`],
                [ 'Harvesting', `${report.Harvesting.trim()}`],
                [ 'Validated By', ` ${report.Validated_By ? report.Validated_Name.trim() + ' ' + report.Validated_Last_Name : 'This report isnt validated'}`],
                [ 'Comments By Validator', ` ${report.Validated_By ? report.Comment_By_Validator : 'This report isnt validated'}`],
                [ 'Validated Date', ` ${report.Validated_By ? this.formatDate(report.Validate_Date) : 'This report isnt validated'}`],
            ]).fontSize(15).alignment('left').widths([100,'*']).margin(30).end
        );
        pdf.add(new Txt('Shipper Hitchcock Farms Inc.').alignment('left').end);
        pdf.add(new Txt('PO Box 2266, Salinas CA 93902.').alignment('left').end);
        pdf.add(new Txt('Contact person: Jose Luis García').alignment('left').end);
        pdf.add(new Txt('Cell: 831-240-1109').alignment('left').end);
        if(report.harvestMachine) {

            // FORM 1
            pdf.add(new Txt(`Daily harvest crew and machine inspection   Created date: ${this.formatDate(report.harvestMachine.Date_Create)}`).bold().pageBreak('before').end);
            pdf.add(
                new Table(
                    this.formHarvestBuilder(report.harvestMachine)
                ).alignment('justify').widths(['*', 60]).end
            );
            pdf.add(new Txt('Comments:').bold().margin(10).end)
            
            if(report.harvestMachine.images) {
                if(report.harvestMachine.images.length > 0){
                    pdf.add(new Txt(report.harvestMachine.Comment.trim()).pageBreak('after').end)
                    pdf.add( new Txt('Daily harvest crew and machine inspection evidences').bold().end);
                    for(let i = 0; i< report.harvestMachine.images.length; i++){
                        try {

                            pdf.add(
                                await new Img(
                                    `${environment.STORAGE}/${report.harvestMachine.images[i].Image_Url.toLowerCase()}.jpeg`
                                    )
                                    .margin(10)
                                    .width(220)
                                    .height(220)
                                    .alignment('center')
                                    .build()
                            )
                        } catch(err) {
                            try {

                                pdf.add(
                                    await new Img(
                                        `${environment.STORAGE}/${environment.CONTAINER}/${report.harvestMachine.images[i].Image_Url.toLowerCase()}.jpeg`
                                        )
                                        .margin(10)
                                        .width(220)
                                        .height(220)
                                        .alignment('center')
                                        .build()
                                )
                            } catch(err) {
    
                            }
                        }
                    }
                }else {
                    pdf.add(new Txt(report.harvestMachine.Comment.trim()).end)
                }
            }
        }
        if(report.machineEquipment) {
            
            let userNameSplit = report.User_Name.split(' ');
            let userLastNameSplit = report.User_Last_Name.split(' ');
    
            let userLetters = '';
    
            if(userNameSplit.length > 0) {
                for(let i = 0; i< userNameSplit.length; i++){
                    userLetters += userNameSplit[i].substring(0, 1);
                }
            }
            if(userLastNameSplit.length > 0) {
                for(let i = 0; i< userLastNameSplit.length; i++){
                    userLetters += userLastNameSplit[i].substring(0, 1);
                }
            }
            // FORM 2
            pdf.add(new Txt(`Harvest machine and equipment cleaning and sanitation refer to ssop 8.05 of the GHP manual  Created date: ${this.formatDate(report.machineEquipment.Date_Create)}`).bold().pageBreak('before').end);
            pdf.add(
                new Table(
                    this.formEquipmentBuilder(report.machineEquipment, userLetters)
                ).alignment('justify').widths(['*', 60]).end
            );
            pdf.add(new Txt('Comments:').bold().margin(10).end)
            
            if(report.machineEquipment.images) {
                if(report.machineEquipment.images.length > 0){
                    pdf.add(new Txt(report.machineEquipment.Comment.trim()).pageBreak('after').end)
                    pdf.add( new Txt('Harvest machine and equipment cleaning and sanitation evidences').bold().end);
                    for(let i = 0; i< report.machineEquipment.images.length; i++){
                        try {
                            pdf.add(
                                await new Img(
                                    `${environment.STORAGE}/${report.machineEquipment.images[i].Image_Url.toLowerCase()}.jpeg`
                                    )
                                    .margin(10)
                                    .width(220)
                                    .height(220)
                                    .alignment('center')
                                    .build()
                            )
                        } catch(err) {
                            try {
                                pdf.add(
                                    await new Img(
                                        `${environment.STORAGE}/${environment.CONTAINER}/${report.machineEquipment.images[i].Image_Url.toLowerCase()}.jpeg`
                                        )
                                        .margin(10)
                                        .width(220)
                                        .height(220)
                                        .alignment('center')
                                        .build()
                                )
                            } catch(err) {

                            }
                        }
                    }
                }else{
                    pdf.add(new Txt(report.machineEquipment.Comment.trim()).end)
                }
            }
        }

        if(report.machineInspection) {
            // FORM 3
            pdf.add(new Txt(`Machine inspection   Created date: ${this.formatDate(report.machineInspection.Date_Create)}`).bold().pageBreak('before').end);
            pdf.add(
                new Table(
                    this.formMachineInspectionBuilder(report.machineInspection)
                ).alignment('justify').widths(['*', 60]).end
            );
            pdf.add(new Txt('Comments:').bold().margin(10).end)
            
            if(report.machineInspection.images) {
                if(report.machineInspection.images.length > 0){
                    pdf.add(new Txt(report.machineInspection.Comment.trim()).pageBreak('after').end)
                    pdf.add( new Txt('Machine inspection evidences').bold().end);
                    for(let i = 0; i< report.machineInspection.images.length; i++){
                        try {
                            pdf.add(
                                await new Img(
                                    `${environment.STORAGE}/${report.machineInspection.images[i].Image_Url.toLowerCase()}.jpeg`
                                    )
                                    .margin(10)
                                    .width(220)
                                    .height(220)
                                    .alignment('center')
                                    .build()
                            )
                        } catch(err) {
                            try {
                                pdf.add(
                                    await new Img(
                                        `${environment.STORAGE}/${environment.CONTAINER}/${report.machineInspection.images[i].Image_Url.toLowerCase()}.jpeg`
                                        )
                                        .margin(10)
                                        .width(220)
                                        .height(220)
                                        .alignment('center')
                                        .build()
                                )
                            } catch(err) {

                            }
                        }
                    }
                }else {
                    pdf.add(new Txt(report.machineInspection.Comment.trim()).end)
                }
            }
        }

        if(report.fieldAssessment) {

            // FORM 4
            pdf.add(new Txt(`Daily harvest field assessment   Created date: ${this.formatDate(report.fieldAssessment.Date_Create)}`).bold().pageBreak('before').end);
            pdf.add(
                new Table(
                    this.formFieldBuilder(report.fieldAssessment)
                ).alignment('justify').widths(['*', 60]).end
            );
            pdf.add(new Txt('Comments:').bold().margin(10).end)
            
            if(report.fieldAssessment.images) {

                if(report.fieldAssessment.images.length > 0){
                    pdf.add(new Txt(report.fieldAssessment.Comment.trim()).pageBreak('after').end)
                    pdf.add( new Txt('Daily harvest field assessment evidences').bold().end);
                    for(let i = 0; i< report.fieldAssessment.images.length; i++){
                        try {

                            pdf.add(
                                await new Img(
                                    `${environment.STORAGE}/${report.fieldAssessment.images[i].Image_Url.toLowerCase()}.jpeg`
                                    )
                                    .margin(10)
                                    .width(220)
                                    .height(220)
                                    .alignment('center')
                                    .build()
                            )
                        } catch(err) {
                            try {

                                pdf.add(
                                    await new Img(
                                        `${environment.STORAGE}/${environment.CONTAINER}/${report.fieldAssessment.images[i].Image_Url.toLowerCase()}.jpeg`
                                        )
                                        .margin(10)
                                        .width(220)
                                        .height(220)
                                        .alignment('center')
                                        .build()
                                )
                            } catch(err) {
    
                            }
                        }
                    }
                }else {
                    pdf.add(new Txt(report.fieldAssessment.Comment.trim()).end)
                }
            }
        }

        if(report.chlorineInspections) {

            // FORMS 5
            if(report.chlorineInspections.length > 0){
                pdf.add(new Txt(`Periodic chlorine inspection`).bold().pageBreak('before').end);
                for(let i = 0; i < report.chlorineInspections.length; i++){
                    
                    pdf.add(new Txt(`Created date: ${this.formatDate(report.chlorineInspections[i].Date_Create)}`).bold().end);
                    pdf.add(
                        new Table(
                            this.formChlorineBuilder(report.chlorineInspections[i])
                        ).alignment('justify').widths(['*', 60]).end
                    );
                    pdf.add(new Txt('Comments:').bold().margin(10).end)
                    pdf.add(new Txt(report.chlorineInspections[i].Comment.trim()).end)
                    if(report.chlorineInspections[i].images) {
                        if(report.chlorineInspections[i].images.length > 0){
                            pdf.add( new Txt('Evidences').bold().end);
                            for(let e = 0; e< report.chlorineInspections[i].images.length; e++){
                                try {

                                    pdf.add(
                                        await new Img(
                                            `${environment.STORAGE}/${report.chlorineInspections[i].images[e].Image_Url.toLowerCase()}.jpeg`
                                            )
                                            .margin(10)
                                            .width(150)
                                            .height(150)
                                            .alignment('center')
                                            .build()
                                    )
                                } catch(err) {
                                    try {

                                        pdf.add(
                                            await new Img(
                                                `${environment.STORAGE}/${environment.CONTAINER}/${report.chlorineInspections[i].images[e].Image_Url.toLowerCase()}.jpeg`
                                                )
                                                .margin(10)
                                                .width(150)
                                                .height(150)
                                                .alignment('center')
                                                .build()
                                        )
                                    } catch(err) {
    
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if(report.weightInspections) {
            // FORMS 6
            if(report.weightInspections.length > 0){
                pdf.add(new Txt(`Periodic weight inspection`).bold().pageBreak('before').end);
                for(let i = 0; i < report.weightInspections.length; i++){
                    
                    pdf.add(new Txt(`Created date: ${this.formatDate(report.weightInspections[i].Date_Create)}`).bold().end);
                    pdf.add(
                        new Table(
                            this.formWeightBuilder(report.weightInspections[i])
                        ).alignment('justify').widths(['*', 60]).end
                    );
                    pdf.add(new Txt('Comments:').bold().margin(10).end)
                    pdf.add(new Txt(report.weightInspections[i].Comment.trim()).end)
                    
                    if(report.weightInspections[i].images) {
                        if(report.weightInspections[i].images.length > 0){
                            pdf.add( new Txt('Evidences').bold().end);
                            for(let e = 0; e< report.weightInspections[i].images.length; e++){
                                try {

                                    pdf.add(
                                        await new Img(
                                            `${environment.STORAGE}/${report.weightInspections[i].images[e].Image_Url.toLowerCase()}.jpeg`
                                            )
                                            .margin(10)
                                            .width(150)
                                            .height(150)
                                            .alignment('center')
                                            .build()
                                    )
                                } catch(err) {
                                    try {

                                        pdf.add(
                                            await new Img(
                                                `${environment.STORAGE}/${environment.CONTAINER}/${report.weightInspections[i].images[e].Image_Url.toLowerCase()}.jpeg`
                                                )
                                                .margin(10)
                                                .width(150)
                                                .height(150)
                                                .alignment('center')
                                                .build()
                                        )
                                    } catch(err) {
    
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if(report.Grower_Name.includes(".")){
            report.Grower_Name.replace(/blue/g, "-");
            pdf.create().download(`${report.Grower_Name}-${moment().local().format('MM/DD/YYYY')}`);
        }else{
            pdf.create().download(`${report.Grower_Name}-${moment().local().format('MM/DD/YYYY')}`);
        }
    }
    
    private formHarvestBuilder = (harvestMachine: Harvest_Machine) => {
        let data = [
            [new Txt('Question').bold().end, new Txt('Answer').bold().end],
        ];
        for(let i = 0; i < questionsEn1.length; i++){
            data.push([ new Txt(questionsEn1[i]).end, new Txt(this.formHarvestAnswer(harvestMachine[`answer${i+1}`])).end ]);
        }
        return data;
    }

    private formHarvestAnswer = (answer: any) => {
        if(answer == 2){
            return 'N/A';
        }else if(answer == 1){
            return 'Done';
        }else if(answer == 0){
            return 'Not Done'
        }else {
            return 'N/A';
        }
    }

    private formEquipmentBuilder = (machineEquipment: Machine_Equipment, userLetters: string) => {
        let data = [
            [new Txt('Question').bold().end, new Txt('Answer').bold().end],
        ];
        for(let i = 0; i < questionsEn2.length; i++){
            if(i === 10) {
                data.push([ new Txt(questionsEn2[i] + ' ' + userLetters).end, new Txt(this.formEquipmentAnswer(machineEquipment[`answer${i+1}`], i)).end ]);
            }else {
                data.push([ new Txt(questionsEn2[i]).end, new Txt(this.formEquipmentAnswer(machineEquipment[`answer${i+1}`], i)).end ]);
            }
        }
        return data;
    }

    private formEquipmentAnswer = (answer: any, index: number) => {
        if(index === 9){
            return answer;
        }
        if(answer == 2){
            return 'N/A';
        }else if(answer == 1){
            return 'Done';
        }else if(answer == 0){
            return 'Not Done'
        }else {
            return 'N/A';
        }
    }

    private formMachineInspectionBuilder = (machineInspection: Machine_Inspection) => {
        let data = [
            [new Txt('Question').bold().end, new Txt('Answer').bold().end],
        ];
        for(let i = 0; i < questionsEn3.length; i++){
            data.push([ new Txt(questionsEn3[i]).end, new Txt(this.formHarvestAnswer(machineInspection[`answer${i+1}`])).end ]);
        }
        return data;
    }

    private formFieldBuilder = (fieldAssessment: Field_Assessment) => {
        let data = [
            [new Txt('Question').bold().end, new Txt('Answer').bold().end],
        ];
        for(let i = 0; i < questionsEn4.length; i++){
            data.push([ new Txt(questionsEn4[i]).end, new Txt(this.formFieldAnswer(fieldAssessment[`answer${i+1}`])).end ]);
        }
        return data;
    }

    private formFieldAnswer = (answer: any) => {
        if(answer == 2){
            return 'N/A';
        }else if(answer == 1){
            return 'Yes';
        }else if(answer == 0){
            return 'No'
        }else {
            return 'N/A';
        }
    }

    private formChlorineBuilder = (chlorineInspections: Chlorine_Inspection) => {
        let data = [
            [new Txt('Question').bold().end, new Txt('Answer').bold().end],
        ];
        for(let i = 0; i < questionsEn5.length; i++){
            data.push([ new Txt(questionsEn5[i]).end, new Txt(this.formChlorineAnswer(chlorineInspections[`answer${i+1}`], i)).end ]);
        }
        return data;
    }

    private formChlorineAnswer = (answer: any, index: number) => {
        if(index == 2){
            if(answer == 2){
                return 'N/A';
            }else if(answer == 1){
                return 'Done';
            }else if(answer == 0){
                return 'Not Done'
            }else {
                return 'N/A';
            }
        }else{
            return answer;
        }
    }

    private formWeightBuilder = (weightInspections: Weight_Inspection) => {
        let data = [
            [new Txt('Question').bold().end, new Txt('Answer').bold().end],
        ];
        for(let i = 0; i < questionsEn6.length; i++){
            data.push([ new Txt(questionsEn6[i]).end, new Txt(weightInspections[`answer${i+1}`]).end ]);
        }
        return data;
    }

    private modalDetail = (data: any) => {
        
        let dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "80%";
        dialogConfig.height = "84vh";
        dialogConfig.data = {form: data};

        let dialogRef = this.dialog.open(DailyModalDetailV2Component, dialogConfig);

        dialogRef.afterClosed().subscribe(async(result) => {
            if(result != null && !result.error){
                let newArray = this.dailyReports;
                newArray.map(report => {
                    if(report.Id === result.Id){
                        report.Status = result.Status;
                        report.Validated_By = result.Validated_By;
                        report.Comment_By_Validator = result.Comment_By_Validator;
                        report.Validate_Date = result.Validate_Date;
                        report.Validated_Name = result.Validated_Name;
                        report.Validated_Last_Name = result.Validated_Last_Name;
                    }
                })
                this.dailyReports = newArray;
            }
        });
    }
}