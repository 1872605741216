import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ReportsService } from '../reports.service';
import * as moment from 'moment';
import DataTable from '../../../components/table/table.model';
import { Grower } from '../../growers/growers.model';
import { Ranch } from '../../ranches/ranches.model';
import User from '../../../models/user.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DailyModalDetailComponent } from './daily-modal-detail/daily-modal-detail.component';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

const questionsEs1 = [
  'a- Se llenaron los tanques de abastecimiento de agua para la cosecha con agua nueva antes de iniciar el día de trabajo y esta agua tiene un nivel de cloro y pH adecuados(650 a 950 mV de ORP y 6.5 a 8.0 de pH) ? -esta agua será utilizada para rociar producto, para llenar los charolas de los cuchillos(knive dip stations), para lavar la maquinaria agrícola, etc.-.',
  'b- Se realizo una limpieza general a la maquinaria(maquina de cosecha, tanques de agua, tractores, etc.) agrícola antes de comenzar a cosechar y se verifico que no hay rastros o residuos de jabón, cloro o de cualquier agente químico ?',
  'c- Se realizo una inspección para verificar que no hacen falta tornillos o tuercas a las bandas transportadoras de producto, elevadores o a la maquinaria agrícola ?',
  'd- Se realizo una inspección al sistema de iluminación para verificar que la iluminación es suficiente y verificar que no existen lámparas rotas ?',
  'e- Todos los motores y conexiones de mangueras hidráulicas están equipados con una charola de prevención de goteos, estos se encuentran fijos y libres de evidencia de goteo de aceite o grasa ?',
  'f- Se realizo una inspección al sistema de espreas de agua clorada a todas las boquillas de espreas para verificar que trabajan apropiadamente ?',
  'g- Se realizo una inspección visual antes de comenzar a trabajar para verificar que la maquinaria agrícola y los empleados estén en cumplimiento con las reglas de higiene ?',
  'h- Se les esta dando servicio a los baños portátiles, se encuentran en condiciones sanitarias y contienen los artículos necesarios(papel de baño, toallas para secar las manos, agua, jabón, agua, etc.) ?',
  'i- Se añadió agua clorada o potable al tanque del agua de los baños portátiles, cuando menos una ves por semana ? Marque con una palomita en el cuadro del día en que se rellene el tanque del agua y escribalo en la fecha correspondiente en la seccion de aciones correctivas.',
  'j- No hay algún empleado que presenta síntomas de tos aguda, ojos llorosos, escurrimiento nasal, fiebre o síntomas de otra enfermedad contagiosa que pudiera causar contaminación del producto ?',
  'k- El equipo de trabajo(guantes, mangas, mandil, cuchillo) se encuentran en condiciones sanitarias antes de comenzar a trabajar ?',
  'l- Todos los empleados tienen el equipo y material de trabajo necesario(mayas para el cabello y barba - si es necesario -, guantes de plástico, mangas de plástico, mandil de plástico) ?',
  'm- No se observan  empleados con objetos personales  tales como joyería, pasadores, seguros, prendedores, uñas postizas, pestañas postizas, llaveros, radios que no sean permitidos, etc.?',
  'n- Todos los empleados se lavaron la manos con agua y jabón antes de comenzar a trabajar.?',
  'o- Se encuentra la maquinaria agrícola / maquina de cosecha libre de embases de productos químicos sin etiqueta ?'
];

const questionsEn1 = [
  'a- We filled the water supply tanks for the harvest with new water before starting the working day and this water has a suitable chlorine and pH level (650 to 950 mV ORP and 6.5 to 8.0 pH)? - This water will be used to spray product, to fill the trays of the knives (knive dip stations), to wash agricultural machinery, etc.-.',
  'b- Was a general cleaning to agricultural machinery (harvesting machine, water tanks, tractors, etc.) before starting to harvest and verified that there are no traces or residues of soap, chlorine or any chemical agent?',
  'c- Was an inspection carried out to verify that there is no need for screws or nuts to product conveyors, elevators or agricultural machinery?',
  'd- Was an inspection made to the lighting system to verify that the lighting is sufficient and verify that there are no broken lamps?',
  'e- All hydraulic hose motors and connections are equipped with a drip prevention tray, these are fixed and free of evidence of dripping oil or grease?',
  'f- Was an inspection made of the chlorinated water system to all the spray nozzles to verify that they work properly?',
  'g- Was a visual inspection performed before starting work to verify that the agricultural machinery and employees are in compliance with the hygiene rules?',
  'h- Are portable toilets being provided, are they in sanitary conditions and contain the necessary items (toilet paper, hand towels, water, soap, water, etc.)?',
  'i- Was chlorinated or potable water added to the water tank of portable toilets, at least once a week? Mark with a popcorn in the box of the day that the water tank is filled and write it on the corresponding date in the corrective actions section.',
  'j- Are there any employees with symptoms of acute cough, watery eyes, runny nose, fever or symptoms of another contagious disease that could cause product contamination?',
  'k- The work team (gloves, sleeves, apron, knife) are in sanitary conditions before starting work?',
  'l- All employees have the necessary equipment and work material (maya for hair and beard - if necessary - plastic gloves, plastic sleeves, plastic apron)?',
  'm- Are employees not observed with personal items such as jewelry, pins, insurance, pins, false nails, false eyelashes, key rings, radios that are not allowed, etc.?',
  'n- All employees washed their hands with soap and water before starting work.',
  'o- Is the agricultural machinery / harvesting machine free of chemical products without labels?'
];

const questionsEs2 = [
  '1- Se encuentra evidencia excesiva de intrusión de animales en el lote donde se cosecha tales como huellas de animales, plantas comidas por animales, heces fecales, restos de animales o sangre.',
  "2- Se encuentra alguna fuente de contaminación en terrenos adyacentes al lote donde se cosecha tales como pilas de estiércol o de composta(400'), operaciones ganaderas(CAFO's 400'), áreas de pastoreo(30'), fosas sépticas(30'), instalaciones de composteo de estiércol(400'), etc.",
  '3- Se encuentran materiales tales como plásticos, vidrios, piezas de metal(tornillos / tuercas sueltas) u otros escombros',
  '4- El campo que va a ser cosechado sufrio una inundación en los ultimos 60 días.',
  '5- Se observa actividad inesperada en los campos que se encuentran adyacentes al lote que se esta cosechando(pastoreo de ganado, pilas de composta, etc.) ?',
  '6- Las cercas en los terrenos adyacentes al lote de cosecha no se encuentran en buen estado.',
  "7- Se observa a algún empleado incumpliendo con las políticas de la compañía En caso de encontrar cualquiera de las evidencias descritas, marque el numero del incidente encontrado durante la cosecha y escriba la acción correctiva tomada para solucionar o minimizar el riesgo de  contaminación del producto en la línea correspondiente al día en que se está cosechando.",
  '8- Inspeccione los baños portatiles cada dos horas o más seguido.Asegure que no le falte agua al lavamanos, que este bien surtido de jabón, papel higienico y se encuentre limpio.Marque la hora en que se realize la inspección en la columna de inspección de los baños en la esquina inferior derecha de esta pagina.',
  '9- Se añadió agua clorada o potable al tanque del agua de los baños portátiles, (por lo menos una ves por semana)'
];
const questionsEn2 = [
  '1- There is excessive evidence of animal intrusion in the lot that is being harvested such as animal tracks, plants eaten by animals, feces, animal remains or blood.',
  "2- Some source of contamination is found in lands adjacent to the lot where it is harvested, such as manure or compost piles(400 '), livestock operations (CAFOs 400'), grazing areas(30 '), septic tanks (30'), manure composting facilities(400 '), etc.",
  '3- Found materials such as plastics, glass, metal parts (loose screws / nuts) or other debris',
  '4- The field that is going to be harvested suffered a flood in the last 60 days.',
  '5- Unexpected activity is observed in the fields that are adjacent to the batch that is being harvested (livestock grazing, piles of compost, etc.)?',
  '6- The fences on the land adjacent to the harvest lot are not in good condition.',
  "7- An employee is observed not complying with the company's policies.If you find any of the evidences described, mark the number of the incident found during the harvest and write the corrective action taken to solve or minimize the risk of contamination of the product on the line corresponding to the day in which it is being harvested.",
  '8- Inspect portable toilets every two hours or more often. Make sure there is no water in the sink, that this well stocked with soap and toilet paper is clean. Mark the time the inspection is done in the inspection column. bathrooms in the lower right corner of this page.',
  '9- Chlorinated or potable water is required to the water tank of the portable toilets, (at least once a week)'
];

const questionsEs3 = [
  'p- Estaciones de empaque(Mesa de empaque)',
  'q- Bandas trasportadoras de producto.',
  'r- Rodillos en  bandas trasportadoras de producto.',
  's- Derramamiento de grasa o aceite en valeros, motores, pistones hidráulicos, etc.',
  't- Charolas para prevención de goteo de aceite en tractores o maquinaria agrícola.',
  'u- Superficie exterior y interior en todos los tanques de abastecimiento de agua.',
  'v- Se limpiaron y desinfetaron los cuchillos y sus charolas ?',
  'w- # de maquina ?',
  'x- Se completo la limpieza y desinfeccion de la maquina(iniciales del responsible)'
];
const questionsEn3 = [
  'p- Packing stations (Packing table)',
  'q- Product conveyor belts.',
  'r- Rollers in product conveyor belts.',
  's- Grease or oil spillage in bearings, engines, hydraulic pistons, etc.',
  't- Trays for prevention of oil dripping in tractors or agricultural machinery.',
  'u- Exterior and interior surface in all water supply tanks.',
  'v- The knives and their trays were cleaned and disinfected?',
  'w- # machine',
  'x- Cleaning and disinfection of the machine completed (initials of the responsible)'
];

const questionsEs4 = [
  'Revisión a efectuar DIARIO',
  'Agua del radiador',
  'Nivel del aceite del motor',
  'Nivel del aceite hidráulico',
  'Tensión de los trackes (no menos de 1250)',
  'Relojes marcadores del tablero.',
  'Botones de los tableros',
  'Presión de las llantas (visual)',
  'Ajuste de bandas transportadoras (alas, principal y transportadoras de cajas).',
  'Transfer (tensión de las cadenas)',
  'Funcionamiento de la bomba de lavado a presión',
  'Derrames en la maquina (motor, radiador, tanques, motores hidráulicos, cilindros/pistones).',
  'Revisión a efectuar SEMANAL',
  'Lubricación de cadenas de transfer.',
  'Engrasar todos los valeros de la maquina (2 bombeadas cada ves).',
  'Descripción de la falla:',
  'Fecha de inicio de la reparación:',
  'Fecha de culminación (trabajo terminado):',
  'Mecánico asignado'
];
const questionsEn4 = [
  'Presure washer function.',
  'Machine motor water level',
  'Machine motor oil level',
  'Machina hydraulic oil level',
  'Track tention (no less than 1250)',
  'Greased housing',
  'Check hydraulic pressure mano meter',
  'Verify that all the control board indicators work properly.',
  'Inspect all the control board buttons.',
  'Tire presure',
  'Conveyor belts adjustment (wings, main and cartons conveyor)',
  'Transfer tension (chain tension)',
  'Inspect for any leaks on the machinery (motor, radiator, tanks, hydraulic motors and shocks)',
  'Grease transfer housing.',
  'Grease all the machina housing.',
  'Work description: ',
  'Date maintenance started:',
  'Date maintenance is done:',
  'Mechanic signature:'
];
const questionsEs5 = [
  'ORP(WD35650)',
  'pH',
  'Inspección de baños',
  'Comentarios'
];
const questionsEn5 = [
  'ORP(WD35650)',
  'pH',
  'Bathroom inspection',
  'Comment'
];

const questionsEs6 = [
  'Jumbos 48s',
  'Jumbos 42s',
  'Jumbos 36s',
  '12 x 3',
  'Mini Bin',
  'Colossal',
  'Petites',
  'Iceberg babies 40s',
  'Iceberg babies 20s',
  '7 x 6'
];
const questionsEn6 = [
  'Jumbos 48s',
  'Jumbos 42s',
  'Jumbos 36s',
  '12 x 3',
  'Mini Bin',
  'Colossal',
  'Petites',
  'Iceberg babies 40s',
  'Iceberg babies 20s',
  '7 x 6'
];

@Component({
  selector: 'app-daily',
  templateUrl: './daily.component.html',
  styleUrls: ['./daily.component.scss']
})
export class DailyComponent implements OnInit {

    public dailyV1Reports: Array<any> = [];
    public dailyV1ReportsdataTable: DataTable = new DataTable();
    public growers: Array<Grower> = [];
    public ranches: Array<Ranch> = [];
    public users: Array<User> = [];
    public maxDate: Date = new Date();

    public lang: string = 'en';
    public fromDate: Date = null;
    public toDate: Date = null;
    public growerId?: number = null;
    public ranchId?: number = null;
    public userId?: number = null;

    constructor(
    private router:Router,
    private reportService: ReportsService,
    private dialog: MatDialog
    ) {
        // this.getData();
        this.getCatalogues();
    }

    ngOnInit(): void {
        this.dailyV1ReportsdataTable.Headers.push('Select');
        this.dailyV1ReportsdataTable.Headers.push('DateCreate');
        this.dailyV1ReportsdataTable.Headers.push('GrowerName');
        this.dailyV1ReportsdataTable.Headers.push('RanchName');
        this.dailyV1ReportsdataTable.Headers.push('UserName');
        this.dailyV1ReportsdataTable.Headers.push('UserLastName');
        this.dailyV1ReportsdataTable.Headers.push('ValidatedName');
        this.dailyV1ReportsdataTable.Headers.push('Actions');
    }

    public detail = (event: {data: any}) => {
        this.modalDetail(event.data);
    }

    public download = (event: {data: any}) => {
        this.downloadPdf(event.data, 2);
    }

    public downloadAll = (event: {data: any}) => {
        if(event.data.length > 0){
            event.data.map(async report => await this.downloadPdf(report, 2));
        }
    }

    public back = () => {
        this.router.navigate(['reports']);
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    public displayedColumns: Array<string>;
    public dataSource: MatTableDataSource<any>;

    public applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    public checkData = (data, header) => {
        if(header == 'Actions')
        return '';

        if(data == null)
        return 'Not captured';

        if(typeof data == 'string'){
        if(data.trim() == '' || data == 'null' || data == 'Null')
            return 'Not captured'
        }
        
        if(header == 'Status') {
        if(data)
            return 'Active';
        else
            return 'Inactive';
        }
        
        return data;
    }

    public getData = async() => {
        let filters = {
            growerId: this.growerId,
            ranchId: this.ranchId,
            userId: this.userId,
            fromDate: this.fromDate,
            toDate: this.toDate
        }
        this.dailyV1Reports = await this.reportService.GetV1(filters);
    }

    public getCatalogues = async() => {

        let result: any = await this.reportService.getCataloguesV1();

        this.growers = result.growers;
        this.ranches = result.ranches;
        this.users = result.users;
    }
    public update = (selected: any, index: number) => {
        
    }

    public delete = (selected: any, index: number) => {
        
    }

    public ranch = (growerId: number) => {
        
    }

    public getColumns = (lang) => {
        if (lang == 'es') {
            return [
                { title: "Pregunta", dataKey: "question" },
                { title: "Respuesta", dataKey: "answer" }
            ];
        } else {
            return [
                { title: "Question", dataKey: "question" },
                { title: "Answer", dataKey: "answer" }
            ];
        }
    }
    public getDataForm = (type, formJson, questions, lang) => {
        let data = [];
        if (questions.length != formJson.length) {                
            for (let i = 0; i < (formJson.length-1); i++) {
                data.push({
                    question: this.setQuestion(questions[i]),
                    answer: type == 2 ? this.setAnswerFormType2(formJson[i].answer, lang) : this.setAnswerFormType1(type,formJson[i].answer, lang, questions[i])
                });
            };
        } else {
            for (let i = 0; i < formJson.length; i++) {
                data.push({
                    question: this.setQuestion(questions[i]),
                    answer: type == 2 ? this.setAnswerFormType2(formJson[i].answer, lang) : this.setAnswerFormType1(type,formJson[i].answer, lang, questions[i])
                });
            };
        }
        return data;
    }

    public setAnswerFormType1 = (type, answer, lang, question) => {

        if(question.substring(0,1) == 'w'){
            return answer;
        }
        
        let a = answer;
        if (lang == 'es') {
            switch (answer) {
                case 0: return 'No realizado';
                case 1: return 'Hecho';
                case 2: return 'N/A';
                default: return a;
            }
        } else {
            switch (answer) {
                case 0: return 'Not done';
                case 1: return 'Done';
                case 2: return 'N/A';
                default: return a;
            }
        }
    }

    public setAnswerFormType2 = (answer, lang) => {
        if (lang == 'es') {
            switch (answer) {
                case 0: return 'No';
                case 1: return 'Sí';
                case 2: return 'N/A';
            }
        } else {
            switch (answer) {
                case 0: return 'No';
                case 1: return 'Yes';
                case 2: return 'N/A';
            }
        }
    }

    public setQuestion = (question) => {
        let leng = question.length;
        if (leng > 130)
            return question;
        else
            return (question.slice(0, 130) + '...');
    }

    public downloadPdf = async(report,type) => {
        if(type != 1){
            // msgFactory.toastError('Download will start soon...');
        }
        
        var lang = this.lang;
        var doc = new jsPDF();
        var formJson = JSON.parse(report.FormJson);
        var formJsonPeriodic = JSON.parse(report.FormJsonPeriodic);
        var todayDate = moment().format('MM/DD/YYYY');
        
        doc.setFontSize(40);
        doc.text('Hitchcock Farms', 50, 50);
        
        doc.setFontSize(11);
        doc.text(
            lang == 'es' ? 
            'Expedido: ' + todayDate :
            'Printed: ' + todayDate,
            165, 12);

        doc.setFontSize(16);
        doc.text(lang == 'es' ?
            'Datos generales del reporte:' :
            'Report general information:',
            34, 80);

        
        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Ranchero: ':
            'Grower: ',
            34, 94);

        doc.setFontSize(12);
        doc.text(report.GrowerName.trim(), 80, 94);

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Rancho:':
            'Ranch:',
            34, 100);

        doc.setFontSize(12);
        doc.text(report.RanchName.trim(), 80, 100);

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Lote':
            'Field:',
            34, 105.5);

        doc.setFontSize(12);
        doc.text(report.Yard, 80, 105.5);

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Field ID:':
            'Field ID:',
            34, 110.5);
        
        doc.setFontSize(12);
        doc.text(report.SubYard, 80, 110.5);

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Mayordomo:':
            'Foreman:',
            34, 115.5);

        doc.setFontSize(12);
        doc.text(report.UserName + ' ' + report.UserLastName, 80, 115.5);

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Cuadrilla:':
            'Harvest crew:',
            34, 120.5);

        doc.setFontSize(12);
        doc.text(report.Squad, 80, 120.5);

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Dirección:':
            'Adress:',
            34, 130.5);

        doc.setFontSize(12);
        doc.text(report.Adress, 80, 130.5);

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Contacto:':
            'Contact Name:',
            34, 140.5);

        doc.setFontSize(12);
        doc.text(report.ContactName, 80, 140.5);

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Cosechando:':
            'Harvesting:',
            34, 145.5);

        doc.setFontSize(12);
        doc.text(report.Harvesting != null? report.Harvesting:'...', 80, 145.5);
        doc.setTextColor(150);

        if(report.ValidatedBy > 0){

            doc.setFontSize(14);
            doc.text(lang == 'es' ?
                'Comentarios de validador: ':
                'Comments by validator: ',
                34, 155.5);
            doc.setTextColor(150);

            doc.setFontSize(12);
            doc.text(report.CommentByValidator != null || report.CommentByValidator != '' ? report.CommentByValidator:'...', 34, 160.5);
            doc.setTextColor(150);

            doc.addImage('assets/verified.png', 'PNG', 150, 250, 40, 40);
            doc.text('By ' + report.ValidatedName + " " + report.ValidatedLastname + " on " + moment(report.ValidateDate).format('MM/DD/YYYY'), 127, 290);
        }
        var cols = this.getColumns(lang);

        // FORM 1
        doc.addPage();

        doc.setFontSize(14);
        doc.text(lang == 'es' ? 'Inspección diaria de cuadrilla y máquina de cosecha' : 'Daily harvest crew and machine inspection', 14, 12);

        doc.setFontSize(11);
        doc.setTextColor(100);
        var form1Date = moment(formJson.Form1Date).format('MM/DD/YYYY HH:MM').toString() == '01/01/0001 00:01' ? '' : moment(formJson.Form1Date).format('MM/DD/YYYY HH:MM');
        doc.text(form1Date, 170, 12);

        doc.autoTable(cols, this.getDataForm(1,formJson.Form1, lang == 'es' ? questionsEs1 : questionsEn1,lang), {
            styles: {
                fillColor: [255, 255, 255],
                lineWidth: 0,
                halign: 'center', // left, center, right
                overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
                valign: 'middle' // top, middle, bottom
            },
            headerStyles: {
                textColor: 20,
                fillColor: [156, 204, 101],
            },
            margin: { horizontal: 7 },
            bodyStyles: { valign: 'top' },
            columnStyles: {
                question: {

                    columnWidth: 165,
                    halign: 'left' // left, center, right
                },
                answer: { columnWidth: 30 }
            }
        });

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Comentarios:':
            'Comments:',
            10, 220);

        doc.setFontSize(12);
        doc.text(formJson.Form1Comment == null ? '': formJson.Form1Comment, 40, 220);
        
        if(formJson.Form1Image != '' && formJson.Form1Image != null){
            doc.addImage(formJson.Form1Image, 'JPEG', 10, 230, 100, 100);
        }
        if(report.ValidatedBy > 0){
            doc.addImage('assets/verified.png', 'PNG', 150, 250, 40, 40);
            doc.text('By ' + report.ValidatedName + " " + report.ValidatedLastname + " on " + moment(report.ValidateDate).format('MM/DD/YYYY'), 127, 290);
        }
        
        // FORM 3
        doc.addPage();

        doc.setFontSize(14);
        doc.text(lang == 'es' ? 'Desinfección y limpieza del equipo y máquina de cosecha' : 'Harvest machine and equipment cleaning and sanitation', 14, 12);

        doc.setFontSize(11);
        doc.setTextColor(100);

        var form3Date = moment(formJson.Form3Date).format('MM/DD/YYYY HH:MM').toString() == '01/01/0001 00:01' ? '' : moment(formJson.Form3Date).format('MM/DD/YYYY HH:MM');
        doc.text(form3Date, 170, 12);

        doc.autoTable(cols, this.getDataForm(3,formJson.Form3, lang == 'es' ? questionsEs3 : questionsEn3,lang), {
            styles: {
                fillColor: [255, 255, 255],
                lineWidth: 0,
                halign: 'center', // left, center, right
                overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
                valign: 'middle' // top, middle, bottom
            },
            headerStyles: {
                textColor: 20,
                fillColor: [156, 204, 101],
            },
            margin: { horizontal: 7 },
            bodyStyles: { valign: 'top' },
            columnStyles: {
                question: {

                    columnWidth: 165,
                    halign: 'left' // left, center, right
                },
                answer: { columnWidth: 30 }
            }
        });

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Comentarios:':
            'Comments:',
            10, 130);

        doc.setFontSize(12);
        doc.text(formJson.Form3Comment == null ? '': formJson.Form3Comment, 40, 130);

        if(formJson.Form3Image != '' && formJson.Form3Image != null){
            doc.addImage(formJson.Form3Image, 'JPEG', 10, 140, 100, 100);
        }
        if(report.ValidatedBy > 0){
            doc.addImage('assets/verified.png', 'PNG', 150, 250, 40, 40);
            doc.text('By ' + report.ValidatedName + " " + report.ValidatedLastname + " on " + moment(report.ValidateDate).format('MM/DD/YYYY'), 127, 290);
        }
        doc.addPage();

        doc.setFontSize(14);
        doc.text(lang == 'es' ? 'Inspección de máquina' : 'Machine inspection', 14, 12);

        doc.setFontSize(11);
        doc.setTextColor(100);

        var form4Date = moment(formJson.Form4Date).format('MM/DD/YYYY HH:MM').toString() == '01/01/0001 00:01' ? '' : moment(formJson.Form4Date).format('MM/DD/YYYY HH:MM');
        doc.text(form4Date, 170, 12);

        doc.autoTable(cols, this.getDataForm(1,formJson.Form4, lang == 'es' ? questionsEs4 : questionsEn4,lang), {
            styles: {
                fillColor: [255, 255, 255],
                lineWidth: 0,
                halign: 'center', // left, center, right
                overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
                valign: 'middle' // top, middle, bottom
            },
            headerStyles: {
                textColor: 20,
                fillColor: [156, 204, 101],
            },
            margin: { horizontal: 7 },
            bodyStyles: { valign: 'top' },
            columnStyles: {
                question: {

                    columnWidth: 165,
                    halign: 'left' // left, center, right
                },
                answer: { columnWidth: 30 }
            }
        });

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Comentarios:':
            'Comments:',
            10, 185);

        doc.setFontSize(12);
        doc.text(formJson.Form4Comment == null ? '': formJson.Form4Comment, 40, 185);

        if(formJson.Form4Image != '' && formJson.Form4Image != null){
            doc.addImage(formJson.Form4Image, 'JPEG', 10, 191, 100, 100);
        }
        if(report.ValidatedBy > 0){
            doc.addImage('assets/verified.png', 'PNG', 150, 250, 40, 40);
            doc.text('By ' + report.ValidatedName + " " + report.ValidatedLastname + " on " + moment(report.ValidateDate).format('MM/DD/YYYY'), 127, 290);
        }
        // FORM 2
        doc.addPage();

        doc.setFontSize(14);
        doc.text(lang == 'es' ? 'Inspección diaría del campo de cosecha' : 'Daily harvest field assessment', 14, 12);

        doc.setFontSize(11);
        doc.setTextColor(100);

        var form2Date = moment(formJson.Form2Date).format('MM/DD/YYYY HH:MM').toString() == '01/01/0001 00:01' ? '' : moment(formJson.Form2Date).format('MM/DD/YYYY HH:MM');
        doc.text(form2Date, 170, 12);

        doc.autoTable(cols, this.getDataForm(2,formJson.Form2, lang == 'es' ? questionsEs2 : questionsEn2,lang), {
            styles: {
                fillColor: [255, 255, 255],
                lineWidth: 0,
                halign: 'center', // left, center, right
                overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
                valign: 'middle' // top, middle, bottom
            },
            headerStyles: {
                textColor: 20,
                fillColor: [156, 204, 101],
            },
            margin: { horizontal: 7 },
            bodyStyles: { valign: 'top' },
            columnStyles: {
                question: {

                    columnWidth: 165,
                    halign: 'left' // left, center, right
                },
                answer: { columnWidth: 30 }
            }
        });

        doc.setFontSize(14);
        doc.text(lang == 'es' ?
            'Comentarios:':
            'Comments:',
            10, 160);

        doc.setFontSize(12);
        doc.text(formJson.Form2Comment == null ? '': formJson.Form2Comment, 40, 160);

        if(formJson.Form2Image != '' && formJson.Form2Image != null){
            doc.addImage(formJson.Form2Image, 'JPEG', 10, 170, 100, 100);
        }
        if(report.ValidatedBy > 0){
            doc.addImage('assets/verified.png', 'PNG', 150, 250, 40, 40);
            doc.text('By ' + report.ValidatedName + " " + report.ValidatedLastname + " on " + moment(report.ValidateDate).format('MM/DD/YYYY'), 127, 290);
        }
        // formJsonPeriodic
        doc.addPage();

        doc.setFontSize(14);
        doc.text(lang == 'es' ? 'Inspección periodica de cloro' : 'Periodic chlorine inspection', 14, 12);
        // var hasImages = false;

        var countChlorine = 0;
        var imagesEvidences = [];

        formJsonPeriodic.ChlorineInspectionForm.map((elem,key) => {

        doc.setFontSize(11);
        doc.setTextColor(100);

        var dateChlorine = moment(elem.CreatedDate).format('MM/DD/YYYY HH:MM').toString() == '01/01/0001 00:01' ? '' : moment(elem.CreatedDate).format('MM/DD/YYYY HH:MM');
        
        doc.text(dateChlorine, 170, (12 + (countChlorine * 60)));

        var Answers = [];
        Answers.push(elem.Answers['0']);
        Answers.push(elem.Answers['1']);
        Answers.push(elem.Answers['2']);

        doc.autoTable(cols, this.getDataForm(1,Answers, lang == 'es' ? questionsEs5 : questionsEn5,lang), {
            styles: {
                fillColor: [255, 255, 255],
                lineWidth: 0,
                halign: 'center', // left, center, right
                overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
                valign: 'middle' // top, middle, bottom
            },
            headerStyles: {
                textColor: 20,
                fillColor: [156, 204, 101],
            },
            startY: (16 + (countChlorine * 60)),
            margin: { horizontal: 7 },
            bodyStyles: { valign: 'top' },
            columnStyles: {
                question: {

                    columnWidth: 165,
                    halign: 'left' // left, center, right
                },
                answer: { columnWidth: 30 }
            }
        });

            doc.text('Comments: '+elem.Comment, 7, (50 + (countChlorine * 60)));
        
            if(elem.Image != '' && elem.Image != null){
                imagesEvidences.push({img:elem.Image, index: countChlorine});
            }
            if(report.ValidatedBy > 0){
                doc.addImage('assets/verified.png', 'PNG', 150, 250, 40, 40);
                doc.text('By ' + report.ValidatedName + " " + report.ValidatedLastname + " on " + moment(report.ValidateDate).format('MM/DD/YYYY'), 127, 290);
            }
            countChlorine++;
        });
    
        if(imagesEvidences.length > 0){
        
            doc.addPage();
            var imgCounter = 1;
            imagesEvidences.map( (elem,key) => {

            doc.text('Form: '+ (imagesEvidences.length + 1), 7 , (imgCounter * 60));
            doc.addImage(elem.Image, 'JPEG', 10, (imgCounter * 62), 100, 100);
            imgCounter++;
            });
        
        }

        formJsonPeriodic.WeightInspectionForm.map((elem,key) => {
            
            doc.addPage();

            doc.setFontSize(14);
            doc.text(lang == 'es' ? 'Inspección periodica de peso de cajas' : 'Periodic weight inspection', 14, 12);
            doc.setFontSize(11);
            doc.setTextColor(100);

            var dateWeight = moment(elem.CreatedDate).format('MM/DD/YYYY HH:MM').toString() == '01/01/0001 00:01' ? '' : moment(elem.CreatedDate).format('MM/DD/YYYY HH:MM');

            doc.text(dateWeight, 170, 12);

            var Answers = [];

            Answers.push(elem.Answers['0']);
            Answers.push(elem.Answers['1']);
            Answers.push(elem.Answers['2']);
            Answers.push(elem.Answers['3']);
            Answers.push(elem.Answers['4']);
            Answers.push(elem.Answers['5']);
            Answers.push(elem.Answers['6']);
            Answers.push(elem.Answers['7']);
            Answers.push(elem.Answers['8']);
            Answers.push(elem.Answers['9']);

            doc.autoTable(cols, this.getDataForm(1,Answers, lang == 'es' ? questionsEs6 : questionsEn6,lang), {
                styles: {
                    fillColor: [255, 255, 255],
                    lineWidth: 0,
                    halign: 'center', // left, center, right
                    overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
                    valign: 'middle' // top, middle, bottom
                },
                headerStyles: {
                    textColor: 20,
                    fillColor: [156, 204, 101],
                },
                margin: { horizontal: 7 },
                bodyStyles: { valign: 'top' },
                columnStyles: {
                    question: {

                        columnWidth: 165,
                        halign: 'left' // left, center, right
                    },
                    answer: { columnWidth: 30 }
                }
            });
            doc.text('Comments: '+elem.Comment, 10, 150);
            
            if(elem.Image != '' && elem.Image != null){
                doc.addImage(elem.Image, 'JPEG', 10, 170, 100, 100);
            }
            if(report.ValidatedBy > 0){
                
                doc.addImage('assets/verified.png', 'PNG', 150, 250, 40, 40);
                doc.text('By ' + report.ValidatedName + " " + report.ValidatedLastname + " on " + moment(report.ValidateDate).format('MM/DD/YYYY'), 127, 290);
            }
        });
    
        doc.save(report.GrowerName.trim() + todayDate +'.pdf');
    }
    
    private modalDetail = (data: any) => {
        
        let dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "80%";
        dialogConfig.height = "84vh";
        dialogConfig.data = {form: data};

        let dialogRef = this.dialog.open(DailyModalDetailComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async(result) => {
        });
    }
}
