<div class="container" id="modal">
  <mat-toolbar>
    <span class="fill-space"></span>
    <span>Delete Item</span>
    <span class="fill-space"></span>
    <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>

  <div class="strong-text"> <h1>Do you want to delete this item??</h1> </div>

  <footer class="dialog-footer">
    <div class="button-row">
      <button mat-raised-button color="primary" (click)="cancelModal()">Cancel</button>
      <button mat-raised-button color="primary" (click)="onDelete()">Delete</button>
    </div>
  </footer>
</div>
