import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilesService } from '../files.service';
import Files from '../models/file.model';

@Component({
  selector: 'app-file-add-modal',
  templateUrl: './file-add-modal.component.html',
  styleUrls: ['./file-add-modal.component.scss']
})
export class FileAddModalComponent implements OnInit {

  public multiple = false;
  public action = false;
  public fileToUpload: File = null;
  public filesToUpload: Array<File> = [];
  private filesAdded: Array<Files> = [];

  constructor(public dialogRef: MatDialogRef<FileAddModalComponent>, private service: FilesService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  
  public csvInputChange(fileInputEvent: any) {
    if(!this.multiple){
      this.fileToUpload = fileInputEvent.target.files[0];
    }else{
      this.filesToUpload = fileInputEvent.target.files;
    }
  }

  public cancelModal = (data: Array<Files> = null) => {
    this.dialogRef.close(data);
  }

  private convertFilesToUpload = (file: File) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = async() => {
        let fileToUpload: Files = {
          Id: '',
          IdFolder: this.data.Id,
          Name: this.fileToUpload.name,
          Status: true,
          file: reader.result
        }
        let newFile = await this.service.UploadFiles(fileToUpload);
        this.filesAdded.push(newFile);
        this.cancelModal(this.filesAdded);
    }
  }

  public onAdd = async() => {

    if(!this.multiple){
      this.convertFilesToUpload(this.fileToUpload);     
    }else{
      await Promise.all(this.filesToUpload.map(async(file:File)=> {
        this.convertFilesToUpload(file);
      }));
    }
    
  }

  public reset = () => {
    this.fileToUpload = null;
    this.filesToUpload = [];
  }
}
