<div class="container">
  <div class="body" *ngIf="!data.softDelete">
      Are you sure to remove permanently this data and all the data relationed with it?
  </div>
  <div class="body" *ngIf="data.softDelete">
      Disable this machine? it will appear no more on the mobile application.
  </div>
  <div class="footer">
      <button mat-button color="warn" *ngIf="data.softDelete" (click)="deleteFn2()">Disable</button>
      <button mat-button color="warn" *ngIf="!data.softDelete" (click)="deleteFn()">Delete</button>
      <button mat-button (click)="cancelModal(null)">Cancel</button>
  </div>
</div>