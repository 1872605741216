import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilesService } from '../files.service';
import { FolderDeleteModalComponent } from '../folder-delete-modal/folder-delete-modal.component';

@Component({
  selector: 'app-file-delete-modal',
  templateUrl: './file-delete-modal.component.html',
  styleUrls: ['./file-delete-modal.component.scss']
})
export class FileDeleteModalComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<FolderDeleteModalComponent>, private service: FilesService, @Inject(MAT_DIALOG_DATA) public data: {itemId:any}) { }

  ngOnInit(): void {
  }

  public cancelModal = (data = null) => {
    this.dialogRef.close(data);
  }
  public onDelete = async() =>{
    console.log(this.data);
      if(this.data.itemId.IdParentFolder){
        let result:any = await this.service.DeleteSubFolder(this.data.itemId.Id);
      if(result.error){
        console.log("error");
        return
      }
      this.cancelModal(result);
    }else{
      console.log('logica de file');
      let result:any = await this.service.DeleteFile(this.data.itemId.Id, this.data.itemId.IdFolder);
      if(result.error){
        console.log("error");
        return
      }
      this.cancelModal(result);
    }

  }

}
