<div class="main">
    <mat-card>
        <h1>Users</h1>
        <p>Control and manage permission of application users</p>
        <button mat-raised-button *ngIf="users.length > 0" (click)="modalAdd()">Create new user</button>
        <div *ngIf="users.length > 0">
            <app-table [data]="users" [dataTable]="dataTable" (updateItem)="update($event)" (deleteItem)="delete($event)"></app-table>
        </div>
        <div *ngIf="users.length == 0" style="text-align: center;">
            <h1>User table is empty</h1>
            <button mat-raised-button (click)="modalAdd()">Create new user</button>
        </div>
    </mat-card>
</div>