<div class="container" id="modal">
  <mat-toolbar>
    <span class="fill-space"></span>
    <span>Create Grower</span>
    <span class="fill-space"></span>
    <button mat-icon-button (click)="cancelModal()"><mat-icon>close</mat-icon></button>
  </mat-toolbar>
  
  <form [formGroup]="form">
    <div class="identity-information">
      <h2>Identity Information</h2>
    </div>
    <mat-divider></mat-divider>

    <div class="block1">
      <mat-form-field>
        <mat-label>Legal Name</mat-label>
        <input matInput type="text" formControlName="name" placeholder="Legal Name*" [formControl]="form.controls['name']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['name'].errors?.required">Legal name is required</mat-error>
        <mat-error *ngIf="form.controls['name'].errors?.maxLength">Legal name cannot contain more than 50 letters</mat-error>
        <mat-error *ngIf="form.controls['name'].errors?.minLength">Legal name cannot contain less than 3 letters</mat-error>
      </mat-form-field>
    </div>

    <div class="block2">
      <mat-form-field>
       <mat-label>Contact Name</mat-label>
       <input matInput type="text" formControlName="contactName" placeholder="Contact Name" [formControl]="form.controls['contactName']" [errorStateMatcher]="matcher">
       <mat-error *ngIf="form.controls['contactName'].errors?.required">Contact name is required</mat-error>
       <mat-error *ngIf="form.controls['contactName'].errors?.maxLength">Contact name cannot contain more than 100 letters</mat-error>
       <mat-error *ngIf="form.controls['contactName'].errors?.minLength">Contact name cannot contain less than 3 letters</mat-error>
      </mat-form-field>
    </div>
    <div class="block3">
      <mat-form-field>
        <mat-label>Cell Phone</mat-label>
        <input matInput type="text" formControlName="cellNumber" placeholder="cell phone" [formControl]="form.controls['cellNumber']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['cellNumber'].errors?.maxLength">Cellphone cannot contain more than 20 numbers</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Phone Number</mat-label>
        <input matInput type="text" formControlName="phoneNumber" placeholder="Phone Number" [formControl]="form.controls['phoneNumber']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['phoneNumber'].errors?.maxLength">Phone number cannot contain more than 20 numbers</mat-error>        
      </mat-form-field>
    </div>
    <div class="block4">
      <mat-form-field>
        <mat-label>Fax Number</mat-label>
        <input matInput type="text" formControlName="faxNumber" placeholder="Fax" [formControl]="form.controls['faxNumber']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['faxNumber'].errors?.maxLength">Fax cannot contain more than 20 numbers</mat-error>                
      </mat-form-field>

      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input matInput type="email" formControlName="email" placeholder="E-mail" [formControl]="form.controls['email']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['email'].errors?.email">Type a valid E-mail</mat-error>        

      </mat-form-field>
    </div>
    <div class="location-information">
      <h2>Location Information</h2>
    </div>
    <mat-divider></mat-divider> 
    <div class="block5">
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input matInput type="text" formControlName="adress" placeholder="Address" [formControl]="form.controls['adress']" [errorStateMatcher]="matcher">
          <mat-error *ngIf="form.controls['adress'].errors?.maxLength">Phone number cannot contain more than 200 characters</mat-error>        
        </mat-form-field>
    </div>
    <div class="block6">
      <mat-form-field>
        <mat-label>Location(GPS, physical location)</mat-label>
        <input matInput type="text" formControlName="location" [formControl]="form.controls['location']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['location'].errors?.maxLength">Location cannot contain more than 500 characters</mat-error>        
      </mat-form-field>
    </div>
    <div class="block7">
      <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput type="text" formControlName="city" placeholder="City" [formControl]="form.controls['city']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['city'].errors?.maxLength">city cannot contain more than 80 letters</mat-error>        
      </mat-form-field>

      <mat-form-field>
        <mat-label>County</mat-label>
        <input matInput type="text" formControlName="county" placeholder="County" [formControl]="form.controls['county']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['county'].errors?.maxLength">County cannot contain more than 80 letters</mat-error>        
      </mat-form-field>
    </div>
    <div class="block8">
      <mat-form-field>
        <mat-label>State</mat-label>
        <input matInput type="text" formControlName="state" placeholder="State" [formControl]="form.controls['state']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['state'].errors?.maxLength">state cannot contain more than 80 characters</mat-error>        
      </mat-form-field>

      <mat-form-field>
        <mat-label>Country</mat-label>
        <input matInput type="text" formControlName="country" placeholder="Country" [formControl]="form.controls['country']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['country'].errors?.maxLength">Country cannot contain more than 80 letters</mat-error>        
      </mat-form-field>
    </div>
    <div class="block9">
      <mat-form-field>
        <mat-label>Zip Code</mat-label>
        <input matInput type="number" formControlName="zipCode" placeholder="Zip Code" [formControl]="form.controls['zipCode']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['zipCode'].errors?.maxLength">Zip Code cannot contain more than 10 numbers</mat-error>        
      </mat-form-field>

      <mat-form-field>
        <mat-label>Size(ge)</mat-label>
        <input matInput type="text" formControlName="size" placeholder="Size" [formControl]="form.controls['size']" [errorStateMatcher]="matcher">
        <mat-error *ngIf="form.controls['size'].errors?.maxLength">Size cannot contain more than 100 numbers</mat-error>        
      </mat-form-field>
    </div>
  </form>
  
  <footer class="dialog-footer">
    <div class="button-row">
      <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="createGrower()">Create</button>
      <button mat-raised-button color="secondary" (click)="cancelModal()">Cancel</button>
    </div>
  </footer>
</div>
  
