<!-- {{data | json}} -->
<div class="main-table">
    <div class="filter" fxLayout="row">
        <mat-form-field style="width: 50%;">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search in table">
        </mat-form-field>
        <div *ngIf="(isReport && showDownloadAll) && !isMachine" style="margin-right: 10px;" class="phone">
          <button mat-raised-button (click)="downloadAll()">Download selected reports</button>
        </div>
    </div>
    <div class="mat-elevation-z8">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container *ngFor="let headCol of displayedColumns" matColumnDef="{{ headCol }}">
            <mat-header-cell *matHeaderCellDef mat-sort-header> {{ headCol }} </mat-header-cell>
            
            <mat-cell  *matCellDef="let row" [attr.data-label]="headCol">
              <div *ngIf="headCol == 'Select' && isReport">
                <mat-checkbox [(ngModel)]="row.checked" (change)="checkRow(row)"></mat-checkbox>
              </div>
              <div *ngIf="headCol != 'Select' && headCol == 'Actions' && isReport">
                <button mat-button (click)="detail(row);">
                  <mat-icon>plagiarism</mat-icon>
                </button>
                <button mat-button (click)="download(row)" *ngIf="!isMachine">
                  <mat-icon>play_for_work</mat-icon>
                </button>
              </div>
              <div *ngIf="headCol != 'Select' && headCol == 'Actions' && !isReport">
                <button *ngIf="row.ContactName != null" mat-button (click)="ranch(row.Id);">
                  <mat-icon>map</mat-icon>
                </button>
                <button mat-button (click)="update(row);">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-button (click)="delete(row)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              {{ headCol == 'Select' ? '' : checkData(row[headCol], headCol) }}
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>
      
        <mat-paginator #paginator [length]="total" [pageSizeOptions]="[15, 25, 45, 100]" (page)="pageChange($event)" showFirstLastButtons></mat-paginator>
    </div>
</div>
