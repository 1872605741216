import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MachinesAndEquipmentService } from '../../machines-component/machines-component.service';


@Component({
  selector: 'app-delete-machine-v2',
  templateUrl: './delete-machine-v2.component.html',
  styleUrls: ['./delete-machine-v2.component.scss']
})
export class DeleteMachineV2Component implements OnInit {

  constructor(private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: {machine_id: number, softDelete: boolean} ,public dialogRef: MatDialogRef<DeleteMachineV2Component>, private service: MachinesAndEquipmentService) { }

  ngOnInit(): void {
  }

  public cancelModal = (data: any = null) => {
    this.dialogRef.close(data);
  }

  public deleteFn = async() => {
    let result: any = await this.service.Delete(true, this.data.machine_id);
    if(result.recordset == void 0 || result.recordset == null || result.error){
      this._snackBar.open(`This item can't be deleted, because is used in another place`, 'Undo');
      this.cancelModal();
    } else
      this.cancelModal(this.data.machine_id);
    
  }
  public deleteFn2 = async() => {
    let result: any = await this.service.Delete(false, this.data.machine_id);
    if(result.recordset == void 0 || result.recordset == null || result.error){
      this._snackBar.open(`This item can't be deleted, because is used in another place`, 'Undo');
      this.cancelModal();
    } else
      this.cancelModal(this.data.machine_id);
    
  }

}
