import { Injectable } from '@angular/core';
import { ts, ActiveUser } from '../../app.shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import User from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class ContractorsService {

  private uri:string = environment.URI;
  private _s: string;
  private _us: User;
  
  constructor(private http:HttpClient, private s:ts, private activeUser:ActiveUser) {
    s.getValue().subscribe((value)=> {
      this._s = value;
    });
    activeUser.getValue().subscribe((value)=> {
        this._us = value;
    })
  }

  public Get = ():Promise<any[]> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.get<any[]>(`${this.uri}/api/contractorAll`, {headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public Create = (contractor:any):Promise<any> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.post<any>(`${this.uri}/api/contractor`, contractor,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public Edit = (contractor:any):Promise<any> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.put<any>(`${this.uri}/api/contractor`, contractor,{headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }

  public Delete = (contractorId: number):Promise<Array<any>> => {
    return new Promise((resolve, reject) => {

      let headers = new HttpHeaders();
      headers = headers.set('x-auth', this._s);

      this.http.delete<any[]>(`${this.uri}/api/contractor?id=${contractorId}`, {headers: headers, observe:'response'}).subscribe(
          success => {
            this.s.setValue(success.headers.get('x-auth'));
            resolve(success.body);
          }, 
          error => {
            if(error.status == 401){
              this.s.cleanPersist()
              this.activeUser.cleanPersist()
              this.s.delete()
              this.activeUser.delete();
            }
            reject({error: error});
      });
    });
  }
}
